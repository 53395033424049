import * as Sentry from '@sentry/react';
import {
    actionsToFilter,
    cases,
    filterExchangeAccounts,
    ignoredErrors,
    leaveValues,
} from './loggerHelpers';

class Logger {
    private readonly isDevBuild: boolean;

    constructor() {
        this.isDevBuild = import.meta.env.MODE === 'development';
    }

    getSentry() {
        return Sentry;
    }

    init(integrations: any) {
        if (this.isDevBuild) return;

        const options: any = {
            dsn: import.meta.env.REACT_APP_SENTRY,
            enabled: true, //import.meta.env.REACT_APP_ENVR === 'prod',
            environment: this.isDevBuild ? 'local' : import.meta.env.REACT_APP_ENVR,
            debug: this.isDevBuild,
            normalizeDepth: 10,
            ignoreErrors: [],
            ignoreTransactions: [],
            integrations: [
                new Sentry.BrowserTracing({
                    ...integrations,
                    _experiments: { enableInteractions: true },
                }),
                new Sentry.Replay({
                    networkDetailAllowUrls: [
                        'https://dev-dashboard.coinmarketman.com',
                        'https://stg-dashboard.coinmarketman.com',
                        'https://dashboard.coinmarketman.com',
                        'wss://coinmarketman.com',
                    ],
                }),
                new Sentry.BrowserProfilingIntegration(),
            ],
            tracesSampleRate: 0.5,
            tracePropagationTargets: [
                'localhost',
                'https://dev-dashboard.coinmarketman.com',
                'https://stg-dashboard.coinmarketman.com',
                'https://dashboard.coinmarketman.com',
                'wss://coinmarketman.com',
            ],
            profilesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            beforeSend: (event, hint) => {
                const error: any = hint.originalException;
                const level = event?.level || null;
                if (level === 'error' && error?.message) {
                    for (const item of ignoredErrors) {
                        if (error.message.includes(item)) {
                            return null;
                        }
                    }
                }

                if (event?.message) {
                    for (const item of ignoredErrors) {
                        if (event.message.includes(item)) {
                            return null;
                        }
                    }
                }

                return event;
            },
            beforeBreadcrumb: (breadcrumb) => {
                if (breadcrumb?.category === 'console') {
                    if (breadcrumb?.level === 'log' || breadcrumb?.level === 'info') {
                        return null;
                    }

                    if (breadcrumb?.level === 'warning') {
                        if (breadcrumb?.message?.includes('Conversion response error')) {
                            return null;
                        }
                    }
                }

                if (breadcrumb?.category === 'fetch') {
                    if (breadcrumb?.data?.url && breadcrumb?.data?.url.includes('zopim')) {
                        return null;
                    }
                }

                if (breadcrumb?.category === 'xhr') {
                    if (!breadcrumb?.data?.url) return breadcrumb;

                    for (const item of cases) {
                        if (breadcrumb?.data?.url.includes(item)) {
                            return null;
                        }
                    }
                }

                // console.info('BREADCRUMB:::', breadcrumb);
                // console.info('BREADCRUMB HINT:::', hint);
                return breadcrumb;
            },
        };

        if (import.meta.env.REACT_APP_BUILD_TIME) {
            options.release = import.meta.env.REACT_APP_BUILD_TIME;
        }

        Sentry.init(options);
    }

    setUser(memberId: number | null) {
        if (!memberId) {
            Sentry.setUser(null);
            return;
        }
        Sentry.setUser({ id: memberId });
    }

    getReduxEnhancer() {
        return Sentry.createReduxEnhancer({
            // Optionally pass options listed below
            stateTransformer: (state: any) => {
                return {
                    ...state,
                    auth: undefined,
                    member: {
                        ...state.member,
                        profile: leaveValues(state.member.profile, ['id']),
                    },
                    legs: {
                        ...state.legs,
                        legsList: undefined,
                    },
                    reports: undefined,
                    payments: undefined,
                    coins: {
                        ...state.coins,
                        quotes: undefined,
                    },
                    shortStorage: {
                        ...state.shortStorage,
                        combinedLegs: undefined,
                    },
                    positions: {
                        ...state.positions,
                        openPositions: undefined,
                    },
                    balances: undefined,
                    exchanges: {
                        ...state.exchanges,
                        exchangeAccounts: filterExchangeAccounts(state.exchanges.exchangeAccounts, [
                            'id',
                            'exchange',
                            'exchangeId',
                        ]),
                    },
                };
            },
            actionTransformer: (action: { type: string; payload: any }) => {
                return actionsToFilter.includes(action.type) ? null : action;
            },
        });
    }

    close = async () => {
        await Sentry.close();
    };

    log = (message: string, withCapture: boolean = true, error?: any): void => {
        console.info('[INFO]:', message);
        if (withCapture) {
            Sentry.captureException(error);
        }
    };

    error = (error: any): void => {
        const isSocketClosedMessage = error?.message?.includes('Websocket was closed by user');

        if (!isSocketClosedMessage) {
            console.error('[ERROR]:', error);
        }
        if (!this.isDevBuild) {
            Sentry.captureException(error);
        }
    };
}

const logger = new Logger();
export default logger;
