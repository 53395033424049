class ExchangeSymbol {
    id: any;
    symbol: any;
    origSymbol: any;
    rate: any;
    prevRate: number;
    subscribers: any[];

    constructor(data) {
        this.id = data.id;
        this.symbol = data.symbol;
        this.origSymbol = data.origSymbol;
        this.rate = data.rate;
        this.prevRate = 0;
        this.subscribers = [];
    }

    onChange(cb) {
        this.subscribers.push(cb);
    }

    off(cb) {
        this.subscribers = this.subscribers.filter((item) => item !== cb);
    }

    update(quote) {
        this.prevRate = this.rate;
        this.rate = (quote.ask + quote.bid) / 2;

        // run updates only on rate change
        if (this.rate !== this.prevRate) {
            this.subscribers.forEach((cb) => cb());
        }
    }

    clear() {
        this.subscribers = [];
    }
}

export default ExchangeSymbol;
