import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag, TagsState } from './tagsTypes';

const initialState: TagsState = {
    tags: [],
};

const slice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        resetTags: (state) => {
            state.tags = [];
        },
        setTags: (state, action: PayloadAction<Tag[]>) => {
            state.tags = action.payload;
        },
        addTag: (state, action: PayloadAction<Tag>) => {
            state.tags.unshift(action.payload);
        },
        deleteTag: (state, action: PayloadAction<Tag>) => {
            state.tags = state.tags.filter((tag) => tag.id !== action.payload.id);
        },
        deleteTagById: (state, action: PayloadAction<number>) => {
            state.tags = state.tags.filter((tag) => tag.id !== action.payload);
        },
        deleteTagBulk: (state, action: PayloadAction<number[]>) => {
            state.tags = state.tags.filter((tag) => !action.payload.includes(tag.id));
        },
        updateTag: (state, action: PayloadAction<Tag>) => {
            state.tags = state.tags.map((tag) => {
                if (tag.id === action.payload.id) {
                    return {
                        ...tag,
                        ...action.payload,
                    };
                }

                return tag;
            });
        },
    },
});

export const { setTags, addTag, deleteTag, deleteTagById, deleteTagBulk, updateTag, resetTags } =
    slice.actions;

export default slice.reducer;
