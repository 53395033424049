export const hashString = (str: string, asString?: boolean, seed?: any) => {
    /*jshint bitwise:false */
    let i,
        l,
        hval = seed === undefined ? 0x438dcc3f : seed;

    if (asString === undefined) {
        asString = true;
    }

    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    if (asString) {
        // Convert to 8 digit hex string
        return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
    }
    return hval >>> 0;
};

export function calc(amount, operation, value) {
    return operation ? amount * value : amount / value;
}

export function doConvert(convSymbols, amount) {
    return convSymbols.reduce((acc, convSymbol) => {
        return calc(acc, convSymbol.reverse, convSymbol.symbol.rate);
    }, amount);
}
