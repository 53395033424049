import {
    GENERAL_MESSAGE,
    GeneralBalancesPayload,
    GeneralEvent,
    GeneralPositionsPayload,
    GeneralPullPayload,
    InvalidKeyPayload,
    MemberUpdatePayload,
    MessageHandler,
} from './websocketTypes';
import { batch } from 'react-redux';
import store, { RootState } from '../../store/store';
import {
    removeBalancesByAccountId,
    setInitialBalances,
    setTotalValues,
} from '@/state/balances/balancesSlice';
import {
    removeDataLoadingKey,
    setApiKeyLoading,
    setGeneralLoadingStatus,
    toggleNotificationToast,
} from '@/state/general/generalSlice';
import { DATA_LOADING_KEYS, LOADING_STATE } from '@/state/general/generalTypes';
import {
    removePositionsForApiKey,
    setPositionsByAccountId,
} from '@/state/positions/positionsSlice';
import { DERIVATIVE_POSITION_STATUS, POSITION_DIRECTION } from '../constants/exchanges';
import { Pull, PULL_GENERAL_STATUS } from '@/state/exchanges/exchangesSliceTypes';
import {
    removeBannerByApiKey,
    removeDismissedBanner,
    updateBannersList,
    updatePullBanner,
} from '@/state/banners/bannersSlice';
import {
    deleteExchangeAccount,
    markAccountAsFaulty,
    resetPullStatus,
    setPullStatus,
    updateExchangeAccount,
} from '@/state/exchanges/exchangesSlice';
import { isDateAvailable } from '../helpers/helpers';
import { Leg } from '@/state/legs/legsTypes';
import { endOfDay, startOfDay } from 'date-fns';
import {
    removeLegsByAccountId,
    setLegsById,
    setSymbolsList,
    updateLeg,
    updateSymbolFilter,
} from '@/state/legs/legsSlice';
import settingsApi from '@/api/settingsApi';
import paymentsApi from '@/api/paymentsApi';
import {
    updateMemberProfile,
    updateMemberProfileWS,
    updateProfileParts,
    updateUsedSlots,
} from '@/state/member/memberSlice';
import { setSubscription } from '@/state/payments/paymentsSlice';
import {
    deleteVerificationPageByApiKeyId,
    updatePageById,
} from '@/state/verification/verificationSlice';
import { removeCombinedReport, removeReport } from '@/state/reports/reportsSlice';
import exchangesApi from '@/api/exchangesApi';
import apiManagerApi from '@/api/apiManagerApi';
import { setCombinedLegs, updateCombinedLegsList } from '@/state/shortStorage/shortStorageSlice';
import { ROUTES } from '@/lib/constants';
import { definedFetchLegsParams } from '@/hooks/useGetFetchLegsParams';
import { Banner } from '@/state/banners/bannersTypes';
import { UsedSlots } from '@/state/member/memberTypes';
import { EXCHANGES } from '@/lib/types/generalTypes';
import { Notification } from '@/state/notifications/notificationsTypes';
import { addNotificationFromWs } from '@/state/notifications/notificationsSlice';
import { OpenPosition } from '@/state/positions/positionTypes';
import { updateLeaderboardSettings } from '@/state/leaderboard/leaderboardSlice';

class GeneralEventsHandler implements MessageHandler {
    constructor() {}

    private getState = (): RootState => store.getState();

    handleMessage = (ws: WebSocket | null, message: GeneralEvent<any>): void => {
        if (!ws) return;
        // console.log('GENERAL MESSAGE', message);
        switch (message.payload.type) {
            case GENERAL_MESSAGE.BALANCES:
                this.handleBalances(message);
                break;
            case GENERAL_MESSAGE.OPEN_POSITIONS:
                this.handleOpenPositions(message);
                break;
            case GENERAL_MESSAGE.DATA_MESSAGE:
            case GENERAL_MESSAGE.DATA_PULL:
                this.handlePullData(message);
                break;
            case GENERAL_MESSAGE.CP_UPDATE:
            case GENERAL_MESSAGE.STRIPE_UPDATE:
                this.handlePaymentSubscriptionUpdate();
                break;
            case GENERAL_MESSAGE.INVALIDATE_API_KEY:
                this.handleInvalidateApiKey(message);
                break;
            case GENERAL_MESSAGE.MARK_API_KEY_AS_FAULTY:
                this.markApiKeyAsFaulty(message);
                break;
            case GENERAL_MESSAGE.MEMBER_ACCOUNT_TYPE:
                this.handleAccountTypeChange(message);
                break;
            case GENERAL_MESSAGE.LEG_CHANGED:
                this.handleLegChange(message);
                break;
            case GENERAL_MESSAGE.NEW_BANNER:
                this.handleNewBanner(message);
                break;
            case GENERAL_MESSAGE.DISMISS_BANNER:
                this.handleDismissBanner(message);
                break;
            case GENERAL_MESSAGE.MEMBER_UPDATE:
                this.handleMemberUpdate(message);
                break;
            case GENERAL_MESSAGE.SLOTS_UPDATE:
                this.handleUsedSlotsUpdate(message);
                break;
            case GENERAL_MESSAGE.VERIFICATION_PAGE_DEPLOY:
                this.handleVerificationPagesDeploy(message);
                break;
            case GENERAL_MESSAGE.VERIFICATION_PAGE_UPDATE:
                this.handleVerificationPageUpdate(message);
                break;
            case GENERAL_MESSAGE.NOTIFICATION_CREATED:
                this.handleNotifications(message);
                break;
            case GENERAL_MESSAGE.LEADERBOARD_UPDATE:
                this.handleLeaderboardUpdate(message);
                break;
            case GENERAL_MESSAGE.MEMBER_SETTINGS_UPDATE:
                this.handleMemberSettingsChanged(message);
                break;
            case GENERAL_MESSAGE.LEADERBOARD_SETTINGS_UPDATE:
                this.handleLeaderboardSettingsChanged(message);
        }
    };

    private handleNewBanner = (message: GeneralEvent<{ type: string; data: Banner }>) => {
        const newBanner = message.payload.data;
        store.dispatch(updateBannersList(newBanner));
    };
    private handleDismissBanner = (message: GeneralEvent<{ type: string; data: Banner }>) => {
        const banner = message.payload;
        store.dispatch(removeDismissedBanner(banner.data.id));
    };

    private handleLegChange = (message: GeneralEvent<{ leg: Leg }>) => {
        const updatedLeg: Leg = message.payload.leg;
        if (location.pathname === ROUTES.HOME) store.dispatch(updateCombinedLegsList(updatedLeg));
        else store.dispatch(updateLeg(updatedLeg));
    };
    private handleBalances = (message: GeneralEvent<GeneralBalancesPayload>): void => {
        const { totalDeposits = 0, totalWithdrawals = 0, apiKeyId } = message.payload;
        batch(() => {
            store.dispatch(setInitialBalances(message.payload));
            store.dispatch(
                setApiKeyLoading({
                    apiKey: apiKeyId,
                    loadingKey: 'balances',
                    status: LOADING_STATE.SUCCESS,
                }),
            );
            store.dispatch(
                setTotalValues({
                    accountId: apiKeyId,
                    values: { totalDeposits, totalWithdrawals },
                }),
            );
        });
    };

    private handleOpenPositions = (message: GeneralEvent<GeneralPositionsPayload>): void => {
        const { positions, apiKeyId } = message.payload;
        const state = store.getState();
        const account = state.exchanges.exchangeAccounts?.find((el) => el.id === apiKeyId);

        if (account?.keyError) return;
        if (positions.length === 0) {
            store.dispatch(
                setApiKeyLoading({
                    apiKey: apiKeyId,
                    loadingKey: 'positions',
                    status: LOADING_STATE.SUCCESS,
                }),
            );
            return;
        }

        const transformed: OpenPosition[] = positions.map((position) => {
            const amount = position?.amount || 0;
            let side: any = position.direction;

            if (side === 'sell') {
                side = POSITION_DIRECTION.SHORT;
            }

            if (side === 'buy') {
                side = POSITION_DIRECTION.LONG;
            }

            if (side === POSITION_DIRECTION.BOTH) {
                side = amount >= 0 ? POSITION_DIRECTION.LONG : POSITION_DIRECTION.SHORT;
            }

            const isUsdt = position.exchangeId === EXCHANGES.WOO ? true : Boolean(position?.isUsdt);

            return {
                ...position,
                status: DERIVATIVE_POSITION_STATUS.OPENED,
                lastUpdated: position?.lastUpdated || new Date().toISOString(),
                side: side as POSITION_DIRECTION,
                direction: side as POSITION_DIRECTION,
                contractType: position?.contractType || null,
                symbolId: position?.symbolId || undefined,
                isUsdt,
            };
        });

        batch(() => {
            store.dispatch(setPositionsByAccountId({ apiKeyId, data: transformed }));
            store.dispatch(
                setApiKeyLoading({
                    apiKey: apiKeyId,
                    loadingKey: 'positions',
                    status: LOADING_STATE.SUCCESS,
                }),
            );
        });
    };

    private handlePullData = (message: GeneralEvent<GeneralPullPayload>): void => {
        const { apiKeyId, status, step, totalSteps } = message.payload;
        const currentPull: Pull | undefined = this.getState().exchanges.pullStatus?.[apiKeyId];
        const isPullVisible: boolean | undefined = this.getState().banners.pullBanners?.[apiKeyId];

        batch(() => {
            store.dispatch(setPullStatus(message.payload));
            if (!currentPull || !isPullVisible) {
                store.dispatch(updatePullBanner({ accountId: apiKeyId, visible: true }));
            }
        });

        if (status === PULL_GENERAL_STATUS.COMPLETED && step === totalSteps) {
            this.runLegsUpdate(apiKeyId)
                .then(() => {
                    console.log('Finished legs update after Pull for key:', apiKeyId);
                })
                .catch((error) => {
                    console.error('Error running legs update', error);
                });
        }
    };

    private handlePaymentSubscriptionUpdate = async (): Promise<void> => {
        const [profile, subscription] = await Promise.all([
            settingsApi.getMemberProfile(),
            paymentsApi.getActualSubscription(),
        ]);

        batch(() => {
            store.dispatch(updateMemberProfile(profile.data));
            store.dispatch(setSubscription(subscription?.data || null));
        });
    };

    private handleInvalidateApiKey = (message: GeneralEvent<InvalidKeyPayload>): void => {
        const { apiKeyId, exchange } = message.payload;
        batch(() => {
            store.dispatch(removePositionsForApiKey({ accountId: apiKeyId }));
            store.dispatch(deleteVerificationPageByApiKeyId({ apiKeyId }));
            store.dispatch(removeBalancesByAccountId({ accountId: apiKeyId }));
            store.dispatch(removeLegsByAccountId({ accountId: apiKeyId }));
            store.dispatch(removeDataLoadingKey({ apiKeyId }));
            store.dispatch(removeBannerByApiKey({ apiKeyId }));
            store.dispatch(resetPullStatus(apiKeyId));
            store.dispatch(removeReport({ accountId: apiKeyId }));
            store.dispatch(removeCombinedReport());
            store.dispatch(deleteExchangeAccount({ accountId: apiKeyId }));
        });
        // navigate home
        if (window.location.pathname.includes(`/${exchange}/${apiKeyId}`)) {
            window.location.href = '/';
        }
    };
    private markApiKeyAsFaulty = (message: any): void => {
        const { apiKeyId, reason } = message.payload;
        batch(() => {
            store.dispatch(removePositionsForApiKey({ accountId: apiKeyId }));
            store.dispatch(removeBalancesByAccountId({ accountId: apiKeyId }));
            store.dispatch(markAccountAsFaulty({ accountId: apiKeyId, keyError: reason }));
            store.dispatch(
                setApiKeyLoading({
                    apiKey: apiKeyId,
                    loadingKey: 'balances',
                    status: LOADING_STATE.ERROR,
                }),
            );
            store.dispatch(
                setApiKeyLoading({
                    apiKey: apiKeyId,
                    loadingKey: 'positions',
                    status: LOADING_STATE.ERROR,
                }),
            );
        });
    };
    private handleAccountTypeChange = async (message: GeneralEvent<any>): Promise<void> => {
        const subscription = await paymentsApi.getActualSubscription();

        batch(() => {
            store.dispatch(updateMemberProfileWS(message.payload.data));
            store.dispatch(setSubscription(subscription?.data || null));
        });
    };

    private runLegsUpdate = async (apiKeyId: number): Promise<void> => {
        try {
            const {
                legs: { dateSelection, symbolFilter, symbolsList },
                exchanges: { exchangeAccounts },
                shortStorage: { selectedAccounts, legsFilters },
            } = this.getState();

            const updatedAccount = exchangeAccounts.find((account) => +account.id === +apiKeyId);
            const isInSelectedAccounts = selectedAccounts.find(
                (item) => +item.accountId === +apiKeyId,
            );
            const params = definedFetchLegsParams([apiKeyId], {
                dateSelection,
                symbolsList,
                symbolFilter,
                legsFilters,
                accounts: exchangeAccounts,
            });
            if (updatedAccount) {
                store.dispatch(
                    setApiKeyLoading({
                        apiKey: apiKeyId,
                        loadingKey: 'legs',
                        status: LOADING_STATE.LOADING,
                    }),
                );

                const [legs, account] = await Promise.all([
                    exchangesApi.getLegsById({ params }),
                    apiManagerApi.getAccountById({ accountId: updatedAccount.id }),
                ]);

                if (legs?.data?.items) {
                    let symbolsParams: any = {
                        startDate: dateSelection.startDate,
                        endDate: dateSelection.endDate,
                    };

                    if (!isDateAvailable(dateSelection.label)) {
                        symbolsParams = {
                            startDate: startOfDay(new Date('01/01/2016')).toISOString(),
                            endDate: endOfDay(new Date()).toISOString(),
                        };
                    }
                    symbolsParams.accountIds = [updatedAccount.id];

                    const { data: symbols } = await exchangesApi.getSymbolsForMultipleAccounts(
                        symbolsParams,
                    );
                    batch(() => {
                        store.dispatch(
                            setLegsById({
                                legs: legs.data,
                                accountId: updatedAccount.id,
                            }),
                        );
                        store.dispatch(setSymbolsList(symbols));
                        store.dispatch(updateSymbolFilter(symbols.map((s) => s.symbol)));
                    });
                }
                store.dispatch(updateExchangeAccount({ account: account.data }));
            }

            if (isInSelectedAccounts) {
                store.dispatch(
                    setGeneralLoadingStatus({
                        key: DATA_LOADING_KEYS.COMBINED_LEGS,
                        status: LOADING_STATE.LOADING,
                    }),
                );

                const { data } = await exchangesApi.getLegsForMultipleAccounts(params);
                store.dispatch(setCombinedLegs(data));
            }
        } catch (error) {
            console.error(error);
        } finally {
            batch(() => {
                store.dispatch(
                    setApiKeyLoading({
                        apiKey: apiKeyId,
                        loadingKey: 'legs',
                        status: LOADING_STATE.SUCCESS,
                    }),
                );

                store.dispatch(
                    setGeneralLoadingStatus({
                        key: DATA_LOADING_KEYS.COMBINED_LEGS,
                        status: LOADING_STATE.SUCCESS,
                    }),
                );
            });
        }
    };

    private handleMemberUpdate = (message: GeneralEvent<MemberUpdatePayload>) => {
        const { data } = message.payload;
        store.dispatch(updateProfileParts(data));
    };

    private handleUsedSlotsUpdate = (message: GeneralEvent<{ data: { usedSlots: UsedSlots } }>) => {
        store.dispatch(updateUsedSlots(message.payload.data.usedSlots));
    };

    private handleVerificationPagesDeploy = (message: GeneralEvent<any>) => {
        const pageData: any = {
            id: message.payload.data.pageId,
            deployStatus: message.payload.data.status,
            deployed: message.payload.data?.deployed || null,
            deployUpdated: message.payload.data?.deployUpdated || null,
        };

        store.dispatch(updatePageById(pageData));
    };

    private handleVerificationPageUpdate = (message: GeneralEvent<any>) => {
        store.dispatch(updatePageById(message.payload.data));
    };

    private handleNotifications = (message: GeneralEvent<{ data: Notification }>) => {
        const notification = message.payload.data;
        store.dispatch(addNotificationFromWs(notification));
        store.dispatch(toggleNotificationToast(true));
    };

    private handleLeaderboardUpdate = (message: GeneralEvent<any>) => {
        console.log('LEADERBOARD_UPDATE', message);
    };

    private handleMemberSettingsChanged = (message: GeneralEvent<any>) => {
        console.log('MEMBER_SETTING_UPDATE', message);
    };

    private handleLeaderboardSettingsChanged = (message: GeneralEvent<any>) => {
        const settings = message.payload.data?.settings?.[0]?.anonymInLeaderBoard || null;
        const state = store.getState();
        const { anonymInLeaderBoard } = state.leaderboard.settings;
        if (settings && settings !== anonymInLeaderBoard) {
            store.dispatch(updateLeaderboardSettings({ anonymInLeaderBoard: settings }));
        }
    };
}

const instance = new GeneralEventsHandler();

export default instance;
