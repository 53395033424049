import { createDeepEqualSelector, RootState } from '@/store/store';
import { EXCHANGES } from '@/lib/types/generalTypes';
import { Balance, BalanceMap, DepositWithdrawalMap, DepositWithdrawals } from './balancesTypes';
import { exchangeAccountsSelector } from '../exchanges/exchangeSelectors';
import { ExchangeAccount } from '../exchanges/exchangesSliceTypes';
import { storageListSelector } from '../storage/storageSelectors';

export interface WalletSelectorProps {
    accountId: number;
    exchangeId?: EXCHANGES;
}

export interface SummaryBalance extends Balance {
    usd: number;
    btc: number;
    walletType: string;
    price: number;
}

export interface BalancesSummary {
    totalUsd: number;
    totalBtc: number;
    totalFutureUsd: number;
    totalSpotUsd: number;
    totalFutureBtc: number;
    totalSpotBtc: number;
    allBalances: SummaryBalance[];
}

export type AssetByExchange = {
    [exchangeId in EXCHANGES]: {
        value: number;
        valueUsd: number;
        allocation: number;
        exchangeId: EXCHANGES;
        asset: string;
    };
};

export interface BalanceByAsset {
    asset: string;
    value: number;
    valueUsd: number;
    allocation: number;
    price: number;
    byExchange: AssetByExchange | {};
}

export interface WalletBalance {
    usd: number;
    walletType: string;
    percent: number;
    label: string;
}

export interface WalletBalance extends Balance {
    walletType: string;
}

export type Asset = {
    asset: string;
    value: number;
    exchangeId: EXCHANGES | 'storage';
    accountId: number;
    valueUsd: number;
    allocation: number;
};

export interface BalanceForConversion {
    symbol: string;
    value: number;
    exchange: EXCHANGES;
    apiKey: number;
}

export const balancesSelector = (state: any) => state.balances.balances;
export const walletSelector = (state: RootState, props: WalletSelectorProps): WalletSelectorProps =>
    props;
export const totalValuesSelector = (state: RootState) => state.balances.totalValues;
export const accountIdSelector = (state, { accountId }): number => accountId;
export const depositWithdrawalsSelector = (state: RootState): DepositWithdrawalMap =>
    state.balances.depositWithdrawal;

export const selectBalancesList = createDeepEqualSelector(
    [balancesSelector, walletSelector],
    (balances, props): WalletBalance[] => {
        const { accountId } = props;

        const allBalancesMap = balances?.[accountId] ? Object.keys(balances[accountId]) : [];
        return allBalancesMap
            .map((walletType) => {
                return Object.values(balances[accountId][walletType].balances).map(
                    (item: any): any => ({
                        ...item,
                        walletType,
                    }),
                );
            })
            .flat();
    },
);
export const selectWalletsList = createDeepEqualSelector(
    [balancesSelector, walletSelector],
    (balances, props): { [key: string]: { balances: WalletBalance; total: { btc: number } } } => {
        const { accountId } = props;
        return balances[accountId];
    },
);

export const selectTotalValuesByAccountId = createDeepEqualSelector(
    [totalValuesSelector, walletSelector],
    (
        totalValues,
        props,
    ): {
        totalDeposits: number;
        totalWithdrawals: number;
        totalDepositsBtc: number;
        totalWithdrawalsBtc: number;
    } => {
        const { accountId } = props;

        const totalDepositsBtc = 0;
        const totalWithdrawalsBtc = 0;
        return {
            totalDepositsBtc,
            totalWithdrawalsBtc,
            ...totalValues[accountId],
        };
    },
);

export const selectTotalBalancesForConversion = createDeepEqualSelector(
    [exchangeAccountsSelector, balancesSelector],
    (exchangeAccounts: ExchangeAccount[], balances: BalanceMap) => {
        const result: BalanceForConversion[] = [];

        exchangeAccounts.forEach((account) => {
            if (account && balances?.[account?.id]) {
                const allBalancesKeys = Object.keys(balances[account.id]);

                const allBalancesList = allBalancesKeys.flatMap((walletType) => {
                    return Object.values(balances[account.id][walletType].balances).map(
                        (balance) => {
                            return {
                                symbol: balance.symbol,
                                value: balance.value || 0,
                                exchange: (account.exchange || account.exchangeId) as EXCHANGES,
                                apiKey: account.id,
                            };
                        },
                    );
                });

                const mergedBalances: BalanceForConversion[] = [];

                allBalancesList.forEach((balance) => {
                    const existingBalanceIndex = mergedBalances.findIndex(
                        (item) =>
                            item.symbol === balance.symbol && item.exchange === balance.exchange,
                    );

                    if (existingBalanceIndex === -1) {
                        mergedBalances.push(balance);
                        return;
                    }

                    const existingBalance = mergedBalances[existingBalanceIndex];
                    if (balance.value === 0) return;

                    existingBalance.value += balance.value;
                    mergedBalances.splice(existingBalanceIndex, 1, existingBalance);
                });

                result.push(...mergedBalances);
            }
        });

        return result;
    },
);

export const selectBalancesByAsset = createDeepEqualSelector(
    [exchangeAccountsSelector, balancesSelector, storageListSelector],
    (exchangeAccounts: ExchangeAccount[], balances: BalanceMap, _storage): Asset[] => {
        const storage = _storage || [];

        const assetsList: Asset[] = storage.map((storageItem) => ({
            asset: storageItem.asset,
            value: storageItem.amount,
            exchangeId: 'storage',
            accountId: 0,
            valueUsd: 0,
            allocation: 0,
        }));

        exchangeAccounts.forEach((account) => {
            const { id, exchangeId } = account;
            if (balances?.[id]) {
                for (let walletType in balances?.[id]) {
                    const accountBalances = Object.values(balances?.[id][walletType].balances);
                    if (accountBalances.length > 0) {
                        accountBalances.forEach((balance) => {
                            const balanceToStore: any = {
                                asset: balance.symbol,
                                value: balance.value,
                                exchangeId,
                                accountId: id,
                                valueUsd: 0,
                                allocation: 0,
                            };

                            assetsList.push(balanceToStore);
                        });
                    }
                }
            }
        });

        return assetsList;
    },
);

export const selectTransactions = createDeepEqualSelector(
    [depositWithdrawalsSelector, accountIdSelector],
    (transactions, accountId) => {
        if (!transactions?.[accountId]) {
            return {} as DepositWithdrawals;
        }
        return transactions[accountId];
    },
);
