/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {StorageEntry, StorageInitialState} from './storageTypes';

const initialState: StorageInitialState = {
    storageList: [],
    storageLoading: true,
};

const slice = createSlice({
    name: 'storage',
    initialState,
    reducers: {
        setStorage: (state, action: PayloadAction<StorageEntry[]>) => {
            state.storageList = action.payload;
        },
        addStorage: (state, action: PayloadAction<StorageEntry>) => {
            state.storageList.push(action.payload);
        },
        updateStorage: (state, action: PayloadAction<StorageEntry>) => {
            const elIndex = state.storageList.findIndex((item) => item.id === action.payload.id);
            if (elIndex >= 0) {
                state.storageList[elIndex] = action.payload;
            }
        },
        deleteStorage: (state, action: PayloadAction<number>) => {
            const newList = [...state.storageList];
            const elIndex = newList.findIndex((item) => +item.id === action.payload);

            if (elIndex > 0) {
                newList.splice(elIndex);
            }

            if (elIndex === 0) {
                newList.shift();
            }

            if (state.storageList.length !== newList.length) {
                state.storageList = newList;
            }
        },
        resetStorage: (state) => {
            state.storageList = [];
        },
        setStorageLoading: (state, action: PayloadAction<boolean>) => {
            state.storageLoading = action.payload;
        },
    },
});

export const {
    setStorage,
    addStorage,
    updateStorage,
    deleteStorage,
    resetStorage,
    setStorageLoading,
} = slice.actions;
export default slice.reducer;
