import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DraftNote, MultiFetchAccount, ShortStorageState } from './shortStorageTypes';
import { Leg, LegsListItem } from '@/state/legs/legsTypes';
import { SortingModel } from '@/state/general/generalTypes';

const initialState: ShortStorageState = {
    selectedAccounts: [],
    combinedLegs: { items: [], totalCount: 0 },
    legsFilters: {},
    legsSorting: { field: 'closeDate', sort: 'desc' },
    fiatRates: {
        usd: 1,
        eur: 0,
        gbp: 0,
    },
    draftNotes: {},
};

const slice = createSlice({
    name: 'shortStorage',
    initialState,
    reducers: {
        resetShortStorage: (state) => {
            state.selectedAccounts = [];
            state.combinedLegs = { items: [], totalCount: 0 };
            state.fiatRates = {
                usd: 1,
                eur: 0,
                gbp: 0,
            };
        },
        setSelectedAccounts: (state, action: PayloadAction<MultiFetchAccount[]>) => {
            state.selectedAccounts = action.payload;
        },
        setCombinedLegs: (state, action: PayloadAction<LegsListItem>) => {
            state.combinedLegs = action.payload;
        },
        addToCombinedLegsList: (state, action: PayloadAction<LegsListItem>) => {
            state.combinedLegs.items = [
                ...(state.combinedLegs.items || []),
                ...(action.payload.items || []),
            ];
        },
        updateCombinedLegsList: (state, action: PayloadAction<Leg>) => {
            const updatedLegIndex = state.combinedLegs.items.findIndex(
                (el) => el.id === action.payload.id,
            );
            if (updatedLegIndex >= 0)
                state.combinedLegs.items.splice(updatedLegIndex, 1, action.payload);
        },
        setLegsFilters: (state, action: PayloadAction<any>) => {
            state.legsFilters = action.payload;
        },
        setCombinedLegsSorting: (state, action: PayloadAction<SortingModel>) => {
            state.legsSorting = action.payload;
        },
        updateRateByKey: (state, actions: PayloadAction<{ key: string; value: number }>) => {
            const { key, value } = actions.payload;
            if (state.fiatRates[key] !== value) {
                state.fiatRates[key] = value;
            }
            if (!state.fiatRates.usd) {
                state.fiatRates.usd = 1;
            }
        },
        addDraftNote: (state, action: PayloadAction<{ source: string; draftNote: DraftNote }>) => {
            state.draftNotes[action.payload.source] = action.payload.draftNote;
        },
        removeDraftNote: (state, action: PayloadAction<string>) => {
            delete state.draftNotes[action.payload];
        },
    },
});

export const {
    setSelectedAccounts,
    setCombinedLegs,
    updateCombinedLegsList,
    resetShortStorage,
    updateRateByKey,
    setLegsFilters,
    setCombinedLegsSorting,
    addToCombinedLegsList,
    addDraftNote,
    removeDraftNote,
} = slice.actions;

export default slice.reducer;
