import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ApiKeysCountWithUpdates,
    InitialStateLeaderboard,
    LeaderboardPeriod,
    LeaderboardSettings,
    LeaderBoardTotalScores,
    MemberRank,
    PositionsAnalyticsWithUpdates,
} from '@/state/leaderboard/leaderboardTypes';

const initialState: InitialStateLeaderboard = {
    apiKeysCount: {
        exchanges: [],
        lastUpdate: '',
        nextUpdate: '',
    },
    positionsAnalytics: {
        exchanges: [],
        lastUpdate: '',
        nextUpdate: '',
    },
    selectedPeriod: LeaderboardPeriod.WEEK,
    totalScores: {
        pnl: 0,
        trades: 0,
        volume: 0,
        avgDuration: 0,
    },
    memberRanks: [],
    pnlRanks: [],
    volumeRanks: [],
    tradesRanks: [],
    topPnlRanks: [],
    settings: {
        anonymInLeaderBoard: false,
    },
};

const slice = createSlice({
    name: 'leaderboard',
    initialState,
    reducers: {
        resetLeaderboardState: (state) => {
            state.apiKeysCount = {
                exchanges: [],
                lastUpdate: '',
                nextUpdate: '',
            };
            state.positionsAnalytics = {
                exchanges: [],
                lastUpdate: '',
                nextUpdate: '',
            };
            state.selectedPeriod = LeaderboardPeriod.WEEK;
            state.totalScores = {
                pnl: 0,
                trades: 0,
                volume: 0,
                avgDuration: 0,
            };
            state.memberRanks = [];
            state.pnlRanks = [];
            state.volumeRanks = [];
            state.tradesRanks = [];
            state.topPnlRanks = [];
            state.settings = {
                anonymInLeaderBoard: false,
            };
        },
        setSelectedPeriod: (state, action: PayloadAction<LeaderboardPeriod>) => {
            state.selectedPeriod = action.payload;
        },
        setApiKeysCount: (state, action: PayloadAction<ApiKeysCountWithUpdates>) => {
            state.apiKeysCount = action.payload;
        },
        setTotalScores: (state, action: PayloadAction<LeaderBoardTotalScores>) => {
            state.totalScores = action.payload;
        },
        setMemberRanks: (state, action: PayloadAction<MemberRank[]>) => {
            state.memberRanks = action.payload;
        },
        setPositionsAnalytics: (state, action: PayloadAction<PositionsAnalyticsWithUpdates>) => {
            state.positionsAnalytics = action.payload;
        },
        updatePnlRanks: (state, action: PayloadAction<MemberRank[]>) => {
            state.pnlRanks = action.payload;
        },
        updateVolumeRanks: (state, action: PayloadAction<MemberRank[]>) => {
            state.volumeRanks = action.payload;
        },
        updateTradesRanks: (state, action: PayloadAction<MemberRank[]>) => {
            state.tradesRanks = action.payload;
        },
        setTopPnlRanks: (state, action: PayloadAction<MemberRank[]>) => {
            state.topPnlRanks = action.payload;
        },
        updateLeaderboardSettings: (state, action: PayloadAction<Partial<LeaderboardSettings>>) => {
            state.settings = {
                ...state.settings,
                ...action.payload,
            };
        },
    },
});

export const {
    resetLeaderboardState,
    setSelectedPeriod,
    setApiKeysCount,
    setTotalScores,
    setMemberRanks,
    setPositionsAnalytics,
    updatePnlRanks,
    updateVolumeRanks,
    updateTradesRanks,
    setTopPnlRanks,
    updateLeaderboardSettings,
} = slice.actions;

export default slice.reducer;
