import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VerificationItem, VerificationState } from './verificationTypes';

const initialState: VerificationState = {
    pages: [],
};

const slice = createSlice({
    name: 'verification',
    initialState,
    reducers: {
        resetVerification: (state) => {
            state.pages = [];
        },
        setVerificationPages: (state, action: PayloadAction<VerificationItem[]>) => {
            state.pages = action.payload;
        },
        updateVerificationPage: (state, action: PayloadAction<Partial<VerificationItem>>) => {
            const page = action.payload;
            const index = state.pages.findIndex((p) => p.id === page.id);
            if (index !== -1) {
                state.pages[index] = {
                    ...state.pages[index],
                    ...page,
                };
            }
        },
        deleteVerificationPage: (state, action: PayloadAction<{ pageId: number }>) => {
            const { pageId } = action.payload;
            state.pages = state.pages.filter((p) => p.id !== pageId);
        },
        addVerificationPage: (state, action: PayloadAction<VerificationItem>) => {
            state.pages.push(action.payload);
        },
        deleteVerificationPageByApiKeyId: (state, action: PayloadAction<{ apiKeyId: number }>) => {
            const { apiKeyId } = action.payload;
            state.pages = state.pages.filter((p) => p.apiKeyId !== apiKeyId);
        },
        updatePageById: (state, action: PayloadAction<Partial<VerificationItem>>) => {
            const page = action.payload;
            const index = state.pages.findIndex((p) => p.id === page.id);
            if (index !== -1) {
                state.pages[index] = {
                    ...state.pages[index],
                    ...page,
                };
            }
        },
    },
});

export const {
    resetVerification,
    setVerificationPages,
    updateVerificationPage,
    deleteVerificationPage,
    addVerificationPage,
    deleteVerificationPageByApiKeyId,
    updatePageById,
} = slice.actions;
export default slice.reducer;
