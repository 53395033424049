import { createDeepEqualSelector, RootState } from '@/store/store';
import { Report, ReportsMap } from './reportsTypes';
import { EXCHANGES } from '@/lib/types/generalTypes';
import { isAfter, isBefore } from 'date-fns';

const accountReportsSelector = (state: RootState): ReportsMap => state.reports.accountReports;
const combinedReportSelector = (state: RootState): Report => state.reports.combinedReport;
const accountIdSelector = (state: RootState, { accountId }: { accountId: number }): number =>
    accountId;

export const selectReportById = createDeepEqualSelector(
    [accountReportsSelector, accountIdSelector],
    (reports, accountId): Report | null => {
        if (!accountId) return null;
        if (!reports?.[accountId]) return null;
        return reports[accountId];
    },
);
export const selectCombinedReport = createDeepEqualSelector(
    [combinedReportSelector],
    (report) => report,
);
