import { SIDE } from '@/lib/types/generalTypes';
import { removeEmptyStringValues } from '@/lib/helpers/helpers';

export const getFilterQueryParams = (legsFilters) => {
    let params = {};
    Object.entries(legsFilters).forEach(([key, filterData]: any) => {
        if (typeof filterData.value === 'string') {
            if (key === 'side') {
                params[key] = [filterData.value?.toLowerCase() === 'long' ? SIDE.BUY : SIDE.SELL];
            } else if (key === 'daysOfWeekSource') {
                params[key] = filterData.value;
            } else params[key] = [filterData.value];
        } else if (Array.isArray(filterData.value)) {
            params[key] = filterData.value;
        } else if (typeof filterData.value === 'object') {
            params = { ...params, ...filterData.value };
        }
    });

    return removeEmptyStringValues(params);
};

export const filterFormValueToSearchParams = (values: { [key: string]: any }) => {
    let params = {};

    Object.entries(values).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            if (value[0] instanceof Date) {
                params[key] = `${value[0] ? value[0] : ''}-${value[1] ? value[1] : ''}`;
            } else if (value[0] && typeof value[0] !== 'string' && 'label' in value[0]) {
                params[key] = value.map((el) => `${el?.value}`);
            } else if (value[0] && typeof value[0] === 'string') {
                params[key] = value.map((el) => `${el}`);
            }
        } else if (typeof value === 'string') {
            if (value) params[key] = value;
        } else if (value && ('from' in value || 'to' in value)) {
            if (value.from && value.to) params[key] = `${value.from}-${value.to}`;
            else if (value.from) params[key] = `${value.from}-`;
            else if (value.to) params[key] = `-${value.to}`;
        }
    });
    return params;
};
export const definedMaxNimKeyName = (
    key: 'size' | 'accRisk' | 'finalR' | 'duration' | 'pnl',
    side: string,
): string => {
    switch (key) {
        case 'size':
            return `${side}Size`;
        case 'pnl':
            return `${side}RealisedPnLUsd`;
        case 'accRisk':
            return `${side}AccRisk`;
        case 'finalR':
            return `${side}FinalR`;
        case 'duration':
            return `${side}Duration`;
    }
};
