import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    BalancesMap,
    BalanceTotalValues,
    DepositWithdrawals,
    InitialBalancesState,
    WSBalancesPayload,
    WSUpdateBalance,
} from './balancesTypes';
import { getBalancesMap } from '@/lib/helpers/helpers';

const initialState: InitialBalancesState = {
    balances: {},
    totalValues: {},
    depositWithdrawal: {},
};

const slice = createSlice({
    name: 'balances',
    initialState,
    reducers: {
        setInitialBalances: (state, action: PayloadAction<WSBalancesPayload>) => {
            const accountId = action.payload.apiKeyId;
            const { balances } = action.payload;
            if (balances?.totalDepostits) {
                delete balances.totalDeposits;
                delete balances.totalWithdrawals;
            }
            if (state?.[accountId]) {
                state.balances[accountId] = {
                    ...state.balances[accountId],
                    ...getBalancesMap(balances),
                };
            } else {
                state.balances = {
                    ...state.balances,
                    [accountId]: { ...getBalancesMap(balances) },
                };
            }
        },
        setTotalValues: (
            state,
            action: PayloadAction<{ accountId: number; values: BalanceTotalValues }>,
        ) => {
            const { accountId, values } = action.payload;
            state.totalValues[accountId] = values;
        },
        setDepositWithdrawal: (
            state,
            action: PayloadAction<{ accountId: number; values: DepositWithdrawals }>,
        ) => {
            const { accountId, values } = action.payload;
            state.depositWithdrawal[accountId] = {
                items: values.items,
                totalCount: values.totalCount,
            };
        },
        updateDepositWithdrawalList: (
            state,
            action: PayloadAction<{ accountId: number; values: DepositWithdrawals }>,
        ) => {
            const { accountId, values } = action.payload;
            state.depositWithdrawal[accountId] = {
                items: [...(state.depositWithdrawal?.[accountId]?.items || []), ...values.items],
                totalCount: values.totalCount,
            };
        },
        updateDepositWithdrawal: (
            state,
            action: PayloadAction<{ accountId: number; transactionId: string; comments: string }>,
        ) => {
            const { accountId, transactionId, comments } = action.payload;
            state.depositWithdrawal[accountId].items = state.depositWithdrawal[
                accountId
            ].items?.map((transaction) => {
                if (transaction.id === transactionId) {
                    return { ...transaction, comments };
                }
                return transaction;
            });
        },
        resetBalances: (state) => {
            state.balances = {};
            state.totalValues = {};
            state.depositWithdrawal = {};
        },
        removeBalancesByAccountId: (state, action: PayloadAction<{ accountId: number }>) => {
            const { accountId } = action.payload;
            if (state.balances?.[accountId]) {
                delete state.balances[accountId];
            }
            if (state.totalValues?.[accountId]) {
                delete state.totalValues[accountId];
            }
            if (state.depositWithdrawal?.[accountId]) {
                delete state.depositWithdrawal[accountId];
            }
        },
        updateBalanceWS: (
            state,
            action: PayloadAction<{
                meta: { accountId: number; accountType: string };
                balance: WSUpdateBalance[];
            }>,
        ) => {
            const { accountId, accountType } = action.payload.meta;
            const { balance: balancesPayloadList } = action.payload;

            if (state.balances?.[accountId]) {
                if (state.balances[accountId]?.[accountType]) {
                    balancesPayloadList.forEach((balance) => {
                        const value =
                            balance?.value || balance?.balance || balance?.balanceUSD || 0;

                        if (state.balances[accountId][accountType]?.[balance.symbol]) {
                            state.balances[accountId][accountType][balance.symbol] = {
                                ...state.balances[accountId][accountType][balance.symbol],
                                ...balance,
                                value,
                            };
                        } else {
                            state.balances[accountId][accountType] = {
                                ...state.balances[accountId][accountType],
                                [balance.symbol]: { ...balance, value },
                            };
                        }
                    });
                } else {
                    const res: BalancesMap | {} = {};
                    balancesPayloadList.forEach((balance) => {
                        const value =
                            balance?.value || balance?.balance || balance?.balanceUSD || 0;
                        res[balance.symbol] = { ...balance, value };
                    });

                    state.balances[accountId] = {
                        ...state.balances[accountId],
                        [accountType]: {
                            balances: res,
                            total: { btc: 0 },
                        },
                    };
                }
            } else {
                const res: BalancesMap | {} = {};
                balancesPayloadList.forEach((balance) => {
                    res[balance.symbol] = { ...balance };
                });

                state.balances = {
                    ...state.balances,
                    [accountId]: {
                        [accountType]: {
                            balances: res,
                            total: { btc: 0 },
                        },
                    },
                };
            }
        },
    },
});

export const {
    setInitialBalances,
    setTotalValues,
    resetBalances,
    updateBalanceWS,
    setDepositWithdrawal,
    updateDepositWithdrawalList,
    updateDepositWithdrawal,
    removeBalancesByAccountId,
} = slice.actions;
export default slice.reducer;
