import { createDeepEqualSelector, RootState } from '@/store/store';
import {
    ApiKeysCount,
    ApiKeysCountWithUpdates,
    BOARD,
    LeaderboardPeriod,
    LeaderboardSettings,
    LeaderBoardTotalScores,
    MemberRank,
    PositionsAnalyticsWithUpdates,
} from '@/state/leaderboard/leaderboardTypes';

const apiKeysCountSelector = (state: RootState): ApiKeysCountWithUpdates =>
    state.leaderboard.apiKeysCount;
const selectedPeriodSelector = (state: RootState): LeaderboardPeriod =>
    state.leaderboard.selectedPeriod;
const totalScoresSelector = (state: RootState): LeaderBoardTotalScores =>
    state.leaderboard.totalScores;
const myRanksSelector = (state: RootState): MemberRank[] => state.leaderboard.memberRanks;
const positionsAnalyticsSelector = (state: RootState): PositionsAnalyticsWithUpdates =>
    state.leaderboard.positionsAnalytics;

const topPnlSelector = (state: RootState): MemberRank[] => state.leaderboard.topPnlRanks;
const tradesRanksSelector = (state: RootState): MemberRank[] => state.leaderboard.tradesRanks;
const volumeRanksSelector = (state: RootState): MemberRank[] => state.leaderboard.volumeRanks;
const pnlRanksSelector = (state: RootState): MemberRank[] => state.leaderboard.pnlRanks;
const settingsSelector = (state: RootState): LeaderboardSettings => state.leaderboard.settings;

export const selectTotalApiKeysCount = createDeepEqualSelector(
    [apiKeysCountSelector],
    ({ exchanges }: { exchanges: ApiKeysCount[] }) => {
        if (!exchanges || !Array.isArray(exchanges) || exchanges.length === 0) return 0;
        return exchanges.reduce((acc, item) => acc + item.count, 0);
    },
);

export const selectApiCountUpdates = createDeepEqualSelector(
    [apiKeysCountSelector],
    ({ lastUpdate, nextUpdate }) => ({
        lastUpdate,
        nextUpdate,
    }),
);

export const selectSelectedPeriod = createDeepEqualSelector(
    [selectedPeriodSelector],
    (period) => period,
);

export const selectTotalScores = createDeepEqualSelector([totalScoresSelector], (scores) => scores);

export const selectMyBestRanks = createDeepEqualSelector([myRanksSelector], (ranks) => {
    const result: any = {
        trades: null,
        pnl: null,
        volume: null,
    };

    ranks.forEach(({ rank, board }) => {
        if (rank !== null && rank !== undefined) {
            if (result[board] === null) {
                result[board] = rank;
            } else {
                if (rank < result[board]) {
                    result[board] = rank;
                }
            }
        }
    });

    return result;
});

export const selectRanksForBoard = createDeepEqualSelector(
    [myRanksSelector, (state: RootState, { board }: { board: BOARD }) => board],
    (ranks, board) => {
        return ranks.filter(
            (rank) => rank.board === board && rank.rank !== null && rank.rank !== undefined,
        );
    },
);

export const selectPositionsAnalytics = createDeepEqualSelector(
    [positionsAnalyticsSelector],
    ({ exchanges }) => {
        if (!exchanges || !Array.isArray(exchanges) || exchanges.length === 0) {
            return {
                short: 0,
                long: 0,
            };
        }

        return {
            short: exchanges.reduce((acc, item) => acc + item.short.count, 0),
            long: exchanges.reduce((acc, item) => acc + item.long.count, 0),
        };
    },
);

export const selectPositionAnalyticsUpdates = createDeepEqualSelector(
    [positionsAnalyticsSelector],
    ({ lastUpdate, nextUpdate }) => ({
        lastUpdate,
        nextUpdate,
    }),
);

export const selectTopPnlRanks = createDeepEqualSelector([topPnlSelector], (ranks) => ranks);

export const selectTradesRanks = createDeepEqualSelector([tradesRanksSelector], (ranks) => ranks);
export const selectVolumeRanks = createDeepEqualSelector([volumeRanksSelector], (ranks) => ranks);
export const selectPnlRanks = createDeepEqualSelector([pnlRanksSelector], (ranks) => ranks);

export const selectLeaderboardSettings = createDeepEqualSelector([settingsSelector], (settings) => {
    return settings;
});
