import { createDeepEqualSelector, RootState } from '@/store/store';
import { FiatCurrency, FiatTransaction, FiatVolumes } from './fiatTypes';

export const fiatVolumesSelector = (state: RootState): FiatVolumes => state.fiat.fiatVolumes;
const transactionsSelector = (state: RootState): FiatTransaction[] => state.fiat.transactionHistory;

const fiatCurrenciesSelector = (state: RootState): FiatCurrency[] => state.fiat.fiatCurrencies;

export const selectFiatVolumes = createDeepEqualSelector(
    [fiatVolumesSelector],
    (volumes) => volumes,
);

export const selectTransactionHistory = createDeepEqualSelector(
    [transactionsSelector],
    (history) => history,
);

export const selectFiatCurrencies = createDeepEqualSelector(
    [fiatCurrenciesSelector],
    (currencies) => currencies,
);

export const selectFiatCurrency = createDeepEqualSelector(
    [fiatVolumesSelector],
    (volumes) => volumes.currency || 'USD',
);
