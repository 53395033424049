import { StorageEntry } from './storageTypes';
import { createDeepEqualSelector, RootState } from '@/store/store';

export const storageListSelector = (state: RootState): StorageEntry[] => state.storage.storageList;
export const storageLoadingSelector = (state: RootState): StorageEntry[] =>
    state.storage.storageLoading;

export const selectStorage = createDeepEqualSelector([storageListSelector], (list) => list);
export const selectStorageLoading = createDeepEqualSelector(
    [storageLoadingSelector],
    (loading) => loading,
);
