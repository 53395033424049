export interface Banner {
    memberId: number;
    apiKeyId: number;
    title: string;
    subtitle: string;
    action: string;
    id: number;
    wasDismissed: boolean;
    dismissedAt: string;
    created: string;
}

export enum PAYMENT_BANNER {
    EXPIRED = 'expiredBanner',
    FAILED = 'failedBanner',
    PAID = 'paidBanner',
}

export enum BANNER_TYPES {
    INVALIDATE_API_KEY = 'invalidateApiKey',
    MARK_API_KEY_AS_FAULTY = 'markApiKeyAsFaulty',
    COINPAYMENT_EXPIRED = 'coinpaymentExpired',
    COINPAYMENT_PAID = 'coinpaymentPaid',
    COINPAYMENT_PENDING = 'coinpaymentPending',
    STRIPE_PAID = 'stripePaid',
    STRIP_FAILED = 'stripeFailed',
    STRIPE_PAYMENT_FAILED = 'stripePaymentFailed',
    STRIPE_EXPIRED = 'stripePaymentSessionExpired',
    STRIPE_CANCELLED = 'stripeCancelled',
    STRIPE_INVOICE_UPCOMING = 'stripeInvoiceUpcoming',
    BAD_API_KEY = 'badApiKey',
    ADMIN_CHANGED_PASSWORD = 'adminChangedPassword',
    ADMIN_CHANGED_EMAIL = 'adminChangedEmail',
    RENEW_API_KEY = 'renewApiKey',
    MARK_API_KEY_AS_VALID = 'markApiKeyAsValid',
    STRIPE_PAYMENT_SESSION_EXPIRED = 'stripePaymentSessionExpired',
    STRIPE_PAUSED = 'stripePaused',
    STRIPE_SUBSCRIPTION_MIGRATE = 'stripeSubscriptionMigrated',
}

export type PaymentBanners = {
    [bannerName in PAYMENT_BANNER]: boolean;
};

export interface TableSort {
    column: string;
    direction: 'asc' | 'desc';
    isSorted: boolean;
}

export enum TABLE {
    JOURNAL_OVERVIEW_CONCLUDED_TRADES = 'journalConcludedTrades',
    JOURNAL_OVERVIEW_OPEN_TRADES = 'journalOpenTrades',
    TRADE_HISTORY = 'tradeHistory',
    ACCOUNT_OPEN_POSITIONS = 'accountOpenPositions',
    ACCOUNT_WITHDRAWALS = 'accountWithdrawals',
    HOME_BALANCES = 'homeBalances',
    HOME_OPEN_POSITIONS = 'homeOpenPositions',
    VERIFICATION_PAGES = 'verificationPages',
}

export type TableSorts = {
    [table in TABLE]: TableSort;
};

export interface PullBanner {
    [accountId: number]: boolean;
}

export interface BannersState {
    paymentBanners: PaymentBanners | {};
    tableSorts: TableSorts;
    pullBanners: PullBanner | {};
    allBanners: Banner[];
}

export type GetAllBannersForMemberResponse = Banner[];

export type DismissBannerPayload = number;
