import { hashString } from './websocketHelpers';
import store from '../../store/store';
import {
    ConversionRequest,
    ConvertCurrencyBulkResponse,
    ConvertCurrencyError,
    ConvertCurrencyRequest,
    EMIT_ACTIONS,
    EmitRequest,
    EventTopic,
    Listener,
    Message,
    MESSAGE_ACTIONS,
    SubscriptionPayload,
} from './websocketTypes';
import GeneralEventsHandler from './GeneralEventsHandler';
import SubscriptionEventsHandler from './SubscriptionEventsHandler';
import ConversionService from './ConversionService';
import { getRandomNumber, randomString } from '../helpers/helpers';
import { setConnectionQuality, setSocketStatus } from '@/state/general/generalSlice';
import { updateRateByKey } from '@/state/shortStorage/shortStorageSlice';
import logger from '@/lib/logger';
import { EXCHANGES } from '@/lib/types/generalTypes';
import { ConnectionQuality } from '@/state/general/generalTypes';

class Websocket {
    private ws: WebSocket | null;
    private readonly url: string;
    private isAuthorized: boolean;
    private requests: Map<string | number, Listener<any>>;
    private subscriptions: Set<string>;
    private authRetryCount: number;
    private closedByUser: boolean;
    private lastHeartBeat: null | number;
    private lastDelay: number;
    private readonly failedToConvertSymbols: Set<string>;
    private interval: ReturnType<typeof setInterval> | null;

    constructor(url: string) {
        this.url = url;
        this.ws = null;
        this.isAuthorized = false;
        this.authRetryCount = 0;
        this.requests = new Map<string, Listener<any>>();
        this.subscriptions = new Set<EventTopic>();
        this.closedByUser = false;
        this.failedToConvertSymbols = new Set<string>();
        this.lastHeartBeat = null;
        this.lastDelay = 0;
        this.interval = null;
    }

    private getInstance(): WebSocket | null {
        return this.ws;
    }

    private heartbeatListener = () => {
        if (this.interval) return;
        this.interval = setInterval(() => {
            const qualityDelays = {
                [ConnectionQuality.HIGH]: 2,
                [ConnectionQuality.MEDIUM]: 3,
                [ConnectionQuality.LOW]: 5,
                [ConnectionQuality.LOOSE_CONNECTION]: 8,
            };

            if (this.lastHeartBeat !== null) {
                const delay = Math.round((Date.now() - this.lastHeartBeat) / 1000);

                const quality = store.getState().general.connectionQuality;
                let newQuality = quality;

                if (delay >= qualityDelays[ConnectionQuality.LOOSE_CONNECTION]) {
                    newQuality = ConnectionQuality.LOOSE_CONNECTION;
                }

                if (
                    delay >= qualityDelays[ConnectionQuality.LOW] &&
                    delay < qualityDelays[ConnectionQuality.LOOSE_CONNECTION]
                ) {
                    newQuality = ConnectionQuality.LOW;
                }

                if (
                    delay >= qualityDelays[ConnectionQuality.MEDIUM] &&
                    delay < qualityDelays[ConnectionQuality.LOW]
                ) {
                    newQuality = ConnectionQuality.MEDIUM;
                }

                if (delay < qualityDelays[ConnectionQuality.MEDIUM]) {
                    newQuality = ConnectionQuality.HIGH;
                }

                if (quality !== newQuality) {
                    store.dispatch(setConnectionQuality(newQuality));
                }
            }
        }, 1000);
    };

    get wasClosed(): boolean {
        return this.closedByUser;
    }

    set wasClosed(value: boolean) {
        this.closedByUser = value;
    }

    connect(): Promise<WebSocket> {
        return new Promise((resolve, reject) => {
            const ws = this.getInstance();
            if (ws) {
                resolve(ws);
                return;
            }

            this.ws = new WebSocket(this.url);
            const connectTimeout = setTimeout(() => {
                clearTimeout(connectTimeout);
                reject(new Error('Connection timeout 20s'));
            }, 20000);

            this.ws.onopen = () => {
                const interval = setInterval(() => {
                    const ws = this.getInstance();
                    if (ws && ws.readyState === 1) {
                        clearTimeout(connectTimeout);
                        clearInterval(interval);
                        this.wasClosed = false;
                        console.info('WS Connected');
                        resolve(ws);
                    }
                }, 500);
            };
            this.ws.onclose = this.onClose;
            this.ws.onmessage = this.onMessage;
            this.heartbeatListener();
        });
    }

    async authorize(token: string): Promise<void> {
        try {
            if (this.isAuthorized) return;

            const ws = this.getInstance();

            if (ws?.readyState !== 1) {
                await Promise.reject(new Error('[AUTH ERROR]: Websocket is not connected'));
                return;
            }
            if (!token) {
                await Promise.reject(new Error('[AUTH ERROR]: No token provided'));
                return;
            }

            await this.send<{ token: string }, void>(EMIT_ACTIONS.AUTH, {
                token,
            });
            this.isAuthorized = true;
            this.authRetryCount = 0;
            store.dispatch(setSocketStatus('connected'));
        } catch (error: any) {
            this.handleAuthorizationError(error);
        }
    }

    disconnect(): void {
        if (this.interval) {
            clearInterval(this.interval);
            this.lastHeartBeat = null;
            store.dispatch(setConnectionQuality(''));
        }

        if (this.ws) {
            this.wasClosed = true;
            this.ws.close();
        }
    }

    private onClose = (): void | Promise<void> => {
        console.info('WS disconnected');
        this.ws = null;
        this.isAuthorized = false;
        this.authRetryCount = 0;
        this.requests.clear();
        this.subscriptions.clear();
        SubscriptionEventsHandler.clearQuoteListeners();
        store.dispatch(setSocketStatus('disconnected'));
        if (this.wasClosed) {
            return;
        } else {
            this.retryConnect();
        }
    };

    private retryConnect = async (): Promise<void> => {
        const timerSeconds = getRandomNumber(2, 5);
        console.info('Retry connect in', timerSeconds, 'seconds');
        const timer = setTimeout(async () => {
            clearTimeout(timer);
            const ws = this.getInstance();
            if (ws && ws.readyState === 1) {
                return;
            }
            await this.connect();
            if (this.isAuthorized) {
                return;
            }
            const token = store.getState().auth.tokens.accessToken;
            if (token) {
                await this.authorize(token);
            }
        }, timerSeconds * 1000);
    };

    public readonly networkDisconnect = (): void | Promise<void> => {
        console.info('Network disconnected');
        this.disconnect();
    };

    /**
     * @name onMessage
     * @description Navigate and handle incoming messages from the websocket server
     * @param messageEvent
     */
    private onMessage = (messageEvent: MessageEvent): void | Promise<void> => {
        if (!messageEvent.data) return;
        const message = JSON.parse(messageEvent.data);

        // Handle response on the sent request messages
        if (message.type === 'reqrep') {
            this.resolveResponseListener(message);
            return;
        }

        // Handle messages from the "general" topic (http backend responses etc.)
        if (message.type === 'event' && message.action === MESSAGE_ACTIONS.GENERAL) {
            GeneralEventsHandler.handleMessage(this.getInstance(), message);
            return;
        }

        // Handle message from the websocket server (positions, balances, quotes etc.)
        if (message.type === 'event' && message.action !== MESSAGE_ACTIONS.HEARTBEAT) {
            SubscriptionEventsHandler.handleMessage(this.getInstance(), message);
        }

        if (message.type === 'event' && message.action === MESSAGE_ACTIONS.HEARTBEAT) {
            this.lastHeartBeat = Date.now();
        }
    };

    private resolveResponseListener(message: Message<any>): void {
        const listener = this.requests.get(message.requestId);
        if (listener) {
            listener(message);
        }
    }

    private waitForWebsocket = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            let tryCount = 0;
            const interval = setInterval(() => {
                const ws = this.getInstance();
                if (this.wasClosed) {
                    clearInterval(interval);
                    reject(new Error('[RETRY]: Websocket was closed by user'));
                    return;
                }
                if (tryCount === 20) {
                    clearInterval(interval);
                    reject(new Error('[RETRY]: Websocket retry failed'));
                    return;
                }
                if (ws && this.isAuthorized) {
                    clearInterval(interval);
                    resolve();
                }
                tryCount++;
            }, 500);
        });
    };

    /**
     * @name send
     * @description Send a Request-Response message to the websocket server
     */
    private send<T, K>(action: EMIT_ACTIONS, payload: T): Promise<Message<K>> {
        return new Promise((resolve, reject) => {
            if (!this.ws) {
                reject(new Error('No websocket connection'));
                return;
            }
            const requestId = hashString(
                `${action}_reqrep_${new Date().getTime()}_${randomString(6)}`,
            );

            const request: EmitRequest<T> = {
                action,
                payload,
                type: 'reqrep',
                requestId,
            };

            // fires on onMessage
            const handleResponse = (messageData) => {
                // remove listener from requests
                this.requests.delete(messageData.requestId);

                if (messageData?.code) {
                    reject(messageData);
                    return;
                }
                resolve(messageData);
                return;
            };

            this.requests.set(requestId, handleResponse);
            this.ws.send(JSON.stringify(request));
        });
    }

    private handleAuthorizationError = (error: any) => {
        this.isAuthorized = false;
        this.disconnect();

        if (this.authRetryCount >= 3) {
            this.authRetryCount = 0;
            logger.error(error);
            return;
        }

        setTimeout(async () => {
            this.authRetryCount += 1;
            const token = store.getState().auth.tokens.accessToken;
            await this.connect();
            await this.authorize(token);
        }, 500);
    };

    // public methods and actions
    connectExchange = async (apiKeyId: number): Promise<void> => {
        try {
            await this.send<{ apiKeyId: number }, void>(EMIT_ACTIONS.CONNECT_EXCHANGE, {
                apiKeyId,
            });
        } catch (error) {
            // TODO Ignore error
            return;
        }
    };

    disconnectExchange = async (apiKeyId: number): Promise<void> => {
        try {
            await this.send<{ apiKeyId: number }, void>(EMIT_ACTIONS.DISCONNECT_EXCHANGE, {
                apiKeyId,
            });
        } catch (error) {
            // TODO Ignore error
            return;
        }
    };

    convertCurrencyBulk = async (
        payload: ConvertCurrencyRequest[],
    ): Promise<ConvertCurrencyBulkResponse[]> => {
        await this.waitForWebsocket();
        const response = await this.send<
            ConvertCurrencyRequest[],
            (ConvertCurrencyBulkResponse | ConvertCurrencyError)[]
        >(EMIT_ACTIONS.CONVERT_BULK, payload);
        const errors: ConvertCurrencyError[] = [];
        const success: ConvertCurrencyBulkResponse[] = [];

        response.payload.forEach((item: any, index: number) => {
            if (item.code) {
                errors.push(item as ConvertCurrencyError);
                if (payload?.[index] && item.code === 500 && item.type === 'ERR_TO_NOT_FOUND') {
                    const symbol = payload[index].from;
                    const exchange = payload[index].exchange?.[0];
                    this.addFailedConversion(`${symbol}|${exchange}`);
                }
            } else {
                const key = `${item.from}|${item.exchange}`;
                if (this.failedToConvertSymbols.has(key)) {
                    this.removeFailedConversion(key);
                }
                success.push(item as ConvertCurrencyBulkResponse);
            }
        });
        return success;
    };

    subscribeToBalances = async (): Promise<void> => {
        try {
            await this.waitForWebsocket();
            if (this.subscriptions.has('balance')) {
                await this.unsubscribeFromBalances();
            }
            await this.send<SubscriptionPayload, void>(EMIT_ACTIONS.SUBSCRIBE, {
                topic: 'balance',
            });
            this.subscriptions.add('balance');
        } catch (error) {
            logger.error(error);
        }
    };

    unsubscribeFromBalances = async (): Promise<void> => {
        try {
            await this.send<SubscriptionPayload, void>(EMIT_ACTIONS.UNSUBSCRIBE, {
                topic: 'balance',
            });
            this.subscriptions.delete('balance');
        } catch (error) {
            logger.error(error);
        }
    };

    subscribeToPositions = async (): Promise<void> => {
        try {
            await this.waitForWebsocket();
            if (this.subscriptions.has('positions')) {
                await this.unsubscribeFromPositions();
            }
            await this.send<SubscriptionPayload, void>(EMIT_ACTIONS.SUBSCRIBE, {
                topic: 'positions',
            });
            this.subscriptions.add('positions');
        } catch (error) {
            logger.error(error);
        }
    };

    unsubscribeFromPositions = async (): Promise<void> => {
        try {
            await this.send<SubscriptionPayload, void>(EMIT_ACTIONS.UNSUBSCRIBE, {
                topic: 'positions',
            });
            this.subscriptions.delete('positions');
        } catch (error) {
            logger.error(error);
        }
    };

    subscribeQuotes = async (filter: any[]): Promise<Message<{ topic: string }>> => {
        try {
            await this.waitForWebsocket();
            return await this.send<SubscriptionPayload, { topic: string }>(EMIT_ACTIONS.SUBSCRIBE, {
                topic: 'quotes',
                filter,
            });
        } catch (error) {
            logger.error(error);
            throw error;
        }
    };

    unsubscribeQuotes = async (topic: string): Promise<any> => {
        try {
            await this.waitForWebsocket();
            return await this.send<SubscriptionPayload, any>(EMIT_ACTIONS.UNSUBSCRIBE, {
                topic,
            });
        } catch (error) {
            logger.error(error);
        }
    };

    async createConversion(conversions: ConversionRequest[], name: string) {
        await this.waitForWebsocket();
        const conversionService = new ConversionService(this);
        return await conversionService.createConversion(conversions, name);
    }

    updateGarbage = (key: string, value: any) => {
        SubscriptionEventsHandler.addGarbage(key, value);
    };

    getGarbage = (key: string) => {
        return SubscriptionEventsHandler.getGarbage(key);
    };

    subscribeToFiatRates = async (): Promise<void> => {
        const toConvert = ['eur', 'gbp'];
        const request: ConversionRequest[] = toConvert.map((symbol) => ({
            from: 'USD',
            to: symbol.toUpperCase(),
            amount: 1,
        }));
        const conversions = await this.createConversion(request, 'fiat-rates');
        request.forEach((conv) => {
            const { from, to, amount } = conv;
            conversions.convert('', from, to, amount, (data) => {
                store.dispatch(updateRateByKey({ key: to.toLowerCase(), value: data.converted }));
            });
        });
    };

    private addFailedConversion = (key: string) => {
        this.failedToConvertSymbols.add(key);
    };

    private removeFailedConversion = (key: string) => {
        this.failedToConvertSymbols.delete(key);
    };

    getFailedConversions = () => {
        return this.failedToConvertSymbols;
    };

    getSymbolIsNotConverted = (symbol: string, exchange: EXCHANGES): boolean => {
        const key = `${symbol}|${exchange}`;
        return this.failedToConvertSymbols.has(key);
    };

    subscribeToSymbolIds = async (
        name: string,
        symbolIds: number[],
        onChange: (payload: any) => void,
    ): Promise<any> => {
        try {
            await this.waitForWebsocket();
            const response = await this.send<SubscriptionPayload, { topic: string }>(
                EMIT_ACTIONS.SUBSCRIBE,
                {
                    topic: 'quotes',
                    filter: symbolIds,
                },
            );

            SubscriptionEventsHandler.addQuoteListener({
                name,
                topic: response.payload.topic,
                listener: onChange,
            });

            return response;
        } catch (error) {
            logger.error(error);
            throw error;
        }
    };

    unsubscribeFromSymbolIds = async (name: string, topic: string): Promise<void> => {
        try {
            if (!topic) return;
            await this.waitForWebsocket();
            await this.send<SubscriptionPayload, void>(EMIT_ACTIONS.UNSUBSCRIBE, {
                topic,
            });
            SubscriptionEventsHandler.removeQuoteListener(name);
        } catch (error) {
            logger.error(error);
        }
    };
}

const instance = new Websocket(import.meta.env.REACT_APP_WS_URL as string);
export default instance;

// retry on connect
// handle close event
