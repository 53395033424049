export enum PLANS {
    BASIC = 'basic',
    PRO = 'pro',
    ENTERPRISE = 'enterprise',
    TRIAL = 'trial',
    FREE = 'free',
    UNLOCKED = 'unlocked',
}

export const PLANS_LABELS = {
    [PLANS.BASIC]: 'basic_plan_label',
    [PLANS.PRO]: 'pro_plan_label',
    [PLANS.ENTERPRISE]: 'enterprise_plan_label',
    [PLANS.TRIAL]: 'trail_plan_label',
    [PLANS.FREE]: 'free_plan_label',
};

export interface UserOrder {
    amount: number;
    created: string;
    currency: string;
    id: number;
    label: string;
    memberId: number;
    payerEmail: string;
    paypalId: string;
    paymentMethod: 'FreeBee' | 'Stripe' | 'PayPal' | 'CoinPayments';
}

export enum SUBSCRIPTION_DURATION {
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
    DIRECT = 'direct',
    FREEBIE = 'freebie',
}

export const SUBSCRIPTION_DURATION_LABELS = {
    [SUBSCRIPTION_DURATION.MONTHLY]: 'monthly_label',
    [SUBSCRIPTION_DURATION.YEARLY]: 'annual_label',
    [SUBSCRIPTION_DURATION.DIRECT]: 'direct_label',
    [SUBSCRIPTION_DURATION.FREEBIE]: 'freebie_label',
};

export enum PAYMENT_METHOD {
    PAYPAL = 'paypal',
    CRYPTO = 'coinpayment',
    STRIPE = 'stripe',
    FREEBIE = 'freebee',
}

export const PRICES = {
    [SUBSCRIPTION_DURATION.MONTHLY]: {
        [PLANS.BASIC]: 0,
        [PLANS.PRO]: 69.99,
        [PLANS.ENTERPRISE]: 89.99,
    },
    [SUBSCRIPTION_DURATION.YEARLY]: {
        [PLANS.BASIC]: 0,
        [PLANS.PRO]: 699.99,
        [PLANS.ENTERPRISE]: 899.99,
    },
};

export interface Price {
    longLabel: string;
    currency: string;
    price: number;
    duration: SUBSCRIPTION_DURATION;
    accountType: PLANS;
}

export enum SUBSCRIPTION_STATUS {
    OPENED = 'opened',
    AUTHORIZED = 'authorized',
    PAID = 'paid',
    CANCELLED = 'cancelled',
    EXPIRED = 'expired',
    FAILED = 'failed',
    PENDING = 'pending_authorization',
}

export interface Subscription {
    created: string;
    updated: string;
    lastCharge: string;
    orderType: PLANS;
    duration: SUBSCRIPTION_DURATION;
    status: SUBSCRIPTION_STATUS;
    id: string | number;
    memberId: number;
    amount: number;
    wasCharged: boolean;
    paymentMethod: PAYMENT_METHOD;
    recurring: false;
    expires: string;
}

export interface Discount {
    id: number;
    discountPrice: number;
    originalPrice: number;
    couponCode: string;
    duration: SUBSCRIPTION_DURATION;
    label: string;
    accountType: PLANS;
}

export interface PaymentState {
    userOrders: UserOrder[];
    prices: Price[];
    subscription: Subscription | null;
    discountCoupons: string;
    discountPercentage: number;
    nonRedeemedCoupons: Discount[];
}

export interface UpgradeLine {
    amount: string;
    description: string;
    discountAmount: string;
}

export interface UpgradeState {
    total: number | string;
    lines: UpgradeLine[];
}
