export const externalUrls = {
    scanner: 'https://coinmarketman.com/scanner/',
    terms: 'https://coinmarketman.com/terms-of-service/',
    privacy: 'https://coinmarketman.com/privacy-policy/',
    security: 'https://coinmarketman.com/security/',
    blog: 'https://coinmarketman.com/blog/',
    about: 'https://coinmarketman.com/about/',
    roadmap: 'https://coinmarketman.com/roadmap/',
    contactus: 'https://coinmarketman.com/contact-us/',
    pricing: 'https://coinmarketman.com/pricing/',
    unlocked: 'https://coinmarketman.com/unlocked/',
    registration: 'https://coinmarketman.com/registration/',
    verification: 'https://coinmarketman.com/features/verification/',
    resetPassword: 'https://coinmarketman.com/reset-password/',
};
