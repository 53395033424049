import { configureStore, getDefaultMiddleware, ReducersMapObject } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import authReducer from '../state/auth/authSlice';
import memberReducer from '../state/member/memberSlice';
import generalReducer from '../state/general/generalSlice';
import exchangesReducer from '../state/exchanges/exchangesSlice';
import fiatReducer from '../state/fiat/fiatSlice';
import storageReducer from '../state/storage/storageSlice';
import legsReducer from '../state/legs/legsSlice';
import balancesReducer from '../state/balances/balancesSlice';
import journalsReducer from '../state/journals/journalsSlice';
import tagsReducer from '../state/tags/tagsSlice';
import paymentsReducer from '../state/payments/paymentsSlice';
import positionsReducer from '../state/positions/positionsSlice';
import reportsReducer from '../state/reports/reportsSlice';
import verificationReducer from '../state/verification/verificationSlice';
import bannersReducer from '../state/banners/bannersSlice';
import shortStorageReducer from '../state/shortStorage/shortStorageSlice';
import orderReducer from '../state/order/orderSlice';
import notificationsReducer from '../state/notifications/notificationsSlice';
import leaderboardReducer from '../state/leaderboard/leaderboardSlice';
import rewardsReducer from '../state/rewards/rewardsSlice';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import logger from '@/lib/logger';

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, {
    equalityCheck: isEqual,
    maxSize: 20,
    resultEqualityCheck: isEqual,
});

const migrate = (state, version) => {
    if (state && state._persist && state._persist.version !== version) {
        return Promise.resolve({});
    }
    if (state && state?.exchangeAccounts && !Array.isArray(state?.exchangeAccounts)) {
        return Promise.resolve({});
    }

    if (state && state?.payments?.discountPercentage && state?.payments?.exchangeAccounts > 11) {
        return Promise.resolve({});
    }

    return Promise.resolve(state);
};

const version = __APP_VERSION__ ? +__APP_VERSION__ : -1;

const hash: string = import.meta.env.REACT_APP_HASH || '';
const env = import.meta.env.REACT_APP_ENVR || 'dev';

const authConfig = {
    key: `@coin_${env}_auth_config`,
    storage,
    version,
};
const memberConfig = {
    key: `@coin_${env}_member_config`,
    storage,
    version,
};

const generalConfig = {
    key: `@coin_${env}_general_config`,
    storage,
    whitelist: ['storedTheme', 'assets', 'gm', 'countries'],
    migrate,
    version,
};

const exchangesConfig = {
    key: `@coin_${env}_exchanges_config`,
    storage,
    blacklist: [
        'isSecondDrawerVisible',
        'selectedExchange',
        'accountBalances',
        'derivativePositions',
        'selectedAccounts',
    ],
    migrate,
    version,
};

const journalConfig = {
    key: `@coin_${env}_journal_config`,
    storage,
    blacklist: ['entryDetails', 'journalEntries', 'threads', 'notes'],
    migrate,
    version,
};

const tagsConfig = {
    key: `@coin_${env}_tags_config`,
    storage,
    migrate,
    version,
};

const legsConfig = {
    key: `@coin_${env}_legs_recheck_config`,
    storage,
    blacklist: ['legsList'],
    migrate,
    version,
};

const fiatConfig = {
    key: `@coin_${env}_fiat_config`,
    storage,
    blacklist: ['fiatVolumes'],
    migrate,
    version,
};

const storageConfig = {
    key: `@coin_${env}_storage_config`,
    storage,
    migrate,
    version,
};

const balancesConfig = {
    key: `@coin_${env}_balances_config`,
    storage,
    blacklist: ['depositWithdrawal'],
    migrate,
    version,
};

const paymentsConfig = {
    key: `@coin_${env}_payments_config`,
    storage,
    migrate,
    version,
    blacklist: [
        'userOrders',
        'subscriptions',
        'prices',
        'nonRedeemedCoupons',
        'discountPercentage',
        'discountCoupons',
    ],
};

const positionsConfig = {
    key: `@coin_${env}_positions_config`,
    storage,
    migrate,
    version,
    blacklist: ['openPositions'],
};

const reportsConfig = {
    key: `@coin_${env}_reports_config`,
    storage: storageSession,
    migrate,
    version,
};

const shortStorageConfig = {
    key: `@coin_${env}_short_storage_config`,
    storage: storageSession,
    migrate,
    version,
    blacklist: ['legsSorting'],
};

const verificationConfig = {
    key: `@coin_${env}_verification_config`,
    storage,
    migrate,
    version,
};

const bannersConfig = {
    key: `@coin_${env}_banners_config`,
    storage,
    migrate,
    version,
};

const orderConfig = {
    key: `@coin_${env}_order_config`,
    storage,
    migrate,
    version,
};

const notificationsConfig = {
    key: `@coin_${env}_notifications_config`,
    storage: storageSession,
    migrate,
    version,
};

const leaderBoardConfig = {
    key: `@coin_${env}_leaderboard_config`,
    storage: storageSession,
    migrate,
    version,
};
const rewardsConfig = {
    key: `@coin_${env}_rewards_config`,
    storage: storageSession,
    migrate,
    version,
};

const reducer: ReducersMapObject = {
    auth: persistReducer(
        {
            ...authConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        authReducer,
    ),
    member: persistReducer(
        {
            ...memberConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        memberReducer,
    ),
    exchanges: persistReducer(
        {
            ...exchangesConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        exchangesReducer,
    ),
    fiat: persistReducer(
        {
            ...fiatConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        fiatReducer,
    ),
    general: persistReducer({ ...generalConfig }, generalReducer),
    storage: persistReducer(
        {
            ...storageConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        storageReducer,
    ),
    legs: persistReducer(
        {
            ...legsConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        legsReducer,
    ),
    balances: persistReducer(balancesConfig, balancesReducer),
    journals: persistReducer(
        {
            ...journalConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        journalsReducer,
    ),
    tags: persistReducer(
        {
            ...tagsConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        tagsReducer,
    ),
    payments: persistReducer(
        {
            ...paymentsConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        paymentsReducer,
    ),
    positions: persistReducer(
        {
            ...positionsConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        positionsReducer,
    ),
    reports: persistReducer(
        {
            ...reportsConfig,
        },
        reportsReducer,
    ),
    verification: persistReducer(
        {
            ...verificationConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        verificationReducer,
    ),
    banners: persistReducer(bannersConfig, bannersReducer),
    shortStorage: persistReducer(
        {
            ...shortStorageConfig,
            transforms: [
                encryptTransform({
                    secretKey: hash,
                }),
            ],
        },
        shortStorageReducer,
    ),
    order: persistReducer(orderConfig, orderReducer),
    notifications: persistReducer(
        {
            ...notificationsConfig,
        },
        notificationsReducer,
    ),
    leaderboard: persistReducer(
        {
            ...leaderBoardConfig,
        },
        leaderboardReducer,
    ),
    rewards: persistReducer(
        {
            ...rewardsConfig,
        },
        rewardsReducer,
    ),
};

const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];
const store = configureStore({
    reducer,
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: {
                // prevent errors about serialization (action with functions or persist)
                ignoredActions,
                warnAfter: 128,
            },
            immutableCheck: { warnAfter: 128 },
        }),
    ],
    devTools: import.meta.env.MODE !== 'production',
    enhancers: [logger.getReduxEnhancer()],
});

/* eslint-disable */
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export default store;
