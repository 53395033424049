import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ENTRIES_TYPES,
    EntriesTotalCount,
    ImportedJournalEntry,
    Journal,
    JournalEntry,
    JournalEntryThread,
    JournalState,
    JournalSymbol,
    Note,
} from './journalTypes';
import { EXCHANGES } from '@/lib/types/generalTypes';
import { SortingModel } from '@/state/general/generalTypes';

const initialState: JournalState = {
    journalsList: {},
    journalSymbols: {},
    journalEntries: {},
    journalEntriesSorting: {
        open: {
            field: 'openDate',
            sort: 'desc',
        },
        concluded: {
            field: 'closeDate',
            sort: 'desc',
        },
    },
    entryDetails: {},
    threads: {},
    notes: { items: [], totalCount: 0 },
};

const slice = createSlice({
    name: 'journals',
    initialState,
    reducers: {
        resetJournals: (state) => {
            state.journalsList = {};
            state.journalEntries = {};
            state.entryDetails = {};
            state.journalSymbols = {};
            state.threads = {};
            state.notes = { items: [], totalCount: 0 };
        },
        setJournals: (state, action: PayloadAction<Journal[]>) => {
            const newJournals = action.payload;

            if (newJournals.length > 0) {
                newJournals.forEach((journal) => {
                    if (state.journalsList?.[journal.id]) {
                        state.journalsList[journal.id] = {
                            ...state.journalsList[journal.id],
                            ...journal,
                        };
                    } else {
                        state.journalsList = {
                            ...state.journalsList,
                            [journal.id]: journal,
                        };
                    }
                });

                const removeJournals = Object.keys(state.journalsList).filter(
                    (journalId) => !newJournals.map((journal) => journal.id).includes(+journalId),
                );

                if (removeJournals.length > 0) {
                    removeJournals.forEach((journalId) => {
                        delete state.journalsList[+journalId];
                    });
                }
            }
        },
        addJournal: (state, action: PayloadAction<Journal>) => {
            state.journalsList = {
                ...state.journalsList,
                [action.payload.id]: action.payload,
            };
        },
        updateJournalById: (state, action: PayloadAction<Journal>) => {
            state.journalsList = {
                ...state.journalsList,
                [action.payload.id]: {
                    ...state.journalsList[action.payload.id],
                    ...action.payload,
                },
            };
        },
        setTempEntry: (state, action: PayloadAction<ImportedJournalEntry>) => {
            state.entryDetails = action.payload;
        },
        setJournalSymbols: (
            state,
            action: PayloadAction<{ journalId: number; symbols: JournalSymbol[] }>,
        ) => {
            state.journalSymbols[action.payload.journalId] = action.payload.symbols;
        },
        clearTempEntry: (state) => {
            state.entryDetails = {};
        },
        updateJournalEntries: (
            state,
            action: PayloadAction<{
                journalId: number | null | string;
                entries: JournalEntry[];
                totalCount?: Partial<EntriesTotalCount>;
            }>,
        ) => {
            const { journalId, entries, totalCount } = action.payload;
            if (!journalId || isNaN(+journalId)) return;

            const currentEntries = [...(state.journalEntries?.[+journalId]?.entries || [])];
            entries.forEach((newEntry) => {
                const entryExists = currentEntries.find((entry) => entry.id === newEntry?.id);
                if (!entryExists) {
                    currentEntries.push(newEntry);
                } else {
                    const index = currentEntries.findIndex((entry) => entry.id === newEntry?.id);
                    if (index !== -1) {
                        currentEntries.splice(index, 1, {
                            ...currentEntries[index],
                            ...newEntry,
                            exchangeId: newEntry?.exchangeId
                                ? (newEntry.exchangeId.toLowerCase() as EXCHANGES)
                                : null,
                        });
                    }
                }
            });

            state.journalEntries = {
                ...state.journalEntries,
                [journalId]: {
                    entries: currentEntries,
                    totalCount: {
                        ...(state.journalEntries?.[journalId]?.totalCount || {}),
                        ...totalCount,
                    },
                },
            };
        },
        replaceJournalEntriesListByType: (
            state,
            action: PayloadAction<{
                journalId: number | null | string;
                entries: JournalEntry[];
                totalCount?: Partial<EntriesTotalCount>;
                type: ENTRIES_TYPES;
            }>,
        ) => {
            const { journalId, entries, totalCount, type } = action.payload;
            if (!journalId || isNaN(+journalId)) return;
            state.journalEntries = {
                ...state.journalEntries,
                [journalId]: {
                    entries: [
                        ...(state.journalEntries?.[+journalId]?.entries || []).filter((el) => {
                            if (type === 'open') return !!el.closeDate;
                            if (type === 'concluded') return !el.closeDate;
                        }),
                        ...entries.map((item) => ({
                            ...item,
                            exchangeId: item?.exchangeId
                                ? (item.exchangeId.toLowerCase() as EXCHANGES)
                                : null,
                        })),
                    ],
                    totalCount: {
                        ...(state.journalEntries?.[journalId]?.totalCount || {}),
                        ...totalCount,
                    },
                },
            };
        },
        clearJournalEntries: (state) => {
            state.journalEntries = {};
        },
        setEntriesSorting: (
            state,
            action: PayloadAction<{ type: string; sortingModel: SortingModel }>,
        ) => {
            state.journalEntriesSorting[action.payload.type] = action.payload.sortingModel;
        },
        addEntry: (state, action: PayloadAction<{ journalId: number; entry: JournalEntry }>) => {
            if (state.journalEntries?.[action.payload.journalId]) {
                state.journalEntries[action.payload.journalId].entries.push(action.payload.entry);
            } else {
                state.journalEntries[action.payload.journalId].entries = [action.payload.entry];
            }
        },
        updateEntry: (state, action: PayloadAction<JournalEntry>) => {
            const { journalId, id } = action.payload;

            state.journalEntries[journalId].entries = state.journalEntries[journalId].entries.map(
                (journalEntry) => {
                    if (journalEntry.id === id) {
                        return {
                            ...journalEntry,
                            ...action.payload,
                        };
                    }

                    return journalEntry;
                },
            );
        },
        deleteEntry: (state, action: PayloadAction<{ journalId: number; id: number }>) => {
            const { journalId, id } = action.payload;

            state.journalEntries[journalId].entries = state.journalEntries[
                journalId
            ].entries.filter((journalEntry) => journalEntry.id !== id);
        },
        setThreads: (
            state,
            action: PayloadAction<{ entryId: number; threads: JournalEntryThread[] }>,
        ) => {
            state.threads = {
                ...state.threads,
                [action.payload.entryId]: action.payload.threads,
            };
        },
        addThread: (state, action: PayloadAction<JournalEntryThread>) => {
            const { entryId } = action.payload;
            if (state.threads[entryId]) {
                state.threads[entryId].push(action.payload);
            } else {
                state.threads[entryId] = [action.payload];
            }
        },
        updateThread: (state, action: PayloadAction<JournalEntryThread>) => {
            const { entryId, id } = action.payload;
            state.threads[entryId] = state.threads[entryId].map((thread) => {
                if (thread.id === id) {
                    return {
                        ...thread,
                        ...action.payload,
                    };
                }

                return thread;
            });
        },
        deleteThread: (state, action: PayloadAction<JournalEntryThread>) => {
            const { entryId, id } = action.payload;
            state.threads[entryId] = state.threads[entryId].filter((thread) => thread.id !== id);
        },
        setNotes: (state, action: PayloadAction<{ items: Note[]; totalCount: number }>) => {
            const updatesNotes = action.payload.items.filter((note) => {
                return !state.notes.items.find((item) => item.id === note.id);
            });

            state.notes = {
                totalCount: action.payload.totalCount,
                items: [...state.notes.items, ...updatesNotes],
            };
        },
        setNotesFromSearch: (
            state,
            action: PayloadAction<{ items: Note[]; totalCount: number }>,
        ) => {
            state.notes = action.payload;
        },
        addNote: (state, action: PayloadAction<Note>) => {
            state.notes.items.push(action.payload);
            state.notes.totalCount += 1;
        },
        deleteNote: (state, action: PayloadAction<Note>) => {
            state.notes.items = state.notes.items.filter((note) => note.id !== action.payload.id);
            state.notes.totalCount -= 1;
        },
        updateNote: (state, action: PayloadAction<Note>) => {
            state.notes.items = state.notes.items.map((note) => {
                if (note.id === action.payload.id) {
                    return {
                        ...note,
                        ...action.payload,
                    };
                }

                return note;
            });
        },
    },
});

export const {
    resetJournals,
    setJournalSymbols,
    setJournals,
    addJournal,
    updateJournalById,
    clearJournalEntries,
    setTempEntry,
    clearTempEntry,
    updateJournalEntries,
    setEntriesSorting,
    replaceJournalEntriesListByType,
    addEntry,
    updateEntry,
    deleteEntry,
    setThreads,
    addThread,
    updateThread,
    deleteThread,
    setNotes,
    setNotesFromSearch,
    addNote,
    deleteNote,
    updateNote,
} = slice.actions;

export default slice.reducer;
