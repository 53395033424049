export const DRAWER_WIDTH = 250;
export const HEADER_HEIGHT = 72;
export const MINIMIZE_DRAWER_WIDTH = 100;

export enum URL_ACTIONS {
    ADD = 'add',
    EDIT = 'edit',
}

export const colorList = [
    '#8BC34A',
    '#B277E1',
    '#FFF9C4',
    '#FFAB00',
    '#377DFF',
    '#FF5722',
    '#FFAB00',
    '#38CB89',
    '#455A64',
    '#00BCD4',
    '#689F38',
    '#579DFF',
    '#CDDC39',
    '#FF5722',
    '#607D8B',
    '#536DFE',
    '#E91E63',
    '#689F38',
    '#455A64',
    '#377DFF',
    '#FFAB00',
    '#38CB89',
    '#B277E1',
    '#727687',
    '#512DA8',
    '#D1C4E9',
    '#00BCD4',
];

export const echartsTheme = {
    version: 1,
    themeName: 'cmm-default',
    theme: {
        seriesCnt: '4',
        backgroundColor: 'transparent',
        textStyle: {
            fontSize: 14,
            fontFamily: 'Manrope',
            fontWeight: 'normal',
            color: '#fff',
        },
        color: ['#FED132'],
    },
};

export const supportEmail = 'support@coinmarketman.com';
export const MAX_WIDTH = '1280px';

//['1m', '5m', '15m', '30m', '1h', '2h', '4h', '6h', '8h', '12h', '1d'],
export const timeframes = [
    // this timeframe should be added to backend validation
    {
        timeframe: '1m',
        seconds: 60,
        duration: {
            from: 0,
            to: 60 * 60,
        },
    },
    {
        timeframe: '5m',
        seconds: 60 * 5,
        duration: {
            from: 60 * 60,
            to: 60 * 60 * 5,
        },
    },
    {
        timeframe: '15m',
        seconds: 60 * 15,
        duration: {
            from: 60 * 60 * 5,
            to: 60 * 60 * 15,
        },
    },
    {
        timeframe: '30m',
        seconds: 60 * 30,
        duration: {
            from: 60 * 60 * 15,
            to: 60 * 60 * 30,
        },
    },
    {
        timeframe: '1h',
        seconds: 60 * 60,
        duration: {
            from: 60 * 60 * 30,
            to: 60 * 60 * 60,
        },
    },
    {
        timeframe: '2h',
        seconds: 60 * 60 * 2,
        duration: {
            from: 60 * 60 * 60,
            to: 60 * 60 * 120,
        },
    },
    {
        timeframe: '4h',
        seconds: 60 * 60 * 4,
        duration: {
            from: 60 * 60 * 120,
            to: 60 * 60 * 240,
        },
    },
    // {
    //     timeframe: '6h',
    //     seconds: 60 * 60 * 6,
    //
    // },
    {
        timeframe: '8h',
        seconds: 60 * 60 * 8,
        duration: {
            from: 60 * 60 * 240,
            to: 60 * 60 * 360,
        },
    },
    {
        timeframe: '12h',
        seconds: 60 * 60 * 12,
        duration: {
            from: 60 * 60 * 360,
            to: 60 * 60 * 720,
        },
    },
    {
        timeframe: '1d',
        seconds: 60 * 60 * 24,
        duration: {
            from: 60 * 60 * 720,
            to: 60 * 60 * 24 * 60, // 60 days
        },
    },
];

export const CAPTCHA_ACTIONS = {
    LOGIN: 'login',
    SIGNUP: 'signup',
    RESET_PASSWORD: 'resetpassword',
    RESEND_EMAIL: 'resendemail',
};

export const COMPANY_DATA = {
    name: 'Coin Market Manager Ltd',
    city: 'London',
    state: 'Uk',
    zip: 'NW42DF',
    email: 'support@coinmarketman.com',
};

export const LISTBOX_PADDING = 8;

export const JOURNAL_NOTES_API_LIMIT = 30;
export const supportedLangsLabels = {
    en: '🇬🇧 English',
    ru: '🇷🇺 Русский',
    uk: '🇺🇦 Українська',
    ko: '🇰🇷 한국어',
    ja: '🇯🇵 日本語',
    zh: '🇨🇳 中文',
};

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
