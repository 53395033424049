import { createDeepEqualSelector, RootState } from '@/store/store';
import { DraftNotes, FiatRate, MultiFetchAccount } from '@/state/shortStorage/shortStorageTypes';
import { memberSelector } from '@/state/member/memberSelectors';
import { ALL_FEATURES, AVAILABLE_FEATURES, SUPPORTED_EXCHANGES } from '@/lib/constants/exchanges';
import { PLANS } from '@/state/payments/paymentsTypes';
import { exchangeAccountsSelector } from '@/state/exchanges/exchangeSelectors';
import { Leg } from '@/state/legs/legsTypes';
import { fiatVolumesSelector } from '@/state/fiat/fiatSelectors';
import { SortingModel } from '@/state/general/generalTypes';

const selectedAccountsSelector = (state: RootState): MultiFetchAccount[] =>
    state.shortStorage.selectedAccounts;
const legsFiltersSelector = (state: RootState): any => state.shortStorage.legsFilters;

const combinedLegsSelector = (state: RootState): Leg[] => state.shortStorage.combinedLegs;
const ratesSelector = (state: RootState): FiatRate => state.shortStorage.fiatRates;
const sortingSelector = (state: RootState): SortingModel => state.shortStorage.legsSorting;
const draftNotesSelector = (state: RootState): DraftNotes => state.shortStorage.draftNotes;

export const selectDraftNoteBySource = createDeepEqualSelector(
    [draftNotesSelector, (state, source: string) => source],
    (draftNotes, source) => {
        return draftNotes[source] || null;
    },
);
export const selectAccountForMultiFetch = createDeepEqualSelector(
    [exchangeAccountsSelector, memberSelector, (state, { tab } = '') => tab],
    (exchangeAccounts, member, tab) => {
        const oldestAccounts: any = {};
        exchangeAccounts.forEach((account) => {
            if (!oldestAccounts[account.exchangeId]) {
                oldestAccounts[account.exchangeId] = account;
            } else {
                if (account.id < oldestAccounts[account.exchangeId].id) {
                    oldestAccounts[account.exchangeId] = account;
                }
            }
        });

        const list: MultiFetchAccount[] = exchangeAccounts.map((account) => {
            let isAnalyticsReady = false;
            let isReportsReady = false;

            const { accountType: memberPlan } = member.profile.accountType;

            if (AVAILABLE_FEATURES[memberPlan].includes(ALL_FEATURES.ANALYTICS)) {
                if (
                    memberPlan === PLANS.PRO &&
                    +account.id === +oldestAccounts[account.exchangeId].id
                ) {
                    isAnalyticsReady = true;
                }

                if (memberPlan !== PLANS.PRO) {
                    isAnalyticsReady = true;
                }
            }

            if (AVAILABLE_FEATURES[memberPlan].includes(ALL_FEATURES.REPORTS)) {
                if (
                    memberPlan === PLANS.PRO &&
                    +account.id === +oldestAccounts[account.exchangeId].id
                ) {
                    isReportsReady = true;
                }

                if (memberPlan !== PLANS.PRO) {
                    isReportsReady = true;
                }
            }

            if (account?.isUnlocked) {
                isAnalyticsReady = true;
                isReportsReady = true;
            }

            return {
                label: account.label,
                exchangeName: SUPPORTED_EXCHANGES?.[account.exchangeId]?.visibleName,
                type: account.exchangeId,
                isUnlocked: account.isUnlocked,
                accountId: +account.id,
                keyError: account.keyError,
                isAnalyticsReady,
                isReportsReady,
            };
        });
        return list;
    },
);

export const selectCombinedLegsSorting = createDeepEqualSelector(
    [sortingSelector],
    (sortingModel) => sortingModel,
);

export const selectSelectedAccounts = createDeepEqualSelector(
    [selectedAccountsSelector],
    (selectedAccounts) => {
        return selectedAccounts;
    },
);

export const selectCombinedLegs = createDeepEqualSelector([combinedLegsSelector], (legs) => legs);

export const selectCurrencyRate = createDeepEqualSelector(
    [ratesSelector, memberSelector],
    (rates, member): number => {
        const activeCurrency = member.activeCurrency || 'usd';
        return rates?.[activeCurrency.toLowerCase()] || 0;
    },
);

export const selectCurrencyRateByKey = createDeepEqualSelector(
    [ratesSelector, (state: RootState, props: { key: string }) => props.key],
    (rates, key) => {
        return rates?.[key.toLowerCase()] || 0;
    },
);

export const selectLegsFilters = createDeepEqualSelector(
    [legsFiltersSelector],
    (dateSelection) => dateSelection,
);

export const selectCurrencyRateHomeFiat = createDeepEqualSelector(
    [ratesSelector, fiatVolumesSelector],
    (rates, volumes): number => {
        const activeCurrency = volumes.currency || 'usd';
        return rates?.[activeCurrency.toLowerCase()] || 0;
    },
);
