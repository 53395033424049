import { EXCHANGES } from '@/lib/types/generalTypes';

export enum LeaderboardPeriod {
    WEEK = '7-days',
    MONTH = '30-days',
    QUARTER = '90-days',
    ALL = 'all',
    YESTERDAY = 'yesterday',
}

export enum BOARD {
    TRADES = 'trades',
    PNL = 'pnl',
    VOLUME = 'volume',
}

export interface ApiKeysCount {
    exchangeId: EXCHANGES;
    count: number;
}

export interface PositionsCount {
    exchange: EXCHANGES;
    short: {
        count: number;
        amountUsd: number;
    };
    long: {
        count: number;
        amountUsd: number;
    };
}

export interface ApiKeysCountWithUpdates {
    exchanges: ApiKeysCount[];
    lastUpdate: string;
    nextUpdate: string;
}

export interface PositionsAnalyticsWithUpdates {
    exchanges: PositionsCount[];
    lastUpdate: string;
    nextUpdate: string;
}

export interface MemberRankDetails {
    memberId: number;
    name: string;
    twitterName?: string;
    twitterId?: string | number;
}

export interface MemberRank {
    memberId: number;
    id: number;
    board: BOARD;
    rank: number | null;
    score: number | null;
    member: MemberRankDetails;
    exchangeId: EXCHANGES;
}

export interface LeaderBoardTotalScores {
    pnl: number;
    trades: number;
    volume: number;
    avgDuration: number;
}

export interface LeaderboardSettings {
    anonymInLeaderBoard: boolean;
}

export interface InitialStateLeaderboard {
    apiKeysCount: ApiKeysCountWithUpdates;
    selectedPeriod: LeaderboardPeriod;
    totalScores: LeaderBoardTotalScores;
    memberRanks: MemberRank[];
    positionsAnalytics: PositionsAnalyticsWithUpdates;
    pnlRanks: MemberRank[];
    volumeRanks: MemberRank[];
    tradesRanks: MemberRank[];
    topPnlRanks: MemberRank[];
    settings: LeaderboardSettings;
}

export interface ShareLeaderboardResponse {
    path: string;
    s3Url: string;
    cloudFrontUrl: string;
}

export enum IMAGE_TEMPLATES {
    PNL = 'PNL',
    VOLUME = 'VOLUME',
    PNL_VOLUME = 'PNL_VOLUME',
}
