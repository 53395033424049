/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Asset,
    ConnectExchangeStatus,
    ConnectExchangeValues,
    Country,
    ErrorModal,
    GeneralInitialValues,
    LOADING_STATE,
    RequestedExchange,
    TimeZone,
    ToggleThemeInterface,
} from './generalTypes';
import { THEME_VARIANTS } from '../member/memberTypes';
import { ExchangeAccount } from '../exchanges/exchangesSliceTypes';

const initialState: GeneralInitialValues = {
    spinner: false,
    storedTheme: THEME_VARIANTS.DARK,
    isSensitive: false,
    connectionQuality: '',
    errorModal: {
        isErrorModalVisible: false,
        errorTitle: null,
        errorCode: null,
        errorText: null,
    },
    connectNewExchange: {
        exchangeValues: {
            apiKey: null,
            secretKey: null,
            label: null,
            selectedExchange: null,
            subAccount: null,
            passphrase: null,
        },
        connectionStatus: {
            testConnection: false,
            fetchingData: false,
            exchangeConnected: false,
        },
    },
    assets: {
        assetsData: [],
        timestamp: null,
        timezones: [],
    },
    requestedExchanges: [],
    dataLoading: {
        apiKeys: {},
        storage: LOADING_STATE.INITIAL,
        fiat: LOADING_STATE.INITIAL,
        exchanges: LOADING_STATE.INITIAL,
        journals: LOADING_STATE.INITIAL,
        combinedLegs: LOADING_STATE.INITIAL,
    },
    pageChangeCandles: null,
    refLink: '',
    countries: [],
    socketConnection: 'initial',
    notificationToast: false,
};

const slice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        toggleSpinner: (state, action: PayloadAction<boolean>) => {
            state.spinner = action.payload;
        },
        toggleTheme: (state, action: PayloadAction<ToggleThemeInterface>) => {
            const { theme } = action.payload;
            if (theme === state.storedTheme) return;
            state.storedTheme = theme;
        },
        toggleSensitive: (state, action: PayloadAction<boolean>) => {
            state.isSensitive = action.payload;
        },
        setConnectExchangeValues: (state, action: PayloadAction<ConnectExchangeValues>) => {
            state.connectNewExchange.exchangeValues = {
                ...state.connectNewExchange.exchangeValues,
                ...action.payload,
            };
        },
        resetConnectExchange: (state) => {
            state.connectNewExchange = {
                ...initialState.connectNewExchange,
            };
        },
        setConnectExchangeStatus: (state, action: PayloadAction<ConnectExchangeStatus>) => {
            state.connectNewExchange.connectionStatus = {
                ...state.connectNewExchange.connectionStatus,
                ...action.payload,
            };
        },
        setModalError: (state, action: PayloadAction<ErrorModal>) => {
            state.errorModal = action.payload;
        },
        setAssets: (state, action: PayloadAction<{ timestamp: string; assets: Asset[] }>) => {
            state.assets.timestamp = action.payload.timestamp;
            state.assets.assetsData = action.payload.assets;
        },
        resetGeneral: (state) => {
            state.connectNewExchange = {
                exchangeValues: {
                    apiKey: null,
                    secretKey: null,
                    label: null,
                    selectedExchange: null,
                    subAccount: null,
                    passphrase: null,
                },
                connectionStatus: {
                    testConnection: false,
                    fetchingData: false,
                    exchangeConnected: false,
                },
            };

            state.spinner = false;
            state.isSensitive = false;
            state.assets = {
                timestamp: null,
                assetsData: [],
                timezones: [],
            };

            state.requestedExchanges = [];

            state.dataLoading = {
                apiKeys: {},
                storage: LOADING_STATE.INITIAL,
                fiat: LOADING_STATE.INITIAL,
                exchanges: LOADING_STATE.INITIAL,
                journals: LOADING_STATE.INITIAL,
                combinedLegs: LOADING_STATE.INITIAL,
            };

            state.countries = [];
            state.refLink = '';
            state.socketConnection = 'initial';
        },
        updateRequestedExchangesList: (state, action: PayloadAction<RequestedExchange[]>) => {
            state.requestedExchanges = action.payload;
        },
        setGeneralLoadingStatus: (
            state,
            action: PayloadAction<{ key: string; status: LOADING_STATE }>,
        ) => {
            const { key, status } = action.payload;
            state.dataLoading[key] = status;
        },
        setKeysForLoading: (state, action: PayloadAction<ExchangeAccount[]>) => {
            action.payload.forEach((exchangeAccount) => {
                state.dataLoading.apiKeys[exchangeAccount.id] = {
                    balances: LOADING_STATE.INITIAL,
                    positions: LOADING_STATE.INITIAL,
                    legs: LOADING_STATE.INITIAL,
                };
            });
        },
        addKeyForLoading: (state, action: PayloadAction<{ accountId: number }>) => {
            state.dataLoading.apiKeys[action.payload.accountId] = {
                balances: LOADING_STATE.INITIAL,
                positions: LOADING_STATE.INITIAL,
                legs: LOADING_STATE.INITIAL,
            };
        },
        setApiKeyLoading: (
            state,
            action: PayloadAction<{
                apiKey: number;
                loadingKey: 'balances' | 'positions' | 'legs';
                status: LOADING_STATE;
            }>,
        ) => {
            const { apiKey, loadingKey, status } = action.payload;
            if (!apiKey) return;
            if (!state.dataLoading.apiKeys?.[apiKey]) return;
            state.dataLoading.apiKeys[apiKey][loadingKey] = status;
        },
        setApiKeyLoadingBulk: (
            state,
            action: PayloadAction<{
                apiKeys: number[];
                loadingKey: 'balances' | 'positions' | 'legs';
                status: LOADING_STATE;
            }>,
        ) => {
            const { apiKeys, loadingKey, status } = action.payload;
            apiKeys.forEach((apiKey) => {
                state.dataLoading.apiKeys[apiKey][loadingKey] = status;
            });
        },
        setGeneralLoadingStatusBulk: (
            state,
            action: PayloadAction<{ keys: string[]; status: LOADING_STATE }>,
        ) => {
            const { keys, status } = action.payload;
            keys.forEach((key) => {
                state.dataLoading[key] = status;
            });
        },
        setPageChangeIndex: (state, action: PayloadAction<number>) => {
            state.pageChangeCandles = action.payload;
        },
        setRefLink: (state, action: PayloadAction<string>) => {
            state.refLink = action.payload;
        },
        setTimezones: (state, action: PayloadAction<TimeZone[]>) => {
            state.assets.timezones = action.payload;
        },
        setCountries: (state, action: PayloadAction<Country[]>) => {
            state.countries = action.payload;
        },
        removeDataLoadingKey: (state, action: PayloadAction<{ apiKeyId: number }>) => {
            const { apiKeyId } = action.payload;
            if (state?.dataLoading?.apiKeys?.[apiKeyId]) {
                delete state.dataLoading.apiKeys[apiKeyId];
            }
        },
        setSocketStatus: (
            state,
            action: PayloadAction<'initial' | 'connected' | 'disconnected'>,
        ) => {
            state.socketConnection = action.payload;
        },
        setConnectionQuality: (state, action: PayloadAction<string>) => {
            state.connectionQuality = action.payload;
        },
        toggleNotificationToast: (state, action: PayloadAction<boolean>) => {
            state.notificationToast = action.payload;
        },
    },
});

export const {
    setConnectionQuality,
    toggleSpinner,
    toggleTheme,
    toggleSensitive,
    setConnectExchangeValues,
    resetConnectExchange,
    setConnectExchangeStatus,
    resetGeneral,
    setModalError,
    setAssets,
    updateRequestedExchangesList,
    setGeneralLoadingStatus,
    setApiKeyLoading,
    setApiKeyLoadingBulk,
    setGeneralLoadingStatusBulk,
    setKeysForLoading,
    addKeyForLoading,
    setPageChangeIndex,
    setRefLink,
    setTimezones,
    setCountries,
    removeDataLoadingKey,
    setSocketStatus,
    toggleNotificationToast,
} = slice.actions;
export default slice.reducer;
