import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Report, ReportsState } from './reportsTypes';

const initialState: ReportsState = {
    accountReports: {},
    combinedReport: {} as Report,
};

const slice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        resetReports: (state) => {
            state.accountReports = {};
            state.combinedReport = {} as Report;
        },
        setReport: (state, action: PayloadAction<{ report: Report; accountId: number }>) => {
            state.accountReports[action.payload.accountId] = action.payload.report;
        },
        setReports: (state, action: PayloadAction<{ reports: Report[] }>) => {
            action.payload.reports.forEach((report) => {
                if (report) {
                    state.accountReports[report.apiKeyId as number] = report;
                }
            });
        },
        removeReport: (state, action: PayloadAction<{ accountId: number }>) => {
            delete state.accountReports[action.payload.accountId];
        },
        setCombinedReport: (state, action: PayloadAction<Report>) => {
            state.combinedReport = action.payload;
        },
        removeCombinedReport: (state) => {
            state.combinedReport = {} as Report;
        },
    },
});

export const {
    resetReports,
    setReport,
    setReports,
    removeReport,
    setCombinedReport,
    removeCombinedReport,
} = slice.actions;

export default slice.reducer;
