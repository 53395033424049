import {
    MessageHandler,
    QuoteListener,
    QuotesWsUpdate,
    SubscriptionBalance,
    SubscriptionEvent,
    SubscriptionPosition,
} from './websocketTypes';
import { WSUpdateBalance } from '@/state/balances/balancesTypes';
import store, { RootState } from '@/store/store';
import { updateBalanceWS } from '@/state/balances/balancesSlice';
import { OpenPosition } from '@/state/positions/positionTypes';
import { EXCHANGES } from '../types/generalTypes';
import {
    ALL_FEATURES,
    AVAILABLE_FEATURES,
    DERIVATIVE_POSITION_STATUS,
    POSITION_DIRECTION,
} from '../constants/exchanges';
import { updatePositionsByAccountId } from '@/state/positions/positionsSlice';
import { PULL_GENERAL_STATUS } from '@/state/exchanges/exchangesSliceTypes';
import { PLANS } from '@/state/payments/paymentsTypes';

import exchangesApi from '@/api/exchangesApi';

class SubscriptionEventsHandler implements MessageHandler {
    private quotesListeners: QuoteListener[];
    // garbage preserves the last conversions state for useConversions hook
    private readonly garbage: { [key: string]: any };
    constructor() {
        this.quotesListeners = [];
        this.garbage = {};
    }

    private getState = (): RootState => store.getState();

    handleMessage = (ws: WebSocket | null, message: SubscriptionEvent<any>): void => {
        if (!ws) return;

        if (message.action.includes('quote')) {
            this.handleQuotes(message);
            return;
        }

        switch (message.action) {
            case 'balance':
                this.handleBalances(message);
                break;
            case 'positions':
                this.handlePositions(message);
                break;
        }
    };

    handleBalances = (message: SubscriptionEvent<SubscriptionBalance[]>): void => {
        const meta = {
            accountId: message.apiKeyId as number,
            accountType: message?.exchange === EXCHANGES.BLOFIN ? 'futures' : message.exchangeType,
        };
        const balance: WSUpdateBalance[] = message.payload.map((balanceItem) => ({
            symbol: balanceItem.asset,
            value: balanceItem.balance || balanceItem.balanceUSD,
            btcValue: balanceItem.balanceBTC,
            ...balanceItem,
        }));
        store.dispatch(updateBalanceWS({ meta, balance }));
    };
    handlePositions = async (message: SubscriptionEvent<SubscriptionPosition[]>): Promise<void> => {
        if (!message?.payload || !Array.isArray(message.payload) || message.payload.length === 0) {
            return;
        }

        // if (message.exchange === EXCHANGES.PHEMEX) {
        //     await balancesPositionsApi.getOpenPositionsByAccountId({
        //         accountId: message.apiKeyId as number,
        //     });
        //     return;
        // }

        const positions: Partial<OpenPosition>[] = message.payload
            .filter((position) => position !== null)
            .map((position) => {
                const amount = position.amount;
                let side: any = position?.side || position?.direction;

                if (amount !== 0 && side === POSITION_DIRECTION.BOTH) {
                    side = amount > 0 ? POSITION_DIRECTION.LONG : POSITION_DIRECTION.SHORT;
                }

                if (side === 'sell') {
                    side = POSITION_DIRECTION.SHORT;
                }

                if (side === 'buy') {
                    side = POSITION_DIRECTION.LONG;
                }

                const contractType = position?.contractType || null;

                const exchange = message.exchange as EXCHANGES;

                return {
                    accountId: message.apiKeyId as number,
                    exchangeId: exchange,
                    status:
                        (position?.action as DERIVATIVE_POSITION_STATUS) ||
                        (position?.status as DERIVATIVE_POSITION_STATUS),
                    symbol: position.symbol,
                    lastUpdated: new Date().toISOString(),
                    liquidationPrice: position?.liquidationPrice || 0,
                    amount: position.amount,
                    entryPrice: position.entryPrice,
                    pnl: position.pnl,
                    direction: side as POSITION_DIRECTION,
                    side: side as POSITION_DIRECTION,
                    digits: position.digits,
                    isUsdt: exchange === EXCHANGES.WOO ? true : Boolean(position?.isUsdt),
                    contractType,
                    posId: position.posId,
                    pnlCurrency: position?.pnlCurrency || '',
                    currency: position?.currency || '',
                };
            });
        store.dispatch(
            updatePositionsByAccountId({ accountId: message.apiKeyId as number, positions }),
        );

        // this.runPull(positions, message.apiKeyId as number, message.exchange)
        //     .then(() => {
        //         console.log('runPull done after handlePositions');
        //     })
        //     .catch((error) => {
        //         console.log('runPull error after handlePositions', error);
        //     });
    };

    handleQuotes = (message: SubscriptionEvent<QuotesWsUpdate>): void => {
        this.quotesListeners.forEach((quoteListener) => {
            if (quoteListener.topic === message.action) {
                quoteListener.listener(message);
            }
        });
    };

    private runPull = async (
        positions: OpenPosition[],
        apiKeyId: number,
        exchange: EXCHANGES,
    ): Promise<void> => {
        try {
            const closedPosition = positions.filter(
                (position) => position.status === DERIVATIVE_POSITION_STATUS.CLOSED,
            );

            if (closedPosition.length === 0) return;

            const currentPullStatus = this.getState().exchanges?.pullStatus?.[apiKeyId];
            if (
                currentPullStatus?.status === PULL_GENERAL_STATUS.STARTED ||
                currentPullStatus?.status === PULL_GENERAL_STATUS.IN_PROGRESS ||
                currentPullStatus?.status === PULL_GENERAL_STATUS.FINALIZING
            ) {
                return;
            }

            let canRunPull: boolean = true;
            const exchangeAccounts = this.getState().exchanges.exchangeAccounts;
            const account = exchangeAccounts?.[exchange]?.[apiKeyId];

            if (account && !account?.isUnlocked) {
                const member = this.getState().member.info;
                canRunPull = AVAILABLE_FEATURES[member.accountType].includes(
                    ALL_FEATURES.PULL_DATA,
                );

                if (member.accountType === PLANS.PRO) {
                    const ids: string[] = [...Object.keys(exchangeAccounts[exchange])];
                    ids.sort((a, b) => +a - +b);
                    if (+ids[0] !== apiKeyId) {
                        canRunPull = false;
                    }
                }
            } else {
                canRunPull = true;
            }

            if (canRunPull) {
                exchangesApi
                    .runPullService({
                        exchange,
                        apiKey: apiKeyId,
                    })
                    .then(() => {
                        console.log('Run pull after closing position');
                    })
                    .catch((error) => {
                        console.error('Pull status error after closed position', error);
                    });
            }
        } catch (error) {
            console.log('runPull error', error);
        }
    };

    addQuoteListener = (quoteListener: QuoteListener): void => {
        this.quotesListeners.push(quoteListener);
    };
    removeQuoteListener = (name: string): void => {
        this.quotesListeners = this.quotesListeners.filter((listener) => listener.name !== name);
    };

    clearQuoteListeners = (): void => {
        this.quotesListeners = [];
    };

    listQuoteListeners = (topic: string | undefined): QuoteListener[] => {
        if (topic) {
            return this.quotesListeners.filter((listener) => listener.topic === topic);
        }
        return this.quotesListeners;
    };

    addGarbage = (key: string, value: any): void => {
        this.garbage[key] = value;
    };
    getGarbage = (key: string): any => {
        return this.garbage[key];
    };
}

const instance = new SubscriptionEventsHandler();

export default instance;
