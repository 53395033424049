import axiosInstance, { API_ROUTES } from '../lib/http/httpRequest';
import { AxiosResponse } from 'axios';
import {
    PAYMENT_METHOD,
    PLANS,
    Price,
    Subscription,
    SUBSCRIPTION_DURATION,
    UpgradeState,
    UserOrder,
} from '@/state/payments/paymentsTypes';
import { Country } from '@/state/general/generalTypes';

interface CPSubscriptionPayload {
    accountType: PLANS;
    duration: SUBSCRIPTION_DURATION;
    params: {
        coupon?: string;
        countryCode?: string;
    };
}

interface CPSubscriptionResponse {
    txn_id: string;
    amount: string | number;
    checkout_url: string;
    qrcode_url: string;
    status_url: string;
    address: string;
    confirms_needed: string | number;
    timeout: number;
}

const getAllSubscriptionDetails = (): Promise<AxiosResponse<Subscription[]>> =>
    axiosInstance.get(`${API_ROUTES.PAYMENTS}/subscriptions`);

const getAllPaypalDetails = (): Promise<AxiosResponse<UserOrder[]>> =>
    axiosInstance.get(`${API_ROUTES.PAYMENTS}/payments`);

const startSubscription = (payload: {
    accountType: PLANS;
    duration: SUBSCRIPTION_DURATION;
    paymentMethod: PAYMENT_METHOD;
    params: any;
}): Promise<AxiosResponse<any>> =>
    axiosInstance.get(
        `${API_ROUTES.PAYMENTS}/${payload.paymentMethod}/subscribe/${payload.accountType}/${payload.duration}`,
        { params: payload.params },
    );

const finalizeSubscription = (payload: {
    paypalId: string;
}): Promise<AxiosResponse<Partial<any>>> =>
    axiosInstance.get(`${API_ROUTES.PAYMENTS}/paypal/finalize-subscription/${payload.paypalId}`);

const cancelSubscription = (payload: { cancelReason: string }): Promise<AxiosResponse<any>> =>
    axiosInstance.post(`${API_ROUTES.PAYMENTS}/paypal/cancel-subscription/`, {
        cancelReason: payload.cancelReason,
    });

const getNonRedeemedCoupons = (): Promise<AxiosResponse<any>> =>
    axiosInstance.get(`${API_ROUTES.COUPONS}/non-redeemed`);

const checkCoupon = ({ couponCode }) =>
    axiosInstance.get(`${API_ROUTES.COUPONS}/checkCoupon/${couponCode}`);

const startCPSubscription = ({
    accountType,
    duration,
    params,
}: CPSubscriptionPayload): Promise<AxiosResponse<CPSubscriptionResponse>> =>
    axiosInstance.get(`${API_ROUTES.PAYMENTS}/cp/order/${accountType}/${duration}`, { params });

const getCountries = (): Promise<AxiosResponse<Country[]>> => axiosInstance.get(`/countries`);

const getCountryByTimezone = (timeZone): Promise<AxiosResponse<string[]>> =>
    axiosInstance.get(`/countries/${timeZone}`);

const getPrices = (): Promise<AxiosResponse<Price[]>> =>
    axiosInstance.get(`${API_ROUTES.PAYMENTS}/prices`);

const getStripePortal = (): Promise<AxiosResponse<{ url: string }>> =>
    axiosInstance.get(`${API_ROUTES.PAYMENTS}/stripe/customer-portal`);

const getStripeSubscriptionInvoice = (payload: {
    accountType: PLANS;
    duration: SUBSCRIPTION_DURATION;
}): Promise<AxiosResponse<UpgradeState>> =>
    axiosInstance.get(
        `${API_ROUTES.PAYMENTS}/stripe/subscription-invoice/${payload.accountType}/${payload.duration}`,
    );

const getCPSubscriptionInvoice = (payload: {
    accountType: PLANS;
    duration: SUBSCRIPTION_DURATION;
}): Promise<AxiosResponse<UpgradeState>> =>
    axiosInstance.get(
        `${API_ROUTES.PAYMENTS}/cp/subscription-invoice/${payload.accountType}/${payload.duration}`,
    );

const upgradeStripeSubscription = (payload: {
    accountType: PLANS;
    subscriptionDuration: SUBSCRIPTION_DURATION;
}) =>
    axiosInstance.get(
        `${API_ROUTES.PAYMENTS}/stripe/upgrade-subscription/${payload.accountType}/${payload.subscriptionDuration}`,
    );
const upgradeCPSubscription = (payload: {
    accountType: PLANS;
    subscriptionDuration: SUBSCRIPTION_DURATION;
}) =>
    axiosInstance.get(
        `${API_ROUTES.PAYMENTS}/cp/upgrade-subscription/${payload.accountType}/${payload.subscriptionDuration}`,
    );

const getActualSubscription = (): Promise<AxiosResponse<Subscription | null>> =>
    axiosInstance.get(`${API_ROUTES.PAYMENTS}/subscription/actual`);

export default {
    getCountryByTimezone,
    getAllSubscriptionDetails,
    getAllPaypalDetails,
    startSubscription,
    finalizeSubscription,
    cancelSubscription,
    getNonRedeemedCoupons,
    checkCoupon,
    startCPSubscription,
    getCountries,
    getPrices,
    getStripePortal,
    getStripeSubscriptionInvoice,
    getCPSubscriptionInvoice,
    upgradeStripeSubscription,
    upgradeCPSubscription,
    getActualSubscription,
};
