import { BROWSER_TAB, MEMBER_STATUS, THEME_VARIANTS } from './memberTypes';
import { PLANS } from '../payments/paymentsTypes';
import { EXCHANGES } from '@/lib/types/generalTypes';

export const INITIAL_SETTINGS = {
    timezone: 'Asia/Jerusalem',
    fiatNativeCurrency: 'USD',
    siteCurrency: 'USD',
    updated: new Date().toISOString(),
    theme: THEME_VARIANTS.DARK,
    browserTabDisplay: BROWSER_TAB.currentSelectedExchangeBtc,
    adminAccessAllowed: false,
    emailMarketingAllowed: true,
    priceHighlight: true,
    maxBreakeven: null,
    minBreakeven: null,
    showGmArt: true,
    isFavoriteMenu: false,
    menuCollapsed: true,
    lang: '',
};

export const INITIAL_USED_SLOTS = {
    [EXCHANGES.BINANCE]: 0,
    [EXCHANGES.FTX]: 0,
    [EXCHANGES.BITMEX]: 0,
    [EXCHANGES.BYBIT]: 0,
    [EXCHANGES.DERIBIT]: 0,
    [EXCHANGES.COINBASE]: 0,
    [EXCHANGES.BITTREX]: 0,
    [EXCHANGES.WOO]: 0,
    [EXCHANGES.BITGET]: 0,
    [EXCHANGES.PHEMEX]: 0,
    [EXCHANGES.BLOFIN]: 0,
    [EXCHANGES.STORAGE]: 0,
};

export const INITIAL_PROFILE = {
    status: MEMBER_STATUS.ACTIVE,
    id: null,
    username: '',
    firstName: '',
    lastName: '',
    country: null,
    email: '',
    userRole: null,
    verified: true,
    role: 2,
    created: new Date().toISOString(),
    accountType: {
        accountType: PLANS.BASIC,
        expiredAt: new Date().toISOString(),
        source: null,
    },
    expires: '',
    method: null,
    unfrozenDate: null,
    duration: null,
    memberStatus: MEMBER_STATUS.ACTIVE,
    slots: {
        usedSlots: { ...INITIAL_USED_SLOTS },
        totalSlots: 0,
    },
};
