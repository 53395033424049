import { EXCHANGES } from '@/lib/types/generalTypes';

export type SecondaryMenuOrder = {
    [exchange in EXCHANGES]: string[] | number[];
};

export enum SORTABLE_MENU_IDS {
    MAIN_MENU = 'mainMenu',
    SECONDARY_MENU = 'secondaryMenu',
    FAVORITE_MENU = 'favoriteMenu',
}

export interface OrderState {
    mainMenu: EXCHANGES[];
    secondaryMenu: SecondaryMenuOrder;
    favoritesMenu: string[] | number[];
}
