import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Banner, BannersState, PAYMENT_BANNER, TABLE, TableSort } from './bannersTypes';

const defaultSort: TableSort = {
    column: '',
    direction: 'desc',
    isSorted: false,
};

const initialState: BannersState = {
    paymentBanners: {
        [PAYMENT_BANNER.EXPIRED]: true,
        [PAYMENT_BANNER.FAILED]: true,
        [PAYMENT_BANNER.PAID]: true,
    },
    tableSorts: {
        [TABLE.JOURNAL_OVERVIEW_CONCLUDED_TRADES]: { ...defaultSort },
        [TABLE.JOURNAL_OVERVIEW_OPEN_TRADES]: { ...defaultSort },
        [TABLE.TRADE_HISTORY]: { ...defaultSort },
        [TABLE.ACCOUNT_OPEN_POSITIONS]: { ...defaultSort },
        [TABLE.ACCOUNT_WITHDRAWALS]: { ...defaultSort },
        [TABLE.HOME_BALANCES]: { ...defaultSort },
        [TABLE.HOME_OPEN_POSITIONS]: { ...defaultSort },
        [TABLE.VERIFICATION_PAGES]: { ...defaultSort },
    },
    pullBanners: {},
    allBanners: [],
};

const slice = createSlice({
    name: 'banners',
    initialState,
    reducers: {
        resetBanners: (state) => {
            state.paymentBanners = {
                [PAYMENT_BANNER.EXPIRED]: true,
                [PAYMENT_BANNER.FAILED]: true,
                [PAYMENT_BANNER.PAID]: true,
            };
            state.tableSorts = {
                [TABLE.JOURNAL_OVERVIEW_CONCLUDED_TRADES]: { ...defaultSort },
                [TABLE.JOURNAL_OVERVIEW_OPEN_TRADES]: { ...defaultSort },
                [TABLE.TRADE_HISTORY]: { ...defaultSort },
                [TABLE.ACCOUNT_OPEN_POSITIONS]: { ...defaultSort },
                [TABLE.ACCOUNT_WITHDRAWALS]: { ...defaultSort },
                [TABLE.HOME_BALANCES]: { ...defaultSort },
                [TABLE.HOME_OPEN_POSITIONS]: { ...defaultSort },
                [TABLE.VERIFICATION_PAGES]: { ...defaultSort },
            };
            state.pullBanners = {};
            state.allBanners = [];
        },
        setPaymentBannerVisibility: (
            state,
            action: PayloadAction<{ bannerName: PAYMENT_BANNER; visible: boolean }>,
        ) => {
            const { bannerName, visible } = action.payload;
            state.paymentBanners[bannerName] = visible;
        },
        setTableSort: (state, action: PayloadAction<{ tableName: TABLE; data: TableSort }>) => {
            const { tableName, data } = action.payload;
            state.tableSorts = {
                ...state.tableSorts,
                [tableName]: data,
            };
        },
        updatePullBanner: (
            state,
            action: PayloadAction<{ accountId: number; visible: boolean }>,
        ) => {
            const { accountId, visible } = action.payload;
            state.pullBanners = {
                ...state.pullBanners,
                [accountId]: visible,
            };
        },
        removeBannerByApiKey: (state, action: PayloadAction<{ apiKeyId: number }>) => {
            const { apiKeyId } = action.payload;
            if (state.pullBanners.hasOwnProperty(apiKeyId)) {
                delete state.pullBanners[apiKeyId];
            }
        },
        setBannersList: (state, action: PayloadAction<Banner[]>) => {
            state.allBanners = action.payload;
        },
        updateBannersList: (state, action: PayloadAction<Banner>) => {
            state.allBanners.push(action.payload);
        },
        removeDismissedBanner: (state, action: PayloadAction<number>) => {
            state.allBanners = state.allBanners.filter((el) => el.id !== action.payload);
        },
    },
});

export const {
    resetBanners,
    setPaymentBannerVisibility,
    setTableSort,
    updatePullBanner,
    setBannersList,
    updateBannersList,
    removeDismissedBanner,
    removeBannerByApiKey,
} = slice.actions;

export default slice.reducer;
