import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DATE_PRESET_LABELS,
    Leg,
    LegExecutions,
    LegNote,
    LegNotes,
    LegsListItem,
    LegsState,
    SymbolsAnalyticsData,
    SymbolsFilterItem,
} from './legsTypes';
import { endOfDay, startOfDay } from 'date-fns';
import { SortingModel } from '@/state/general/generalTypes';
import { ResponseWithPagination } from '@/lib/http/types';

const initialState: LegsState = {
    legsList: {},
    latestLegs: {},
    symbolFilter: [],
    legsAnalytics: {},
    legsSorting: {},
    dateSelection: {
        startDate: startOfDay(new Date('01/06/2016')).toISOString(),
        endDate: endOfDay(new Date('01/06/2016')).toISOString(),
        label: DATE_PRESET_LABELS.LAST_50_TRADES,
    },
    legsExecutions: {},
    legsNotes: {},
    symbolsList: [],
};

const slice = createSlice({
    name: 'legs',
    initialState,
    reducers: {
        setLegsById: (state, action: PayloadAction<{ accountId: number; legs: LegsListItem }>) => {
            state.legsList[action.payload.accountId] = action.payload.legs;
        },
        addToLegsListById: (
            state,
            action: PayloadAction<{ accountId: number; legs: LegsListItem }>,
        ) => {
            state.legsList[action.payload.accountId].items = [
                ...state.legsList[action.payload.accountId].items,
                ...action.payload.legs.items,
            ];
        },
        setLegsSortingById: (
            state,
            action: PayloadAction<{ accountId: number; sorting: SortingModel }>,
        ) => {
            state.legsSorting[action.payload.accountId] = action.payload.sorting;
        },
        setLastedLegsById: (
            state,
            action: PayloadAction<{ accountId: number; legs: LegsListItem }>,
        ) => {
            state.latestLegs[action.payload.accountId] = action.payload.legs;
        },
        updateLeg: (state, action: PayloadAction<Leg>) => {
            const legList = state.legsList?.[action.payload.apiKeyId];
            const latestLegs = state.latestLegs?.[action.payload.apiKeyId];

            if (legList && legList?.items) {
                const updatedItemIndex = state.legsList[action.payload.apiKeyId].items.findIndex(
                    (el) => el.id === action.payload.id,
                );

                if (updatedItemIndex >= 0) {
                    state.legsList[action.payload.apiKeyId].items.splice(
                        updatedItemIndex,
                        1,
                        action.payload,
                    );
                }
            }

            if (latestLegs) {
                const updatedLastedLegsIndex = state.latestLegs[
                    action.payload.apiKeyId
                ].items.findIndex((el) => el.id === action.payload.id);
                if (updatedLastedLegsIndex >= 0) {
                    state.latestLegs[action.payload.apiKeyId].items.splice(
                        updatedLastedLegsIndex,
                        1,
                        action.payload,
                    );
                }
            }
        },
        updateSymbolFilter: (state, action: PayloadAction<string[]>) => {
            state.symbolFilter = action.payload;
        },
        setLegsExecutions: (state, action: PayloadAction<{ [key: string]: LegExecutions }>) => {
            state.legsExecutions = { ...state.legsExecutions, ...action.payload };
        },
        setLegsNotes: (state, action: PayloadAction<{ [key: string]: LegNotes }>) => {
            state.legsNotes = { ...state.legsNotes, ...action.payload };
        },
        addLegNote: (state, action: PayloadAction<LegNote>) => {
            if (
                state.legsNotes[action.payload.legId] &&
                Array.isArray(state.legsNotes[action.payload.legId])
            ) {
                const notes = [...state.legsNotes[action.payload.legId]];
                notes.push(action.payload);
                state.legsNotes[action.payload.legId] = notes;
            } else {
                state.legsNotes[action.payload.legId] = [action.payload];
            }
        },
        updateLegNote: (state, action: PayloadAction<LegNote>) => {
            const updatedItemIndex = state.legsNotes[action.payload.legId].findIndex(
                (el) => el.id === action.payload.id,
            );
            if (updatedItemIndex >= 0) {
                state.legsNotes[action.payload.legId].splice(updatedItemIndex, 1, action.payload);
            }
        },
        removeLegNote: (state, action: PayloadAction<{ id: number; legId: number }>) => {
            const updatedItemIndex = state.legsNotes[action.payload.legId].findIndex(
                (el) => el.id === action.payload.id,
            );
            if (updatedItemIndex >= 0) {
                state.legsNotes[action.payload.legId].splice(updatedItemIndex, 1);
            }
        },
        updateDateSelection: (
            state,
            action: PayloadAction<{
                startDate: string;
                endDate: string;
                label: DATE_PRESET_LABELS;
            }>,
        ) => {
            state.dateSelection = action.payload;
        },
        resetLegs: (state) => {
            state.legsList = {};
            state.symbolFilter = [];
            state.latestLegs = {};
            (state.legsSorting = {}),
                (state.legsAnalytics = {}),
                (state.dateSelection = {
                    startDate: startOfDay(new Date('01/06/2016')).toISOString(),
                    endDate: endOfDay(new Date('01/06/2016')).toISOString(),
                    label: DATE_PRESET_LABELS.LAST_50_TRADES,
                });
            state.symbolsList = [];
            state.legsExecutions = {};
            state.legsNotes = {};
        },
        resetSymbolsList: (state) => {
            state.symbolsList = [];
        },
        resetSymbolFilter: (state) => {
            state.symbolFilter = [];
        },
        setSymbolsList: (state, action: PayloadAction<SymbolsFilterItem[]>) => {
            state.symbolsList = action.payload;
        },
        removeLegsByAccountId: (state, action: PayloadAction<{ accountId: number }>) => {
            const { accountId } = action.payload;
            if (state.legsList?.[accountId]) {
                delete state.legsList[accountId];
            }
        },
        setLegsAnalytics: (state, action: PayloadAction<any>) => {
            state.legsAnalytics = { ...state.legsAnalytics, ...action.payload };
        },
        setSymbolsAnalytics: (
            state,
            action: PayloadAction<ResponseWithPagination<SymbolsAnalyticsData>>,
        ) => {
            state.legsAnalytics = { ...state.legsAnalytics, ...action.payload };
        },
        setSymbolsAnalyticsSorting: (
            state,
            action: PayloadAction<{ [key: string]: SortingModel }>,
        ) => {
            state.legsAnalytics = { ...state.legsAnalytics, ...action.payload };
        },
        updateSymbolsAnalytics: (
            state,
            action: PayloadAction<ResponseWithPagination<SymbolsAnalyticsData>>,
        ) => {
            state.legsAnalytics = {
                ...state.legsAnalytics,
                symbolsAnalytics: {
                    ...(state.legsAnalytics.symbolsAnalytics || {}),
                    items: [
                        ...((state.legsAnalytics.symbolsAnalytics?.items ||
                            []) as SymbolsAnalyticsData[]),
                        ...action.payload.items,
                    ],
                    totalCount: action.payload.totalCount,
                },
            };
        },
    },
});

export const {
    setLegsById,
    resetLegs,
    setLastedLegsById,
    updateLeg,
    setLegsExecutions,
    addLegNote,
    updateLegNote,
    removeLegNote,
    setLegsNotes,
    updateSymbolFilter,
    updateDateSelection,
    setLegsAnalytics,
    setSymbolsAnalytics,
    resetSymbolsList,
    resetSymbolFilter,
    setSymbolsList,
    removeLegsByAccountId,
    addToLegsListById,
    setLegsSortingById,
    updateSymbolsAnalytics,
    setSymbolsAnalyticsSorting,
} = slice.actions;

export default slice.reducer;
