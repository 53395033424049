import { createDeepEqualSelector, RootState } from '../../store/store';
import { VerificationItem } from './verificationTypes';

const pagesSelector = (state: RootState): VerificationItem[] => state.verification.pages;

export const selectVerificationPages = createDeepEqualSelector([pagesSelector], (pages) => pages);

export const selectVerificationPageById = createDeepEqualSelector(
    [pagesSelector, (state, { pageId }) => pageId],
    (pages, id) => {
        const page = pages.find((page) => Number(page.id) === Number(id));
        return (page || {}) as VerificationItem;
    },
);
