import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_PROFILE, INITIAL_SETTINGS, INITIAL_USED_SLOTS } from './initialMember';
import { CURRENCIES } from '@/lib/types/generalTypes';
import {
    AccountType,
    ConnectedTwitterData,
    InitialMemberState,
    MemberSettings,
    Profile,
    UsedSlots,
} from './memberTypes';

const initialState: InitialMemberState = {
    isAuth: false,
    twitterAccount: null,
    activeCurrency: CURRENCIES.USD,
    settings: INITIAL_SETTINGS,
    profile: { ...INITIAL_PROFILE },
    referrer: null,
};

const slice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        authorize: (state) => {
            state.isAuth = true;
        },
        resetMember: (state) => {
            state.isAuth = false;
            state.twitterAccount = null;
            state.profile = INITIAL_PROFILE;
            state.settings = INITIAL_SETTINGS;
            state.activeCurrency = CURRENCIES.USD;
            state.referrer = null;
        },
        setCurrency: (state, action: PayloadAction<CURRENCIES>) => {
            state.activeCurrency = action.payload;
        },
        verifyUser: (state) => {
            state.profile.verified = true;
        },
        updateSettings: (state, action: PayloadAction<Partial<MemberSettings>>) => {
            state.settings = {
                ...state.settings,
                ...action.payload,
            };
        },
        setSettings: (state, action: PayloadAction<MemberSettings>) => {
            state.settings = action.payload;
        },
        setTwitterData: (state, action: PayloadAction<ConnectedTwitterData | null>) => {
            state.twitterAccount = action.payload;
        },

        updateMemberProfileWS: (state, action: PayloadAction<any>) => {
            state.profile.accountType.accountType = action.payload.accountType;
            state.profile.accountType.expiredAt = action.payload.expires;
            state.profile.accountType.source = action.payload.source;
            state.profile.slots.totalSlots = action.payload.totalSlots;
        },

        updateMemberProfile: (state, action: PayloadAction<Profile>) => {
            const slots = { ...action.payload.slots };
            const accountType = { ...action.payload.accountType };
            const info = { ...action.payload };
            // @ts-ignore
            delete info.slots;
            // @ts-ignore
            delete info.accountType;
            const stateSlots = { ...(state.profile?.slots?.usedSlots || {}) };

            info.slots = {
                totalSlots: slots.totalSlots as number,
                usedSlots: {
                    ...INITIAL_USED_SLOTS,
                    ...stateSlots,
                },
            };

            if (Object.keys(slots?.usedSlots || {}).length > 0) {
                info.slots.usedSlots = {
                    ...info.slots.usedSlots,
                    ...slots.usedSlots,
                };
            }

            // @ts-ignore
            info.accountType = {
                ...state.profile.accountType,
                ...accountType,
            };

            state.profile = {
                ...state.profile,
                ...info,
            };
        },
        updateProfileParts: (state, action: PayloadAction<Partial<Profile>>) => {
            state.profile = {
                ...state.profile,
                ...action.payload,
            };
        },
        updateUsedSlots: (state, action: PayloadAction<UsedSlots>) => {
            state.profile.slots.usedSlots = action.payload;
        },
        updateAccountType: (state, action: PayloadAction<AccountType>) => {
            state.profile.accountType = {
                ...state.profile.accountType,
                ...action.payload,
            };
        },
        setReferrer: (state, action: PayloadAction<string | null>) => {
            state.referrer = action.payload;
        },
    },
});

export const {
    authorize,
    resetMember,
    setCurrency,
    verifyUser,
    setSettings,
    updateSettings,
    setTwitterData,
    updateMemberProfile,
    updateMemberProfileWS,
    updateProfileParts,
    updateUsedSlots,
    updateAccountType,
    setReferrer,
} = slice.actions;
export default slice.reducer;
