export default {
    aed: {
        cc: 'aed',
        symbol: 'د.إ;',
        name: 'UAE dirham',
    },
    afn: {
        cc: 'afn',
        symbol: 'Afs',
        name: 'Afghan afghani',
    },
    all: {
        cc: 'all',
        symbol: 'L',
        name: 'Albanian lek',
    },
    amd: {
        cc: 'amd',
        symbol: 'AMD',
        name: 'Armenian dram',
    },
    ang: {
        cc: 'ang',
        symbol: 'NAƒ',
        name: 'Netherlands Antillean gulden',
    },
    aoa: {
        cc: 'aoa',
        symbol: 'Kz',
        name: 'Angolan kwanza',
    },
    ars: {
        cc: 'ars',
        symbol: '$',
        name: 'Argentine peso',
    },
    aud: {
        cc: 'aud',
        symbol: '$',
        name: 'Australian dollar',
    },
    awg: {
        cc: 'awg',
        symbol: 'ƒ',
        name: 'Aruban florin',
    },
    azn: {
        cc: 'azn',
        symbol: 'AZN',
        name: 'Azerbaijani manat',
    },
    bam: {
        cc: 'bam',
        symbol: 'KM',
        name: 'Bosnia and Herzegovina konvertibilna marka',
    },
    bbd: {
        cc: 'bbd',
        symbol: 'Bds$',
        name: 'Barbadian dollar',
    },
    bdt: {
        cc: 'bdt',
        symbol: '৳',
        name: 'Bangladeshi taka',
    },
    bgn: {
        cc: 'bgn',
        symbol: 'BGN',
        name: 'Bulgarian lev',
    },
    bhd: {
        cc: 'bhd',
        symbol: '.د.ب',
        name: 'Bahraini dinar',
    },
    bif: {
        cc: 'bif',
        symbol: 'FBu',
        name: 'Burundi franc',
    },
    bmd: {
        cc: 'bmd',
        symbol: 'BD$',
        name: 'Bermudian dollar',
    },
    bnd: {
        cc: 'bnd',
        symbol: 'B$',
        name: 'Brunei dollar',
    },
    bob: {
        cc: 'bob',
        symbol: 'Bs.',
        name: 'Bolivian boliviano',
    },
    brl: {
        cc: 'brl',
        symbol: 'R$',
        name: 'Brazilian real',
    },
    bsd: {
        cc: 'bsd',
        symbol: 'B$',
        name: 'Bahamian dollar',
    },
    btn: {
        cc: 'btn',
        symbol: 'Nu.',
        name: 'Bhutanese ngultrum',
    },
    bwp: {
        cc: 'bwp',
        symbol: 'P',
        name: 'Botswana pula',
    },
    byr: {
        cc: 'byr',
        symbol: 'Br',
        name: 'Belarusian ruble',
    },
    bzd: {
        cc: 'bzd',
        symbol: 'BZ$',
        name: 'Belize dollar',
    },
    cad: {
        cc: 'cad',
        symbol: '$',
        name: 'Canadian dollar',
    },
    cdf: {
        cc: 'cdf',
        symbol: 'F',
        name: 'Congolese franc',
    },
    chf: {
        cc: 'chf',
        symbol: 'Fr.',
        name: 'Swiss franc',
    },
    clp: {
        cc: 'clp',
        symbol: '$',
        name: 'Chilean peso',
    },
    cny: {
        cc: 'cny',
        symbol: '¥',
        name: 'Chinese/Yuan renminbi',
    },
    cop: {
        cc: 'cop',
        symbol: 'Col$',
        name: 'Colombian peso',
    },
    crc: {
        cc: 'crc',
        symbol: '₡',
        name: 'Costa Rican colon',
    },
    cuc: {
        cc: 'cuc',
        symbol: '$',
        name: 'Cuban peso',
    },
    cve: {
        cc: 'cve',
        symbol: 'Esc',
        name: 'Cape Verdean escudo',
    },
    czk: {
        cc: 'czk',
        symbol: 'Kč',
        name: 'Czech koruna',
    },
    djf: {
        cc: 'djf',
        symbol: 'Fdj',
        name: 'Djiboutian franc',
    },
    dkk: {
        cc: 'dkk',
        symbol: 'Kr',
        name: 'Danish krone',
    },
    dop: {
        cc: 'dop',
        symbol: 'RD$',
        name: 'Dominican peso',
    },
    dzd: {
        cc: 'dzd',
        symbol: 'د.ج',
        name: 'Algerian dinar',
    },
    eek: {
        cc: 'eek',
        symbol: 'KR',
        name: 'Estonian kroon',
    },
    egp: {
        cc: 'egp',
        symbol: '£',
        name: 'Egyptian pound',
    },
    ern: {
        cc: 'ern',
        symbol: 'Nfa',
        name: 'Eritrean nakfa',
    },
    etb: {
        cc: 'etb',
        symbol: 'Br',
        name: 'Ethiopian birr',
    },
    eur: {
        cc: 'eur',
        symbol: '€',
        name: 'European Euro',
    },
    fjd: {
        cc: 'fjd',
        symbol: 'FJ$',
        name: 'Fijian dollar',
    },
    fkp: {
        cc: 'fkp',
        symbol: '£',
        name: 'Falkland Islands pound',
    },
    gbp: {
        cc: 'gbp',
        symbol: '£',
        name: 'British pound',
    },
    gel: {
        cc: 'gel',
        symbol: 'GEL',
        name: 'Georgian lari',
    },
    ghs: {
        cc: 'ghs',
        symbol: 'GH₵',
        name: 'Ghanaian cedi',
    },
    gip: {
        cc: 'gip',
        symbol: '£',
        name: 'Gibraltar pound',
    },
    gmd: {
        cc: 'gmd',
        symbol: 'D',
        name: 'Gambian dalasi',
    },
    gnf: {
        cc: 'gnf',
        symbol: 'FG',
        name: 'Guinean franc',
    },
    gqe: {
        cc: 'gqe',
        symbol: 'CFA',
        name: 'Central African CFA franc',
    },
    gtq: {
        cc: 'gtq',
        symbol: 'Q',
        name: 'Guatemalan quetzal',
    },
    gyd: {
        cc: 'gyd',
        symbol: 'GY$',
        name: 'Guyanese dollar',
    },
    hkd: {
        cc: 'hkd',
        symbol: 'HK$',
        name: 'Hong Kong dollar',
    },
    hnl: {
        cc: 'hnl',
        symbol: 'L',
        name: 'Honduran lempira',
    },
    hrk: {
        cc: 'hrk',
        symbol: 'kn',
        name: 'Croatian kuna',
    },
    htg: {
        cc: 'htg',
        symbol: 'G',
        name: 'Haitian gourde',
    },
    huf: {
        cc: 'huf',
        symbol: 'Ft',
        name: 'Hungarian forint',
    },
    idr: {
        cc: 'idr',
        symbol: 'Rp',
        name: 'Indonesian rupiah',
    },
    ils: {
        cc: 'ils',
        symbol: '₪',
        name: 'Israeli new sheqel',
    },
    inr: {
        cc: 'inr',
        symbol: '₹',
        name: 'Indian rupee',
    },
    iqd: {
        cc: 'iqd',
        symbol: 'د.ع',
        name: 'Iraqi dinar',
    },
    irr: {
        cc: 'irr',
        symbol: 'IRR',
        name: 'Iranian rial',
    },
    isk: {
        cc: 'isk',
        symbol: 'kr',
        name: 'Icelandic króna',
    },
    jmd: {
        cc: 'jmd',
        symbol: 'J$',
        name: 'Jamaican dollar',
    },
    jod: {
        cc: 'jod',
        symbol: 'JOD',
        name: 'Jordanian dinar',
    },
    jpy: {
        cc: 'jpy',
        symbol: '¥',
        name: 'Japanese yen',
    },
    kes: {
        cc: 'kes',
        symbol: 'KSh',
        name: 'Kenyan shilling',
    },
    kgs: {
        cc: 'kgs',
        symbol: 'сом',
        name: 'Kyrgyzstani som',
    },
    khr: {
        cc: 'khr',
        symbol: '៛',
        name: 'Cambodian riel',
    },
    kmf: {
        cc: 'kmf',
        symbol: 'KMF',
        name: 'Comorian franc',
    },
    kpw: {
        cc: 'kpw',
        symbol: 'W',
        name: 'North Korean won',
    },
    krw: {
        cc: 'krw',
        symbol: 'W',
        name: 'South Korean won',
    },
    kwd: {
        cc: 'kwd',
        symbol: 'KWD',
        name: 'Kuwaiti dinar',
    },
    kyd: {
        cc: 'kyd',
        symbol: 'KY$',
        name: 'Cayman Islands dollar',
    },
    kzt: {
        cc: 'kzt',
        symbol: 'T',
        name: 'Kazakhstani tenge',
    },
    lak: {
        cc: 'lak',
        symbol: 'KN',
        name: 'Lao kip',
    },
    lbp: {
        cc: 'lbp',
        symbol: '£',
        name: 'Lebanese lira',
    },
    lkr: {
        cc: 'lkr',
        symbol: 'Rs',
        name: 'Sri Lankan rupee',
    },
    lrd: {
        cc: 'lrd',
        symbol: 'L$',
        name: 'Liberian dollar',
    },
    lsl: {
        cc: 'lsl',
        symbol: 'M',
        name: 'Lesotho loti',
    },
    ltl: {
        cc: 'ltl',
        symbol: 'Lt',
        name: 'Lithuanian litas',
    },
    lvl: {
        cc: 'lvl',
        symbol: 'Ls',
        name: 'Latvian lats',
    },
    lyd: {
        cc: 'lyd',
        symbol: 'LD',
        name: 'Libyan dinar',
    },
    mad: {
        cc: 'mad',
        symbol: 'MAD',
        name: 'Moroccan dirham',
    },
    mdl: {
        cc: 'mdl',
        symbol: 'MDL',
        name: 'Moldovan leu',
    },
    mga: {
        cc: 'mga',
        symbol: 'FMG',
        name: 'Malagasy ariary',
    },
    mkd: {
        cc: 'mkd',
        symbol: 'MKD',
        name: 'Macedonian denar',
    },
    mmk: {
        cc: 'mmk',
        symbol: 'K',
        name: 'Myanma kyat',
    },
    mnt: {
        cc: 'mnt',
        symbol: '₮',
        name: 'Mongolian tugrik',
    },
    mop: {
        cc: 'mop',
        symbol: 'P',
        name: 'Macanese pataca',
    },
    mro: {
        cc: 'mro',
        symbol: 'UM',
        name: 'Mauritanian ouguiya',
    },
    mur: {
        cc: 'mur',
        symbol: 'Rs',
        name: 'Mauritian rupee',
    },
    mvr: {
        cc: 'mvr',
        symbol: 'Rf',
        name: 'Maldivian rufiyaa',
    },
    mwk: {
        cc: 'mwk',
        symbol: 'MK',
        name: 'Malawian kwacha',
    },
    mxn: {
        cc: 'mxn',
        symbol: '$',
        name: 'Mexican peso',
    },
    myr: {
        cc: 'myr',
        symbol: 'RM',
        name: 'Malaysian ringgit',
    },
    mzm: {
        cc: 'mzm',
        symbol: 'MTn',
        name: 'Mozambican metical',
    },
    nad: {
        cc: 'nad',
        symbol: 'N$',
        name: 'Namibian dollar',
    },
    ngn: {
        cc: 'ngn',
        symbol: '₦',
        name: 'Nigerian naira',
    },
    nio: {
        cc: 'nio',
        symbol: 'C$',
        name: 'Nicaraguan córdoba',
    },
    nok: {
        cc: 'nok',
        symbol: 'kr',
        name: 'Norwegian krone',
    },
    npr: {
        cc: 'npr',
        symbol: 'NRs',
        name: 'Nepalese rupee',
    },
    nzd: {
        cc: 'nzd',
        symbol: 'NZ$',
        name: 'New Zealand dollar',
    },
    omr: {
        cc: 'omr',
        symbol: 'OMR',
        name: 'Omani rial',
    },
    pab: {
        cc: 'pab',
        symbol: 'B./',
        name: 'Panamanian balboa',
    },
    pen: {
        cc: 'pen',
        symbol: 'S/.',
        name: 'Peruvian nuevo sol',
    },
    pgk: {
        cc: 'pgk',
        symbol: 'K',
        name: 'Papua New Guinean kina',
    },
    php: {
        cc: 'php',
        symbol: '₱',
        name: 'Philippine peso',
    },
    pkr: {
        cc: 'pkr',
        symbol: 'Rs.',
        name: 'Pakistani rupee',
    },
    pln: {
        cc: 'pln',
        symbol: 'zł',
        name: 'Polish zloty',
    },
    pyg: {
        cc: 'pyg',
        symbol: '₲',
        name: 'Paraguayan guarani',
    },
    qar: {
        cc: 'qar',
        symbol: 'QR',
        name: 'Qatari riyal',
    },
    ron: {
        cc: 'ron',
        symbol: 'L',
        name: 'Romanian leu',
    },
    rsd: {
        cc: 'rsd',
        symbol: 'din.',
        name: 'Serbian dinar',
    },
    rub: {
        cc: 'rub',
        symbol: 'R',
        name: 'Russian ruble',
    },
    sar: {
        cc: 'sar',
        symbol: 'SR',
        name: 'Saudi riyal',
    },
    sbd: {
        cc: 'sbd',
        symbol: 'SI$',
        name: 'Solomon Islands dollar',
    },
    scr: {
        cc: 'scr',
        symbol: 'SR',
        name: 'Seychellois rupee',
    },
    sdg: {
        cc: 'sdg',
        symbol: 'SDG',
        name: 'Sudanese pound',
    },
    sek: {
        cc: 'sek',
        symbol: 'kr',
        name: 'Swedish krona',
    },
    sgd: {
        cc: 'sgd',
        symbol: 'S$',
        name: 'Singapore dollar',
    },
    shp: {
        cc: 'shp',
        symbol: '£',
        name: 'Saint Helena pound',
    },
    sll: {
        cc: 'sll',
        symbol: 'Le',
        name: 'Sierra Leonean leone',
    },
    sos: {
        cc: 'sos',
        symbol: 'Sh.',
        name: 'Somali shilling',
    },
    srd: {
        cc: 'srd',
        symbol: '$',
        name: 'Surinamese dollar',
    },
    syp: {
        cc: 'syp',
        symbol: 'LS',
        name: 'Syrian pound',
    },
    szl: {
        cc: 'szl',
        symbol: 'E',
        name: 'Swazi lilangeni',
    },
    thb: {
        cc: 'thb',
        symbol: '฿',
        name: 'Thai baht',
    },
    tjs: {
        cc: 'tjs',
        symbol: 'TJS',
        name: 'Tajikistani somoni',
    },
    tmt: {
        cc: 'tmt',
        symbol: 'm',
        name: 'Turkmen manat',
    },
    tnd: {
        cc: 'tnd',
        symbol: 'DT',
        name: 'Tunisian dinar',
    },
    try: {
        cc: 'try',
        symbol: 'TRY',
        name: 'Turkish new lira',
    },
    ttd: {
        cc: 'ttd',
        symbol: 'TT$',
        name: 'Trinidad and Tobago dollar',
    },
    twd: {
        cc: 'twd',
        symbol: 'NT$',
        name: 'New Taiwan dollar',
    },
    tzs: {
        cc: 'tzs',
        symbol: 'TZS',
        name: 'Tanzanian shilling',
    },
    uah: {
        cc: 'uah',
        symbol: 'UAH',
        name: 'Ukrainian hryvnia',
    },
    ugx: {
        cc: 'ugx',
        symbol: 'USh',
        name: 'Ugandan shilling',
    },
    usd: {
        cc: 'usd',
        symbol: '$',
        name: 'United States dollar',
    },
    uyu: {
        cc: 'uyu',
        symbol: '$U',
        name: 'Uruguayan peso',
    },
    uzs: {
        cc: 'uzs',
        symbol: 'UZS',
        name: 'Uzbekistani som',
    },
    veb: {
        cc: 'veb',
        symbol: 'Bs',
        name: 'Venezuelan bolivar',
    },
    vnd: {
        cc: 'vnd',
        symbol: '₫',
        name: 'Vietnamese dong',
    },
    vuv: {
        cc: 'vuv',
        symbol: 'VT',
        name: 'Vanuatu vatu',
    },
    wst: {
        cc: 'wst',
        symbol: 'WS$',
        name: 'Samoan tala',
    },
    xaf: {
        cc: 'xaf',
        symbol: 'CFA',
        name: 'Central African CFA franc',
    },
    xcd: {
        cc: 'xcd',
        symbol: 'EC$',
        name: 'East Caribbean dollar',
    },
    xdr: {
        cc: 'xdr',
        symbol: 'SDR',
        name: 'Special Drawing Rights',
    },
    xof: {
        cc: 'xof',
        symbol: 'CFA',
        name: 'West African CFA franc',
    },
    xpf: {
        cc: 'xpf',
        symbol: 'F',
        name: 'CFP franc',
    },
    yer: {
        cc: 'yer',
        symbol: 'YER',
        name: 'Yemeni rial',
    },
    zar: {
        cc: 'zar',
        symbol: 'R',
        name: 'South African rand',
    },
    zmk: {
        cc: 'zmk',
        symbol: 'ZK',
        name: 'Zambian kwacha',
    },
    zwr: {
        cc: 'zwr',
        symbol: 'Z$',
        name: 'Zimbabwean dollar',
    },
};

export const supportedUserCurrencies = [
    {
        id: 978,
        code: 'EUR',
        name: 'currency.eur',
        symbol: '€',
        fractureSize: 2,
    },
    {
        id: 826,
        code: 'GBP',
        name: 'currency.gbp',
        symbol: '£',
        fractureSize: 2,
    },
    {
        id: 840,
        code: 'USD',
        name: 'currency.usd',
        symbol: '$',
        fractureSize: 2,
    },
];
