import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Reward, RewardsState } from '@/state/rewards/rewardsTypes';

const initialState: RewardsState = {
    rewardsList: [],
};

const slice = createSlice({
    name: 'rewards',
    initialState,
    reducers: {
        setRewardsList: (state, action: PayloadAction<Reward[]>) => {
            state.rewardsList = action.payload;
        },
        useReward: (state, action: PayloadAction<Reward['name']>) => {
            const reward = state.rewardsList.find((reward) => reward.name === action.payload);
            if (reward) {
                reward.available = false;
            }
        },
    },
});

export const { setRewardsList, useReward } = slice.actions;

export default slice.reducer;
