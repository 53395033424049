import { createDeepEqualSelector, RootState } from '@/store/store';
import { LegExecutions, LegNotes, LegsList, LegsListItem, SymbolsFilterItem } from './legsTypes';
import { SortingModel } from '@/state/general/generalTypes';

const legsSelectors = (state: RootState): LegsList => state.legs.legsList;
const legsSortingSelectors = (state: RootState): { [key: number]: SortingModel } =>
    state.legs.legsSorting;
const legsAnalyticsSelectors = (state: RootState) => state.legs.legsAnalytics;
const lastedLegsSelectors = (state: RootState): LegsList => state.legs.latestLegs;
const accountIdSelector = (state, props): number => props.accountId;
const symbolFilterSelector = (state: RootState): string[] => state.legs.symbolFilter;
const executionsSelector = (state: RootState): { [key: string]: LegExecutions } =>
    state.legs.legsExecutions;
const notesSelector = (state: RootState): { [key: string]: LegNotes } => state.legs.legsNotes;
const dateSelectionSelector = (
    state: RootState,
): {
    startDate: string;
    endDate: string;
    label: string;
} => state.legs.dateSelection;
const symbolsListSelector = (state: RootState): SymbolsFilterItem[] => state.legs.symbolsList;

export const selectLegsById = createDeepEqualSelector(
    [legsSelectors, accountIdSelector],
    (legs, accountId) => {
        return legs?.[accountId] || ({} as LegsListItem);
    },
);
export const selectLastedLegsById = createDeepEqualSelector(
    [lastedLegsSelectors, accountIdSelector],
    (legs, accountId) => {
        return legs?.[accountId] || ({} as LegsListItem);
    },
);
export const selectLegsAnalytics = createDeepEqualSelector(
    [legsAnalyticsSelectors],
    (analytics) => analytics,
);
export const selectLegsSymbolsAnalytics = createDeepEqualSelector(
    [legsAnalyticsSelectors],
    (analytics) => analytics.symbolsAnalytics || { totalCount: 0, items: [] },
);
export const selectSymbolsAnalyticsSorting = createDeepEqualSelector(
    [legsAnalyticsSelectors],
    (analytics) => analytics.symbolsTableSorting,
);

export const selectLegs = createDeepEqualSelector([legsSelectors], (legs) => legs);
export const selectLegExecutions = createDeepEqualSelector(
    [executionsSelector, (state, legId) => legId],
    (executions, legId) => executions?.[legId] || [],
);
export const selectLegsSortingById = createDeepEqualSelector(
    [legsSortingSelectors, (state, accountId) => accountId],
    (sorting, accountId) =>
        sorting[accountId] || ({ field: 'closeDate', sort: 'desc' } as SortingModel),
);
export const selectLegNotes = createDeepEqualSelector(
    [notesSelector, (state, legId) => legId],
    (executions, legId) => executions?.[legId] || [],
);

export const selectDateSelection = createDeepEqualSelector(
    [dateSelectionSelector],
    (dateSelection) => dateSelection,
);

export const selectSymbolFilter = createDeepEqualSelector(
    [symbolFilterSelector],
    (symbolFilter) => symbolFilter,
);

export const selectSymbolsList = createDeepEqualSelector([symbolsListSelector], (symbolsList) => {
    return symbolsList;
});
