/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FiatCurrency, FiatTransaction, FiatVolumes, initialFiat } from './fiatTypes';

const initialState: initialFiat = {
    fiatVolumes: {
        fiatExposure: 0,
        fiatDeposited: 0,
        fiatWithdrawn: 0,
        fiatPnl: 0,
        currency: 'USD',
    },
    transactionHistory: [],
    fiatCurrencies: [],
};

const slice = createSlice({
    name: 'fiat',
    initialState,
    reducers: {
        updateFiatVolumes: (state, action: PayloadAction<FiatVolumes>) => {
            if (!action.payload || Object.keys(action.payload).length === 0) {
                console.warn('Wrong data input for fiat volumes');
                return;
            }
            state.fiatVolumes = {
                ...state.fiatVolumes,
                ...action.payload,
                currency: action.payload?.fiatNativeCurrency || action.payload?.currency || 'USD',
            };
        },
        setTransactionHistory: (state, action: PayloadAction<FiatTransaction[]>) => {
            if (!Array.isArray(action.payload)) {
                console.warn('Wrong data input for transaction history');
                return;
            }

            state.transactionHistory = action.payload;
        },
        addTransaction: (state, action: PayloadAction<FiatTransaction>) => {
            if (!action.payload || Object.keys(action.payload).length === 0) {
                console.warn('Wrong data input for transaction');
                return;
            }

            state.transactionHistory.push(action.payload);
        },
        deleteTransaction: (state, action: PayloadAction<number>) => {
            const elIndex = state.transactionHistory.findIndex(
                (item) => +item.id === +action.payload,
            );

            if (elIndex >= 0) {
                state.transactionHistory.splice(elIndex, 1);
            }
        },
        setNativeCurrency: (state, action: PayloadAction<string>) => {
            state.fiatVolumes.currency = action.payload;
        },
        resetFiatState: (state) => {
            state.fiatVolumes = {
                fiatExposure: 0,
                fiatDeposited: 0,
                fiatWithdrawn: 0,
                fiatPnl: 0,
                currency: 'USD',
            };

            state.transactionHistory = [];
        },
        setFiatCurrencies: (state, action: PayloadAction<FiatCurrency[]>) => {
            state.fiatCurrencies = action.payload;
        },
    },
});

export const {
    updateFiatVolumes,
    setTransactionHistory,
    addTransaction,
    resetFiatState,
    deleteTransaction,
    setNativeCurrency,
    setFiatCurrencies,
} = slice.actions;
export default slice.reducer;
