import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_AUTH } from './initialAuth';
import { AuthorizationTokens, InitialAuthSlice } from './authTypes';

const initialState: InitialAuthSlice = {
    tokens: { ...INITIAL_AUTH },
    firstTimer: true,
    tokenUpdate: new Date().toISOString(),
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthTokens: (state, action: PayloadAction<AuthorizationTokens>) => {
            if (!action.payload) {
                return;
            }

            state.tokens = {
                ...action.payload,
            };
        },
        updateAccessToken: (state, action: PayloadAction<{ accessToken: string }>) => {
            const { accessToken } = action.payload;
            state.tokens.accessToken = accessToken;
        },
        resetAuth: (state) => {
            state.tokens = INITIAL_AUTH;
        },
        updateTokens: (state, action: PayloadAction<AuthorizationTokens>) => {
            state.tokens = action.payload;
            state.tokenUpdate = new Date().toISOString();
        },
        updateFirstTimer: (state, action: PayloadAction<boolean>) => {
            state.firstTimer = action.payload;
        },
    },
});

export const { setAuthTokens, resetAuth, updateAccessToken, updateTokens, updateFirstTimer } =
    slice.actions;
export default slice.reducer;
