export interface Reward {
    name: RewardName;
    title: string;
    description: string;
    available: boolean;
}

export enum RewardName {
    DISCORD_JOIN = 'discord-join',
    X_FOLLOW = 'x-follow',
    EXCHANGE_CONNECT = 'exchange-connect',
}

export interface RewardsState {
    rewardsList: Reward[];
}
