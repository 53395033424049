import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EXCHANGES } from '@/lib/types/generalTypes';
import { ExchangeAccount, Favorite, InitialExchanges, Pull } from './exchangesSliceTypes';

const initialState: InitialExchanges = {
    isSecondDrawerVisible: false,
    selectedExchange: null,
    exchangeAccounts: [],
    pullStatus: {},
};

const slice = createSlice({
    name: 'exchanges',
    initialState,
    reducers: {
        toggleSecondDrawer: (state, action: PayloadAction<boolean>) => {
            state.isSecondDrawerVisible = action.payload;
        },
        setSelectedExchange: (state, action: PayloadAction<EXCHANGES | null>) => {
            state.selectedExchange = action.payload;
        },
        resetSelectedExchange: (state) => {
            state.selectedExchange = null;
        },
        addExchangeAccount: (state, action: PayloadAction<{ account: ExchangeAccount }>) => {
            const { account } = action.payload;
            const accounts: ExchangeAccount[] = state.exchangeAccounts;
            if (accounts.find((stateAccount: ExchangeAccount) => account.id === stateAccount.id)) {
                return;
            }

            state.exchangeAccounts = [...state.exchangeAccounts, account];
        },
        updateExchangeAccount: (state, action: PayloadAction<{ account: ExchangeAccount }>) => {
            const { account } = action.payload;
            const accountIndex = state.exchangeAccounts.findIndex((el) => account.id === el.id);
            if (accountIndex >= 0) {
                const editedAccount = state.exchangeAccounts[accountIndex];
                state.exchangeAccounts.splice(accountIndex, 1, {
                    ...editedAccount,
                    ...account,
                    exchange: account.exchangeId,
                });
            }
        },
        markAccountAsFaulty: (
            state,
            action: PayloadAction<{ accountId: number; keyError: string }>,
        ) => {
            const { keyError, accountId } = action.payload;
            const accountIndex = state.exchangeAccounts.findIndex((el) => accountId === el.id);
            if (accountIndex >= 0) {
                const editedAccount = state.exchangeAccounts[accountIndex];
                state.exchangeAccounts.splice(accountIndex, 1, {
                    ...editedAccount,
                    keyError,
                });
            }
        },

        updateExchangeAccountSettings: (
            state,
            action: PayloadAction<{
                accountId: number;
                settings: { minBreakeven: number; maxBreakeven: number };
            }>,
        ) => {
            const account = state.exchangeAccounts.find(
                (account) => account.id === action.payload.accountId,
            );
            if (account) {
                state.exchangeAccounts.splice(state.exchangeAccounts.indexOf(account), 1, {
                    ...account,
                    settings: action.payload.settings,
                });
            }
        },
        toggleFavorite: (state, action: PayloadAction<Favorite>) => {
            state.exchangeAccounts = state.exchangeAccounts.map((account) => {
                if (account.id === action.payload.accountId) {
                    account.isFavorite = action.payload.isFavorite;
                }
                return account;
            });
        },
        resetExchangesState: (state) => {
            state.exchangeAccounts = [];
            state.pullStatus = {};
        },
        setInitialExchangeAccounts: (state, action: PayloadAction<ExchangeAccount[]>) => {
            state.exchangeAccounts = action.payload;
        },
        deleteExchangeAccount: (state, action: PayloadAction<{ accountId: number }>) => {
            const { accountId } = action.payload;
            state.exchangeAccounts = state.exchangeAccounts.filter(
                (account) => account.id !== accountId,
            );
        },

        setPullStatus: (state, action: PayloadAction<Pull>) => {
            state.pullStatus[action.payload.apiKeyId] = action.payload;
        },
        resetPullStatus: (state, action: PayloadAction<number>) => {
            const statuses = { ...state.pullStatus };

            if (statuses?.[action.payload]) {
                delete statuses[action.payload];
                state.pullStatus = statuses;
            }
        },
        resetAllPullStatus: (state) => {
            state.pullStatus = {};
        },
    },
});

export const {
    toggleSecondDrawer,
    setSelectedExchange,
    addExchangeAccount,
    toggleFavorite,
    resetSelectedExchange,
    updateExchangeAccount,
    resetExchangesState,
    setInitialExchangeAccounts,
    deleteExchangeAccount,
    setPullStatus,
    resetPullStatus,
    updateExchangeAccountSettings,
    markAccountAsFaulty,
    resetAllPullStatus,
} = slice.actions;
export default slice.reducer;
