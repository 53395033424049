import { useMemo } from 'react';
import { convertDateToSelectedTimezone, isDateAvailable } from '@/lib/helpers/helpers';
import { DATE_PRESET_LIMITS } from '@/state/legs/legsTypes';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import {
    selectDateSelection,
    selectSymbolFilter,
    selectSymbolsList,
} from '@/state/legs/legsSelectors';
import { selectLegsFilters } from '@/state/shortStorage/shortStorageSelectors';
import { getFilterQueryParams } from '@/components/FilterPopup/filterHelpers';
import { selectAccountNames } from '@/state/exchanges/exchangeSelectors';
import { selectMemberTimezone } from '@/state/member/memberSelectors';

export const definedFetchLegsParams = (
    accountIds: number[] | string[],
    paramsData,
    initial: boolean = false,
) => {
    const { dateSelection, symbolsList, symbolFilter, legsFilters, accounts, userTimezone } =
        paramsData;
    const { label = '' } = dateSelection;

    let params: any = {
        offset: 0,
        startDate: convertDateToSelectedTimezone(dateSelection.startDate, userTimezone),
        endDate: convertDateToSelectedTimezone(dateSelection.endDate, userTimezone),
    };

    if (!isDateAvailable(label)) {
        params = {
            offset: 0,
            limit: DATE_PRESET_LIMITS[dateSelection.label],
        };
    }
    if (legsFilters) {
        params = { ...params, ...getFilterQueryParams(legsFilters) };
    }

    if (initial) {
        params.symbols = [];
    }
    if (symbolFilter.length > 0 && symbolFilter.length < symbolsList.length) {
        params.symbols = symbolFilter;
    }
    if (Array.isArray(accountIds) && Object.keys(accounts).length !== accountIds.length) {
        params.accountIds = accountIds;
    }
    return params;
};

interface UseGetFetchLegsParamsProps {
    accountIds?: number[];
    withoutLegFilter?: boolean;
    initial?: boolean;
}

const useGetFetchLegsParams = ({
    accountIds,
    initial = false,
    withoutLegFilter = false,
}: UseGetFetchLegsParamsProps): any => {
    const symbolsList = useSelector((state: RootState) => selectSymbolsList(state));

    const { symbolFilter, userTimezone, dateSelection, legsFilters, accounts } = useSelector(
        (state: RootState) => ({
            symbolFilter: selectSymbolFilter(state),
            dateSelection: selectDateSelection(state),
            legsFilters: selectLegsFilters(state),
            accounts: selectAccountNames(state),
            userTimezone: selectMemberTimezone(state),
        }),
    );
    const getParams = (accountIds, initial) => {
        return definedFetchLegsParams(
            accountIds,
            {
                dateSelection,
                symbolsList,
                symbolFilter,
                legsFilters: withoutLegFilter ? {} : legsFilters,
                accounts,
                userTimezone,
            },
            initial,
        );
    };

    const params = useMemo(() => {
        return getParams(accountIds, initial);
    }, [
        legsFilters,
        symbolFilter,
        dateSelection,
        accountIds?.length,
        accountIds?.[0],
        userTimezone,
    ]);
    return { params, getParams };
};

export default useGetFetchLegsParams;
