import { createDeepEqualSelector, RootState } from '../../store/store';
import { Banner, PaymentBanners, PullBanner, TABLE } from './bannersTypes';

const paymentBannersSelector = (state: RootState): PaymentBanners => state.banners.paymentBanners;
const tableSortsSelector = (state: RootState) => state.banners.tableSorts;
const tableNameSelector = (state: RootState, { tableName }): TABLE => tableName;
const pullBannersSelector = (state: RootState): PullBanner => state.banners.pullBanners;

const allBannersSelector = (state: RootState): Banner[] => state.banners.allBanners;

export const selectPaymentBanners = createDeepEqualSelector(
    [paymentBannersSelector],
    (paymentBanners) => paymentBanners,
);

export const selectNotDismissedBanner = createDeepEqualSelector([allBannersSelector], (banners) => {
    if (!banners) return [];
    return banners.filter((el) => !el?.wasDismissed);
});

export const selectNotDismissedBannerById = createDeepEqualSelector(
    [allBannersSelector, (state, props: { apiKeyId: number }) => props.apiKeyId],
    (banners, apiKeyId) => banners.filter((el) => !el.wasDismissed && el.apiKeyId === apiKeyId),
);

export const selectSortForTable = createDeepEqualSelector(
    [tableSortsSelector, tableNameSelector],
    (tableSorts, tableName) => tableSorts[tableName],
);

export const selectPullBannerById = createDeepEqualSelector(
    [pullBannersSelector, (state, props) => props],
    (pullBanners, { accountId }: { accountId: number }): boolean | null =>
        pullBanners?.[accountId] || null,
);
