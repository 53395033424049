import ExchangeSymbol from './ExchangeSymbol';
import SubscriptionEventsHandler from './SubscriptionEventsHandler';
import { doConvert } from './websocketHelpers';
import {
    Conversion,
    ConversionRequest,
    ConvertCurrencyBulkResponse,
    ConvertOnchangeCB,
    Message,
    QuotesWsUpdate,
    Symbol,
} from './websocketTypes';
import { EXCHANGES } from '@/lib/types/generalTypes';

class ConversionService {
    private readonly client: any; //Websocket instance
    private readonly symbols: Map<number, ExchangeSymbol>;
    private readonly convs: Map<string | number, Conversion>;
    private topic: string | null;
    private name: string;

    constructor(ws) {
        this.client = ws;
        this.symbols = new Map();
        this.convs = new Map();
        this.topic = null;
        this.name = '';
    }

    createSymbols = (convSymbols: Symbol[]): Symbol[] => {
        return convSymbols.map((symbol) => {
            if (!this.symbols.has(symbol.id)) {
                this.symbols.set(symbol.id, new ExchangeSymbol(symbol));
            }
            return symbol;
        });
    };

    createConversions = (firstConversionResponse: ConvertCurrencyBulkResponse[]) => {
        return firstConversionResponse.map((conv) => {
            const key = conv.from + '|' + conv.to + '|' + conv.exchange;
            // when conversion symbols were not found, or was any other error, just skip this
            if (!conv.symbols) {
                // console.warn('Conversion response error:', conv, key);
                return;
            }
            const filter: Symbol[] = this.createSymbols(conv.symbols);
            const conversion: Conversion = {
                subscriptions: [],
                convSymbols: filter.map((symbol) => {
                    return {
                        symbol: this.symbols.get(symbol.id) as ExchangeSymbol,
                        reverse: symbol.reverse,
                    };
                }),
            };
            this.convs.set(key, conversion);
        });
    };

    handleQuotesUpdate = (quotes: QuotesWsUpdate) => {
        quotes.payload.forEach((quote) => {
            if (this.symbols.has(quote.id)) {
                const quoteData = this.symbols.get(quote.id);
                if (quoteData) {
                    quoteData.update(quote);
                }
            }
        });
    };

    subscribe = async (name: string): Promise<void> => {
        const symbolIds: number[] = Array.from(this.symbols.values()).map(
            (symbol: any) => symbol.id,
        );
        const subscriptionResponse: Message<{ topic: string }> = await this.client.subscribeQuotes(
            symbolIds,
        );
        this.topic = subscriptionResponse.payload.topic;
        SubscriptionEventsHandler.addQuoteListener({
            name,
            topic: this.topic,
            listener: this.handleQuotesUpdate,
        });
    };

    async createConversion(conversions: ConversionRequest[] = [], name: string) {
        this.name = name;
        const firstConversionResponse: ConvertCurrencyBulkResponse[] =
            await this.client.convertCurrencyBulk(
                conversions.map((conv) => ({ ...conv, value: conv.amount })),
            );
        this.createConversions(firstConversionResponse);
        await this.subscribe(name);

        return {
            convert: (
                exchange: EXCHANGES | string,
                from: string,
                to: string,
                amount: number,
                onChange: ConvertOnchangeCB,
            ) => {
                if (!this.client || !this.client.isAuthorized) return;
                const key = from + '|' + to + '|' + exchange;
                let conversion = this.convs.get(key);

                if (!conversion) {
                    this.convs.forEach((value, key) => {
                        const strKey = `${key}`;
                        const list = strKey.split('|');
                        if (list[0] === from && list[1] === to) {
                            conversion = value;
                        }
                    });
                }

                if (!conversion) return () => {};

                if (conversion) {
                    const convertHandler = () => {
                        // @ts-ignore
                        const converted = doConvert(conversion.convSymbols, amount);

                        onChange({
                            from,
                            to,
                            amount,
                            converted,
                        });
                    };

                    // first run - get initial values
                    convertHandler();

                    // symbols subscriptions  - on every quote
                    conversion.convSymbols.forEach((convSymbol) => {
                        convSymbol.symbol.onChange(convertHandler);
                    });

                    // return unsubscribe function
                    return () => {
                        // @ts-ignore
                        conversion.convSymbols.forEach((convSymbol) => {
                            convSymbol.symbol.off(convertHandler);
                        });
                    };
                }
                // for not to catch error when from/to doesn't exist
                return () => {};
            },
            unsubscribe: async () => {
                Array.from(this.symbols.values()).forEach((symbol: any) => {
                    symbol.clear();
                });
                this.convs.clear();
                this.symbols.clear();
                console.log('unsubscribing', this.topic);
                const listeners = SubscriptionEventsHandler.listQuoteListeners(
                    this.topic as string,
                );
                if (listeners.length === 1) {
                    await this.client.unsubscribeQuotes(this.topic as string);
                }
                SubscriptionEventsHandler.removeQuoteListener(this.name as string);
            },
        };
    }
}

export default ConversionService;
