export enum VERIFICATION_STATUS {
    PENDING = 'pending_review',
    APPROVED = 'approved',
    DECLINED = 'declined',
    UNFINISHED = 'unfinished',
    EXPIRED = 'expired',
}

export type PageSettingsKey = 'showValues' | 'showBalances' | 'showOpenPositions' | 'active';

export enum VERPAGE_DEPLOY_STATUS {
    SUCCESS = 'success',
    IN_PROGRESS = 'in_progress',
    FAILED = 'failed',
    IN_QUEUE = 'in_queue',
}
export interface VerificationItem {
    username: string;
    email: string;
    memberId: number;
    id: number;
    apiKeyId: number;
    pageName: string;
    permalink: string;
    discord: string;
    twitterId: string;
    showValues: boolean;
    showBalances: boolean;
    showOpenPositions: boolean;
    showInDarkMode: boolean;
    status: VERIFICATION_STATUS;
    lastUpdated: string | null;
    active: boolean;
    created: string;
    totalRows?: number;
    deployed?: string;
    deployStatus?: VERPAGE_DEPLOY_STATUS;
    deployUpdated?: string;
}

export type UpdateVerificationPagePayload = {
    [key in PageSettingsKey]: boolean | number;
} & {
    id: number;
};

export interface VerificationState {
    pages: VerificationItem[];
}

export enum VerificationAddSteps {
    SELECT_EXCHANGE_ACCOUNT = 'SELECT_EXCHANGE_ACCOUNT',
    CONFIGURE_SETTINGS = 'CONFIGURE_SETTINGS',
    UNDER_REVIEW = 'UNDER_REVIEW',
    CONNECT = 'CONNECT',
    LOADING = 'LOADING',
}

export interface GetTwitterTokenPayload {
    id: number;
}

export interface GetTwitterTokenResponse {
    oauthToken: string;
}

export interface ValidateTwitterUserPayload {
    oauthToken: string;
    oauthVerifier: string;
}

export interface FetchTwitterUserInfoPayload {
    id: string;
}

export interface ValidateTwitterUserResponse {
    verificationPageId: number;
    memberId: number;
    twitterId: string | number;
    twitterUsername: string;
}

export interface ValidatePermalinkPayload {
    permalink: string;
    pageId: number;
}

export interface ValidatePermalinkResponse {
    reason: string;
    valid: boolean;
}
