import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Discount, PaymentState, Price, Subscription, UserOrder } from './paymentsTypes';

const initialState: PaymentState = {
    userOrders: [],
    prices: [],
    subscription: null,
    discountCoupons: '',
    nonRedeemedCoupons: [],
    discountPercentage: 0,
};

const slice = createSlice({
    initialState,
    name: 'payments',
    reducers: {
        resetPaymentState: (state) => {
            state.subscription = null;
            state.userOrders = [];
        },
        setUserOrders: (state, action: PayloadAction<UserOrder[]>) => {
            state.userOrders = action.payload;
        },
        setPrices: (state, action: PayloadAction<Price[]>) => {
            state.prices = action.payload;
        },
        setSubscription: (state, action: PayloadAction<Subscription | null>) => {
            state.subscription = action.payload;
        },
        setNonRedeemedCoupons: (state, action: PayloadAction<Discount[]>) => {
            const activeDiscountCoupon = action.payload
                .map((coupon) => coupon.couponCode)
                .filter((value, index, array) => array.indexOf(value) === index)[0];
            if (activeDiscountCoupon) {
                state.nonRedeemedCoupons = action.payload;
                state.discountCoupons = activeDiscountCoupon;
                state.discountPercentage =
                    100 -
                    Math.round(
                        (action.payload[0].discountPrice / action.payload[0].originalPrice) * 100,
                    );
            }
        },
    },
});

export const {
    setUserOrders,
    resetPaymentState,
    setPrices,
    setSubscription,
    setNonRedeemedCoupons,
} = slice.actions;

export default slice.reducer;
