import { CURRENCIES } from '@/lib/types/generalTypes';

export type FiatVolumesAmount = {
    [key in CURRENCIES]: number;
};

export enum TRANSACTION_TYPE {
    DEPOSIT = 'deposit',
    WITHDRAW = 'withdrawal',
}

export interface FiatTransaction {
    date: Date | string | null;
    type: TRANSACTION_TYPE;
    bankAmount: number;
    destination: string;
    id: number;
}

export interface FiatVolumes {
    fiatExposure: number;
    fiatDeposited: number;
    fiatWithdrawn: number;
    currency: string;
    fiatPnl: number;
    fiatNativeCurrency?: string;
}

export interface FiatCurrency {
    id: number;
    code: string;
    name: string;
    symbol: string;
    fractureSize: number;
}

export interface initialFiat {
    fiatVolumes: FiatVolumes;
    transactionHistory: FiatTransaction[];
    fiatCurrencies: FiatCurrency[];
}

export interface AddFiatTransactionPayload extends Omit<FiatTransaction, 'id'> {}

export interface AddFiatTransactionResponse extends FiatTransaction {
    fiatSummary: FiatVolumes;
}

export interface UpdateTransactionPayload {
    transactionId: number;
    transaction: Partial<AddFiatTransactionPayload>;
}

export interface NativeCurrencyResponse {
    fiatNativeCurrency: CURRENCIES;
}
