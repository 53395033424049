import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Notification,
    NotificationsResponse,
    NotificationsState,
} from '@/state/notifications/notificationsTypes';

const initialState: NotificationsState = {
    notifications: {
        items: [],
        totalCount: 0,
        totalCountUnread: 0,
    },
    unreadNotifications: {
        items: [],
        totalCount: 0,
        totalCountUnread: 0,
    },
};

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        resetNotificationState: (state) => {
            state.notifications = { items: [], totalCount: 0, totalCountUnread: 0 };
            state.unreadNotifications = { items: [], totalCount: 0, totalCountUnread: 0 };
        },
        setNotifications: (state, action: PayloadAction<NotificationsResponse>) => {
            state.notifications = action.payload;
        },
        setUnreadNotifications: (state, action: PayloadAction<NotificationsResponse>) => {
            state.unreadNotifications = action.payload;
        },
        removeNotification: (state, action: PayloadAction<number[]>) => {
            state.notifications.items = state.notifications.items.filter(
                (notification) => !action.payload.includes(notification.id),
            );

            state.notifications.totalCount -= action.payload.length;
            state.unreadNotifications.items = state.unreadNotifications.items.filter(
                (notification) => !action.payload.includes(notification.id),
            );
            state.unreadNotifications.totalCount -= action.payload.length;
            state.unreadNotifications.totalCountUnread -= action.payload.length;
        },
        markNotificationAsRead: (state, action: PayloadAction<number[]>) => {
            state.unreadNotifications.items = state.unreadNotifications.items.filter(
                (notification) => !action.payload.includes(notification.id),
            );

            state.unreadNotifications.totalCount = state.unreadNotifications.items.length;
            state.unreadNotifications.totalCountUnread = state.unreadNotifications.items.filter(
                (notification) => !notification.read,
            ).length;

            state.notifications.items = state.notifications.items.map((notification) => {
                if (!action.payload.includes(notification.id)) {
                    return notification;
                } else {
                    return {
                        ...notification,
                        read: true,
                    };
                }
            });
        },

        markNotificationAsUnread: (state, action: PayloadAction<number[]>) => {
            state.unreadNotifications.items = [
                ...state.unreadNotifications.items,
                ...state.notifications.items.filter((notification) =>
                    action.payload.includes(notification.id),
                ),
            ];
            state.unreadNotifications.totalCount = state.unreadNotifications.items.length;
            state.unreadNotifications.totalCountUnread = state.unreadNotifications.items.length;

            state.notifications.items = state.notifications.items.map((notification) => {
                if (!action.payload.includes(notification.id)) {
                    return notification;
                } else {
                    return {
                        ...notification,
                        read: false,
                    };
                }
            });
        },

        updateNotificationById: (state, action: PayloadAction<Partial<Notification>>) => {
            state.notifications.items = state.notifications.items.map((notification) =>
                notification.id === action.payload.id
                    ? { ...notification, ...action.payload }
                    : notification,
            );
            state.unreadNotifications.items = state.unreadNotifications.items.map((notification) =>
                notification.id === action.payload.id
                    ? { ...notification, ...action.payload }
                    : notification,
            );
        },
        addNotificationFromWs: (state, action: PayloadAction<Notification>) => {
            state.notifications.items = [action.payload, ...state.notifications.items];
            state.notifications.totalCount += 1;
            if (!action.payload.read) {
                state.unreadNotifications.items = [
                    action.payload,
                    ...state.unreadNotifications.items,
                ];
                state.unreadNotifications.totalCount += 1;
                state.unreadNotifications.totalCountUnread += 1;
            }
        },
    },
});

export const {
    resetNotificationState,
    setNotifications,
    setUnreadNotifications,
    updateNotificationById,
    markNotificationAsRead,
    removeNotification,
    addNotificationFromWs,
    markNotificationAsUnread,
} = slice.actions;

export default slice.reducer;
