import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpenPosition, PositionsState } from './positionTypes';
import { DERIVATIVE_POSITION_STATUS } from '@/lib/constants/exchanges';
import { defineIsPositionClosed } from '@/state/positions/positionStateHelpers';
import { EXCHANGES } from '@/lib/types/generalTypes';

const initialState: PositionsState = {
    openPositions: {},
};

const slice = createSlice({
    name: 'positions',
    initialState,
    reducers: {
        resetPositions: (state) => {
            state.openPositions = {};
        },
        removePositionsForApiKey: (state, action: PayloadAction<{ accountId: number }>) => {
            const { accountId } = action.payload;
            const openPositions = { ...state.openPositions };
            if (openPositions?.[accountId]) {
                delete openPositions[accountId];
            }
            state.openPositions = openPositions;
        },
        updatePositionsByAccountId: (
            state,
            action: PayloadAction<{ accountId: number; positions: Partial<OpenPosition>[] }>,
        ) => {
            const { accountId, positions: positionsUpdates } = action.payload;
            if (!Array.isArray(positionsUpdates) || positionsUpdates.length === 0) return;

            if (!state.openPositions?.[accountId]) {
                state.openPositions[accountId] = [];
            }

            const transformedUpdated = state.openPositions[accountId].map((newPosition) => {
                const findPosition = positionsUpdates.find(
                    (position) => position.posId === newPosition.posId,
                );

                if (!findPosition) {
                    return newPosition;
                }

                if (findPosition && !findPosition.amount) {
                    return null;
                }

                return {
                    ...newPosition,
                    ...findPosition,
                };
            });

            const unExistedPositions = positionsUpdates.filter((position) => {
                return (
                    !state.openPositions[accountId].find((item) => item.posId === position.posId) &&
                    position.amount !== 0
                );
            });

            const filtered = transformedUpdated.filter((item) => item !== null);
            state.openPositions = {
                ...state.openPositions,
                [accountId]: [...filtered, ...unExistedPositions] as OpenPosition[],
            };
        },

        setPositionsByAccountId: (
            state,
            action: PayloadAction<{ apiKeyId: number; data: OpenPosition[] }>,
        ) => {
            state.openPositions = {
                ...state.openPositions,
                [action.payload.apiKeyId]: action.payload.data.filter((item) => item.amount !== 0),
            };
        },
    },
});

export const {
    resetPositions,
    // setPositions,
    updatePositionsByAccountId,
    removePositionsForApiKey,
    setPositionsByAccountId,
} = slice.actions;

export default slice.reducer;
