import store from '../../store/store';
import { resetMember } from './memberSlice';
import { resetGeneral } from '../general/generalSlice';
import { resetAuth } from '../auth/authSlice';
import { resetExchangesState } from '../exchanges/exchangesSlice';
import { resetFiatState } from '../fiat/fiatSlice';
import { batch } from 'react-redux';
import { resetLegs } from '../legs/legsSlice';
import { resetBalances } from '../balances/balancesSlice';
import { resetJournals } from '../journals/journalsSlice';
import { resetPositions } from '../positions/positionsSlice';
import { resetPaymentState } from '../payments/paymentsSlice';
import { resetReports } from '../reports/reportsSlice';
import { resetVerification } from '../verification/verificationSlice';
import { resetBanners } from '../banners/bannersSlice';
import websocket from '../../lib/websocket';
import userApi from '../../api/userApi';
import { resetShortStorage } from '@/state/shortStorage/shortStorageSlice';
import { resetTags } from '@/state/tags/tagsSlice';
import { resetNotificationState } from '@/state/notifications/notificationsSlice';
import { resetLeaderboardState } from '@/state/leaderboard/leaderboardSlice';

export const logoutMember = async (withApi: boolean = true): Promise<void> => {
    try {
        if (withApi) {
            await userApi.logout();
            document.cookie = `isAuth=false; domain=coinmarketman.com`;
            document.cookie = `loggedEnv=; domain=coinmarketman.com`;
        }
    } catch (error) {
        // silent fail for logout api call;
        console.log(error);
    }

    websocket.disconnect();
    batch(() => {
        store.dispatch(resetExchangesState());
        store.dispatch(resetFiatState());
        store.dispatch(resetGeneral());
        store.dispatch(resetMember());
        store.dispatch(resetAuth());
        store.dispatch(resetLegs());
        store.dispatch(resetBalances());
        store.dispatch(resetJournals());
        store.dispatch(resetTags());
        store.dispatch(resetPositions());
        store.dispatch(resetPaymentState());
        store.dispatch(resetReports());
        store.dispatch(resetVerification());
        store.dispatch(resetBanners());
        store.dispatch(resetShortStorage());
        store.dispatch(resetNotificationState());
        store.dispatch(resetLeaderboardState());
    });
};
