import { createDeepEqualSelector, RootState } from '@/store/store';
import { NotificationsResponse } from '@/state/notifications/notificationsTypes';

const allNotificationsSelector = (state: RootState): NotificationsResponse =>
    state.notifications.notifications;
const unreadNotificationsSelector = (state: RootState): NotificationsResponse =>
    state.notifications.unreadNotifications;

export const selectAllNotifications = createDeepEqualSelector(
    [allNotificationsSelector],
    (notifications) => notifications,
);

export const selectUnreadNotifications = createDeepEqualSelector(
    [unreadNotificationsSelector],
    (notifications) => notifications,
);

export const selectNotificationById = createDeepEqualSelector(
    [allNotificationsSelector, (state: RootState, props: { id: string }) => props],
    (notifications, { id }) => {
        if (!notifications.items) return null;
        return notifications.items.find((item) => item.id === +id);
    },
);
