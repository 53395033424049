import { CURRENCIES, EXCHANGES } from '@/lib/types/generalTypes';
import { PAYMENT_METHOD, PLANS } from '../payments/paymentsTypes';

export enum MEMBER_STATUS {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    SUSPENDED = 'suspended',
}

export enum LANGUAGES {
    EN = 'en',
    RU = 'ru',
    UK = 'uk',
    // KO = 'ko',
    // JA = 'ja',
    // ZH = 'zh',
}

export enum THEME_VARIANTS {
    DARK = 'dark',
    LIGHT = 'light',
}

export enum THEME_VARIANTS_DISPLAY {
    DARK = 'dark_theme_label',
    LIGHT = 'light_theme_label',
}

export enum BROWSER_TAB {
    currentSelectedExchangeBtc = 'current_selected_exchange_btc',
    currentSelectedExchangeFiat = 'current_selected_exchange_fiat',
    totalNetWorthBtc = 'total_net_worth_btc',
    totalNetWorthFiat = 'total_net_worth_fiat',
}

export enum BROWSER_TAB_DISPLAY {
    currentSelectedExchangeBtc = 'current_selected_exchange_btc',
    currentSelectedExchangeFiat = 'current_selected_exchange_fiat',
    totalNetWorthBtc = 'total_net_worth_btc',
    totalNetWorthFiat = 'total_net_worth_fiat',
}

export interface MemberSettings {
    timezone?: string;
    fiatNativeCurrency: string;
    siteCurrency: string;
    updated: string;
    theme: THEME_VARIANTS;
    browserTabDisplay: BROWSER_TAB;
    adminAccessAllowed: boolean;
    emailMarketingAllowed: boolean;
    priceHighlight?: boolean;
    maxBreakeven: number | null;
    minBreakeven: number | null;
    showGmArt: boolean;
    isFavoriteMenu: boolean;
    menuCollapsed: boolean;
    lang: string;
}

export type UsedSlots = {
    [key in EXCHANGES]: number;
};

export interface AccountType {
    accountType: PLANS;
    expiredAt: string;
    source: PAYMENT_METHOD | null;
}

export interface MemberSlots {
    usedSlots: UsedSlots;
    totalSlots: number;
}

export interface Profile {
    status: MEMBER_STATUS;
    id: number | null;
    username: string;
    firstName: string | null;
    lastName: string | null;
    country: any;
    email: string;
    role: number;
    verified: boolean;
    created: string;
    slots: MemberSlots;
    accountType: AccountType;
}

export interface InitialMemberState {
    isAuth: boolean;
    activeCurrency: CURRENCIES;
    settings: MemberSettings;
    twitterAccount: ConnectedTwitterData | null;
    profile: Profile;
    referrer: string | null;
}

export interface ConnectedTwitterData {
    memberId: number;
    twitterId: string;
    twitterScreenName: string;
    twitterProfile: {
        screenName: string;
        profileImageUrl: string;
        name: string;
        description: string;
        verified: boolean;
    };
    created: string;
    updated: string;
}
