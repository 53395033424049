import { createDeepEqualSelector, RootState } from '@/store/store';
import { OpenPosition, PositionsMap } from './positionTypes';

const positionsSelector = (state: RootState): PositionsMap => state.positions.openPositions;
const accountIdSelector = (
    state: RootState,
    { accountId }: { accountId: number | number[] | null },
): number | number[] | null => accountId;

export const selectAllPositions = createDeepEqualSelector([positionsSelector], (positions) => {
    const positionsFinal: OpenPosition[] = Object.values(positions).flat();
    return positionsFinal;
});

export const selectPositionsByAccountId = createDeepEqualSelector(
    [positionsSelector, accountIdSelector],
    (positions, accountId): OpenPosition[] => {
        if (!accountId) return [];
        if (typeof accountId === 'number' && !positions?.[accountId]) return [];
        if (Array.isArray(accountId) && !accountId.length) return [];

        if (typeof accountId === 'number') return positions[accountId];

        return Object.values(positions)
            .flat()
            .filter((position) => accountId.includes(position.accountId));
    },
);
