export enum EXCHANGES {
    BINANCE = 'binance',
    BITMEX = 'bitmex',
    FTX = 'ftx',
    BYBIT = 'bybit',
    COINBASE = 'coinbase',
    DERIBIT = 'deribit',
    BITTREX = 'bittrex',
    STORAGE = 'storage',
    WOO = 'woo',
    BITGET = 'bitget',
    PHEMEX = 'phemex',
    BLOFIN = 'blofin',
}

export enum EXCHANGE_IDS {
    BINANCE = 2,
    BITMEX = 5,
    FTX = 279,
    BYBIT = 134,
    COINBASE = 4,
    DERIBIT = 51,
    BITTREX = 1,
    WOO = 0,
    BITGET = 123,
    PHEMEX = 124,
    BLOFIN = 125,
}

export enum SIZES {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
}

export enum CURRENCIES {
    GBP = 'gbp',
    USD = 'usd',
}

export enum SIDE {
    BUY = 'buy',
    SELL = 'sell',
}

export interface DefaultAccountSelector {
    accountId: number;
    exchange: EXCHANGES;
}

export enum CLICKS_TRACK {
    FIRST_TIMER_BANNER_1 = 'firsttimer_banner_1',
    FIRST_TIMER_BANNER_2 = 'firsttimer_banner_2',
}

export enum SORTING_FIELDS {}

export enum ExchangeBadge {
    FUTURES = 'futures',
    SPOT = 'spot',
    DIRECT = 'direct',
}

export interface ExchangeCardBadge {
    value: number;
    type: ExchangeBadge;
}

export interface ExchangeCardData {
    type: EXCHANGES | 'bitget' | 'phemex' | 'kucoin' | 'okx' | 'mexc' | 'blofin' | 'APEX';
    refLink: string;
    description: string;
    badges: ExchangeCardBadge[];
    logo?: any;
    label?: string;
    comingSoon?: boolean;
}

export enum LOCAL_LOADER {
    INITIAL = 'initial',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}
