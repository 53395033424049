import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderState, SecondaryMenuOrder } from '@/state/order/orderTypes';
import { EXCHANGES } from '@/lib/types/generalTypes';

const initialState: OrderState = {
    mainMenu: [],
    secondaryMenu: {} as SecondaryMenuOrder,
    favoritesMenu: [],
};

const slice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        resetOrder: (state) => {
            state.mainMenu = [];
            state.secondaryMenu = {} as SecondaryMenuOrder;
            state.favoritesMenu = [];
        },
        setAllMenuOrder: (state, action: PayloadAction<{ menuOrder: any }>) => {
            const {
                mainMenu = null,
                secondaryMenu = null,
                favoritesMenu = null,
            } = action.payload.menuOrder;
            if (mainMenu) state.mainMenu = mainMenu;
            if (secondaryMenu) state.secondaryMenu = secondaryMenu;
            if (favoritesMenu) state.favoritesMenu = favoritesMenu;
        },
        setMainMenuOrder: (state, action: PayloadAction<EXCHANGES[]>) => {
            state.mainMenu = action.payload;
        },
        setSecondaryMenuOrder: (
            state,
            action: PayloadAction<{ exchange: EXCHANGES; order: string[] | number[] }>,
        ) => {
            state.secondaryMenu = {
                ...state.secondaryMenu,
                [action.payload.exchange]: action.payload.order,
            };
        },
        setFavoritesOrder: (state, action: PayloadAction<string[] | number[]>) => {
            state.favoritesMenu = action.payload;
        },
    },
});

export const {
    resetOrder,
    setAllMenuOrder,
    setMainMenuOrder,
    setSecondaryMenuOrder,
    setFavoritesOrder,
} = slice.actions;

export default slice.reducer;
