import { createDeepEqualSelector, RootState } from '@/store/store';
import { EXCHANGES } from '@/lib/types/generalTypes';

const menuOrderSelector = (state: RootState): EXCHANGES[] => state.order.mainMenu;
const favoritesMenuOrderSelector = (state: RootState): number[] => state.order.favoritesMenu;
const secondaryMenuOrderSelector = (
    state: RootState,
): { [exchange in EXCHANGES]: string[] | number[] } => state.order.secondaryMenu;

const orderSelector = (state: RootState) => state.order;

export const selectMainMenuOrder = createDeepEqualSelector([menuOrderSelector], (menu) => menu);
export const selectFavoriteExchangesOrder = createDeepEqualSelector(
    [favoritesMenuOrderSelector],
    (menu) => menu,
);
export const selectSecondaryMenuOrder = createDeepEqualSelector(
    [secondaryMenuOrderSelector, (state, props: { exchange: EXCHANGES }) => props.exchange],
    (menu, exchange) => menu?.[exchange] || [],
);

export const selectAllMenusOrder = createDeepEqualSelector([orderSelector], (order) => order);
